
import React, { useContext, useState, useEffect } from 'react';

// local
import UrlContext from '../../lookup/prefixurl'
import { apiGroupRequesters } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import {GroupRequestsInline} from './grouprequestsinline';


export function GroupRequests(props) {
    const {slug}                        = props;
    const prefixurl                     = useContext(UrlContext)
    const [sessionUser, setSessionUser] = useState(null)
    const [nextUrl, setNextUrl]         = useState(null)
    const [requesters, setRequesters]   = useState(null)
    const [doneLoading, setDoneLoading] = useState(false)
    const [refresh, setRefresh]         = useState(false)

    useEffect(()=> {
        if (refresh === false) {
            setRefresh(true)
        }
    },[refresh, setRefresh])

    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreRequesters()
        }
    }

    const handleRequesters = (response, status) => {
        if (status === 200){
            setRequesters(response.results)
            setSessionUser(response.sessionUser)
            setNextUrl(response.next)
            setDoneLoading(true)
        }
    }

    useEffect(() => {
        apiGroupRequesters(prefixurl, slug, handleRequesters)
    }, [prefixurl, slug, props])

    const loadMoreRequesters = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newRequesters = [...requesters].concat(response.results)
                    setRequesters(newRequesters)
                } else {
                  console.log("There was an error")
                }
            }
            apiGroupRequesters(prefixurl, slug, handleLoadNextResponse, nextUrl)
        }
    }

    return (
        <div>
            {(doneLoading === true) 
            ? 
            <div onScroll={handleScroll}>
                <div>
                    {requesters && requesters.map((requesterItem, i) => {
                        return (
                            <div key={i} className={classes.echdiv1}>
                                <GroupRequestsInline 
                                    requester={requesterItem}
                                    groupslug={slug} 
                                    sessionuser={sessionUser}
                                />
                            </div>
                            )
                    })}
                </div>
            </div> 
             : 
               null
            }
        </div>
    )
}