import React, {useState, useEffect} from 'react';

// third party plugins
import AppsIcon from '@mui/icons-material/Apps';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';


//local
import { UrlProvider } from '../../lookup/prefixurl';
import { ProfileFeed } from './profilefeed';
import { ProfileFollowedBy,  ProfileImage } from './profilecomponents' ;
import { ProfileFollowing} from './profilefollowing';        
import { LocationsFollowed } from './profilelocation';
import { ProfileMedia } from './profilemedia';
import { ProfileBlocked } from './profileblocked';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function ProfileTabs(props) {
  const {username}    = props;
  let {privacyflag}   = props;
  let {blockedby}     = props;
  const {prefixurl}   = props;
  const {sessionusr}  = props;
  const [value, setValue] = useState(0);
  
  // if privacyflag and blockedby comes from django view  
  if ( privacyflag === 'True' ) { privacyflag = true }
  if ( privacyflag === 'False' ) { privacyflag = false }
  if ( blockedby === 'True' ) { blockedby = true }
  if ( blockedby === 'False' ) { blockedby = false }

  // console.log("privacyflag", privacyflag)
  // console.log("blockedby", blockedby)
  // console.log("sessionusr", sessionusr)

  useEffect(()=>{
    if (prefixurl === undefined) {
      document.body.style.overflow = 'hidden';
    } 
    return () => (document.body.style.overflow = 'visible')
  }, [prefixurl])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // cleanup
  useEffect(() => {
    return () => {
    }
  })

  return (
    <div className='row'>
      <div className='col-lg-6 col-lg-offset-3'>
        <div style={{width:'100%'}}>
          <div className="panel space-4">
            <div>
              {privacyflag===true
                ?
                  <div style={{width:'100%', textAlign:'center'}}>
                    {prefixurl !== undefined
                    ? 
                      <UrlProvider value={prefixurl}>
                        <ProfileImage username={username} />
                      </UrlProvider>
                    : 
                      <ProfileImage username={username} />
                    }
                  </div>
                :
                blockedby===true
                ?
                  <div style={{width:'100%', textAlign:'center'}}>
                    {prefixurl !== undefined
                    ? 
                      <UrlProvider value={prefixurl}>
                        <ProfileImage username={username} />
                      </UrlProvider>
                    : 
                      <ProfileImage username={username} />
                    }
                </div>
                :
                <div>
                    <AppBar position="static" style={{backgroundColor:'#172734'}} >
                      <Tabs
                        sx = {{
                          '& .MuiTab-root' : {
                            textTransform: 'capitalize !important',
                            color: '#adacac !important',
                            fontSize:'medium'
                          },
                          '& .MuiTabs-indicator' :{
                            background: '#ffffff !important'
                          },
                          '& .Mui-selected' :{
                            color: '#ffffff !important'
                          }
                        }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"  
                        TabIndicatorProps={{style: {backgroundColor: "#2d516f"}}}
                      >
                        <Tab icon={<AppsIcon fontSize='large' htmlColor='#ffffff' />} aria-label="Posts" {...a11yProps(0)} />
                        <Tab icon={<PhotoLibraryIcon fontSize='large' htmlColor='#ffffff' />} aria-label="Media" {...a11yProps(1)} />
                        <Tab disableRipple label="Following" {...a11yProps(2)} />
                        <Tab disableRipple label="Followers" {...a11yProps(3)} />
                        <Tab disableRipple label="Locations" {...a11yProps(4)} />

                        {username === sessionusr ? 
                        <Tab icon={<BlockIcon fontSize='large' htmlColor='#ffffff' />} aria-label="Blocked" {...a11yProps(5)} />
                        : null}
                      </Tabs>
                    </AppBar>
                  {prefixurl !== undefined
                    ? <React.Fragment> 
                        <UrlProvider value={prefixurl}>
                          <TabPanel value={value} index={0} >
                            <ProfileFeed username={username} />
                          </TabPanel>
                          <TabPanel value={value} index={1} >
                            <ProfileMedia username={username} />
                          </TabPanel>
                          <TabPanel value={value} index={2} >
                            <ProfileFollowing username={username} />
                          </TabPanel>
                          <TabPanel value={value} index={3} >
                            <ProfileFollowedBy username={username} /> 
                          </TabPanel>
                          <TabPanel value={value} index={4} >
                            <LocationsFollowed username={username} /> 
                          </TabPanel>
                          <TabPanel value={value} index={5} >
                            <ProfileBlocked username={username} />
                          </TabPanel>
                        </UrlProvider>
                      </React.Fragment>
                  : <React.Fragment>
                      <TabPanel value={value} index={0} >
                        <ProfileFeed username={username} />
                      </TabPanel>
                      <TabPanel value={value} index={1} >
                        <ProfileMedia username={username} />
                      </TabPanel>
                      <TabPanel value={value} index={2} >
                        <ProfileFollowing username={username} />
                      </TabPanel>
                      <TabPanel value={value} index={3} >
                        <ProfileFollowedBy username={username} /> 
                      </TabPanel>
                      <TabPanel value={value} index={4} >
                        <LocationsFollowed username={username} /> 
                      </TabPanel>
                      <TabPanel value={value} index={5} >
                        <ProfileBlocked username={username} />
                      </TabPanel>
                    </React.Fragment>}
                  </div>
              }
            </div>
          </div>  
        </div>  
      </div> 
    </div>  
  );
}


export default ProfileTabs