import React, {useContext, useState, useEffect, useRef, forwardRef} from 'react';
import {apiCommentCreate, apiCommentReplyEdit} from '../lookup/lookup';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import classes from './assets/style.module.css';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import {constructFormData, imagePreviewGenerate, getPolicyForS3, ProcessEditedMedia, deleteWithPolicy, getCommentObject} from './utilities';
import $ from "jquery";
import UrlContext from '../lookup/prefixurl';
import uploadIcon from './assets/images/uploadIcon.png';
import uploadIconDisabled from './assets/images/uploadIconDisabled.png';

// const urlToS3Temp      = 'http://localhost:8000/api/post/mediauploadToTemp/'
// const urlToS3Copy      = 'http://localhost:8000/api/comments/mediaCopyFromTemp/'
// const urlGetCommDetail = 'http://localhost:8000/api/comments/'


export default forwardRef(function CommentCreateEdit(props, ref) {
    const {didComment}  = props;
    const {blogSlug}    = props;
    const {comm}        = props;
    const {className}   = props;
    const textAreaRef   = ref;
    const prefixurl = useContext(UrlContext);
    const fileUpload    = useRef(null);
    const [opacity, setOpacity]                     = useState(0.5);
    const [val, setVal]                             = useState('');
    const [progress, setProgress]                   = useState(0);
    const [jqXHR, setjqXHR]                         = useState(null);
    const [deleteOrigMedia, setDeleteOrigMedia]     = useState(false);
    const [mediaInTemp, setMediaInTemp]             = useState(false)
    const [canDelete, setCanDelete]                 = useState(false)
    const [awsUploadKey, setAwsUploadKey]           = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl]     = useState(null);
    const [medianameFromTemp, setMedianameFromTemp] = useState(null);
    const [mediapathFromTemp, setMediapathFromTemp] = useState(null);
    const [mediatypeFromTemp, setMediatypeFromTemp] = useState(null);
    const [fileDisplay, setFileDisplay]             = useState(false);
    const [enableSendIcon, setEnableSendIcon]       = useState(false);
    const [inFocus, setInFocus]                     = useState(false);
    const [openForImage, setOpenForImage]           = useState(false);
    const [openForVideo, setOpenForVideo]           = useState(false);

    var navbarElement = document.querySelector('.navbar-fixed-bottom');

    // temp file gets uploaded in AWS S3 : media/blogpost/temp/{user.id}/{filename_final}'
    const urlToS3Temp      = prefixurl + '/post/mediauploadToTemp/'
    const urlToS3Copy      = prefixurl + '/comments/mediaCopyFromTemp/'
    const urlGetCommDetail = prefixurl + '/comments/'

    useEffect(()=> {
        if (comm) {
            if (comm.get_media_download_url) {
                if (comm.mediatype.slice(0,6) === "video/" ){
                    setImagePreviewUrl(comm.get_sd_download_url)
                } else {
                    setImagePreviewUrl(comm.get_media_download_url)
                }
                setFileDisplay(true)
                setOpacity(0)
            }
            textAreaRef.current.focus();
        } 
    },[comm, textAreaRef])

    const handleClickImage = () => {
        setOpenForImage(true);
    };
    const handleClickVideo = () => {
        setOpenForVideo(true);
    };
  
    const handleCloseImage = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenForImage(false);
    };
    const handleCloseVideo = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenForVideo(false);
    };
    const handleCameraBlur = (event) => {
        event.stopPropagation()
        textAreaRef.current.focus();
    };
    

    const actionImage = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseImage}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      const actionVideo = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseVideo}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const UsePolicyAndUpload = async (file, policyData) => {
        if  ("medianame" in policyData) {
            setMedianameFromTemp(policyData["medianame"])
            delete policyData["medianame"]
        }
        if  ("mediapath" in policyData) {
            setMediapathFromTemp(policyData["mediapath"])
            delete policyData["mediapath"]
        }
        if  ("mediatype" in policyData) {
            setMediatypeFromTemp(policyData["mediatype"])
            delete policyData["mediatype"]
        }
        const fd = constructFormData(policyData)
        fd.append('file', file)
        const awsEndpoint = policyData.url
        setAwsUploadKey(policyData.fields.key)
        setjqXHR($.ajax({
            type : "POST",
            url : awsEndpoint,
            data : fd,
            xhr: function(){
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload){
                    myXhr.upload.addEventListener('progress', function(e){
                        if (e.lengthComputable){
                            var percent = e.lengthComputable ? (e.loaded / e.total) * 100 : 0;
                            setProgress(parseFloat(percent.toFixed(2)))
                            if (parseFloat(percent.toFixed(2)) === 100) {
                                setOpacity(0)
                            }
                        }
                    }, false);
                }
                return myXhr;
            },
            success : function(e){
                setCanDelete(true)
                setMediaInTemp(true)
                setEnableSendIcon(true)
            },
            error : function(_jqXHR, textStatus){
                console.log("S3 Upload Error:", textStatus)
            },
            cache : false,
            contentType : false,
            processData : false
        }))
    }

    const handleImagePreviewSetup = (callback) => {
        if (callback) {
            setImagePreviewUrl(callback)
            setOpacity(0.5)
        } else {
            setFileDisplay(false)
        }
    }

    const handleSetComm = (response) => {
        let commid = null
        if (comm){
          commid = comm.id
        } 
        let data = {
            "commId":commid,
            "response":response
        }
        didComment(data)
        setFileDisplay(false)
        setVal('')
    }


    const handleBackendCommentProcess = async (response, status) => {
        if (status === 200 || status === 201) {
            //   if media file is in temp, process media file
            if (mediaInTemp) {
                let copy_from = mediapathFromTemp
                let filename_to_copy = copy_from.split("/").pop()
                let data ={
                    id : response.id,
                    content: val,
                    medianameFromTemp : medianameFromTemp,
                    mediatypeFromTemp : mediatypeFromTemp,
                    copy_from : copy_from,
                    filename : filename_to_copy
                }
                ProcessEditedMedia(data, urlToS3Copy, handleBackendUpdate)
                setMediaInTemp(false)

            } else {
                // no media, just update comments
                handleSetComm(response)
            }
        } else {
            alert("An error occurred.")
        }
    }
    
    const handleChange = (event) => {
        event.preventDefault()
        setVal(event.target.value)
        let key = event.target.name
        let value = event.target.value.trim()
        if (key === 'comment'){
            if (value.length > 1000){
                value = value.substring(0,1000)
            }
            if (value.length === 0){
                if (!mediaInTemp) {
                    setEnableSendIcon(false)
                } else {
                    setEnableSendIcon(true)
                }
            } else {
                setEnableSendIcon(true)
            }
        }
    }
    
    const handleOnFocus = (event) => {
        event.preventDefault()
        if (navbarElement != null) {
            navbarElement.style.display = 'none';
            setInFocus(true);
        }
    }

    const handleBlur = (event) => {
        event.preventDefault()
        if (event.relatedTarget === undefined || event.relatedTarget === null) { // if Submit button is not clicked
            setVal(''); 
         }
        if (navbarElement != null) {
            navbarElement.style.display = 'block';
            setInFocus(false)
        }
    }

    // set cursor at the end of text
    const handleOnSelect = (event) => {
        const {value} = event.target;
        const position = value.length;
        event.target.setSelectionRange(position, position);
    }

    const handleUploadAbort = async (event) => {
        event.preventDefault()
        if (jqXHR) {
            jqXHR.abort()
        }
        setFileDisplay(false)
        setDeleteOrigMedia(true)
        setImagePreviewUrl("")
        setProgress(0)
        if (mediaInTemp) {
            if (awsUploadKey) {
                deleteWithPolicy(awsUploadKey, urlToS3Temp)
                setMediaInTemp(false)
                setAwsUploadKey(null)
            }
        }
        if (!val) {
            setEnableSendIcon(false)
        }
    }

    const handleFileChange = async (event) => {
        event.persist()
        setProgress(0)
        if (awsUploadKey) {
            deleteWithPolicy(awsUploadKey, urlToS3Temp)
        }
        setAwsUploadKey(null)
        let file = event.target.files[0];
        if (file) {
            if (file.type.match('image')) {
                if (file.size <= 11000000) {
                    setFileDisplay(true)
                    imagePreviewGenerate(file, handleImagePreviewSetup)
                    getPolicyForS3(file, urlToS3Temp, UsePolicyAndUpload)
                } else {
                    handleClickImage()
                }
            }

            if (file.type.match('video')) {
                if (file.size <= 51000000) {
                    setFileDisplay(true)
                    imagePreviewGenerate(file, handleImagePreviewSetup)
                    getPolicyForS3(file, urlToS3Temp, UsePolicyAndUpload)
                } else {
                    handleClickVideo()
                }
            }
        }
        event.target.value = ''
    }
    
    const handleBackendUpdate = async (response, status) => {
        setMediaInTemp(false)
        setDeleteOrigMedia(false)
        let urlCommDel = urlGetCommDetail + response.comment_id +'/'
        getCommentObject(response.id, urlCommDel, handleSetComm)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const newVal = textAreaRef.current.value
        if (comm !== null){ // means Comment or Reply is in Edit mode
            if (mediaInTemp) {
                let copy_from = mediapathFromTemp
                let filename_to_copy = copy_from.split("/").pop()
                let data ={
                    id : comm.id,
                    content: newVal,
                    medianameFromTemp : medianameFromTemp,
                    mediatypeFromTemp : mediatypeFromTemp,
                    copy_from : copy_from,
                    filename : filename_to_copy
                }
                ProcessEditedMedia(data, urlToS3Copy, handleBackendUpdate)
            } else if (deleteOrigMedia) {
                let data ={
                    id : comm.id,
                    content: newVal,
                    medianameFromTemp : '',
                    mediatypeFromTemp : '',
                    copy_from : '',
                    filename : ''
                }
                ProcessEditedMedia(data, urlToS3Copy, handleBackendUpdate)
            } else {
                apiCommentReplyEdit(prefixurl, newVal, comm.id, handleBackendCommentProcess)
            }
        } else { // means new comment
            let data = {
                content   : newVal,
                medianame : null,
                mediapath : null,
                mediatype : null
            }
            apiCommentCreate(prefixurl, data, blogSlug, handleBackendCommentProcess)
        }
        setVal(''); 
        textAreaRef.current.value = ''
        setEnableSendIcon(false)
    }

    useEffect(() => {
        return () => {
            if (canDelete) {
                setjqXHR(null)
            }
        }
    },[canDelete])

    useEffect(() => {
        return () => {
            if (awsUploadKey !== null) {
                deleteWithPolicy(awsUploadKey, urlToS3Temp)
            }
        }
    },[awsUploadKey, urlToS3Temp ])

    let $imagePreview = (<div></div>);
    if (imagePreviewUrl) {
        $imagePreview = (
            <div className={classes.imgPreviewBox}>
                <div className={classes.imageopaque} style={{opacity: opacity}}></div>
                <div className={classes.cancelImgBtn}>
                    <IconButton className='mediaUpload-cancel' onClick={handleUploadAbort}>
                        <CancelSharpIcon htmlColor='#003049' fontSize='small' />
                    </IconButton>
                </div>
                <img src={imagePreviewUrl} alt="icon" className={classes.imgPreview}/> 
                <div>
                    <LinearProgress 
                     sx={{
                        width: '100%',
                        height: '2px',
                        borderRadius: '0 0 4px 4px',
                        backgroundColor: `rgb(${"56,54,61"},0.1)`,
                        "&.MuiLinearProgress-bar": {
                        backgroundColor: '#003049'
                        }
                    }} 
                    variant="determinate" 
                    value={progress} />
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <form key='comment' 
                noValidate 
                autoComplete="off" 
                onSubmit={(event) => {handleSubmit(event)}} 
            >

                <div className={inFocus ? classes.commWrapperInFocus : classes.commWrapper}>
                    <div className={classes.imgContainerComm}>
                        {fileDisplay ? $imagePreview :'' }
                    </div>
                        {
                            fileDisplay
                            ?
                            <div className={classes.commPhotoCamDisabled}  style={{backgroundImage: 'url(' + uploadIconDisabled + ')',  backgroundRepeat: 'no-repeat'}}/>
                            :
                            <div className={classes.commPhotoCam} style={{backgroundImage: 'url(' + uploadIcon + ')', backgroundRepeat: 'no-repeat'}}>
                                <label htmlFor="blogCommentMedia" style={{width:"40px", height:"40px"}} /> 
                                <input type="file"
                                    ref={fileUpload}
                                    id="blogCommentMedia"
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, video/*"  
                                    onChange={handleFileChange}
                                    onBlur={(event) => {handleCameraBlur(event)}}
                                />
                            </div>
                        }
                        

                    <div className={classes.commInput}>
                        <TextField
                            id="filled-textarea-comment"
                            inputRef={textAreaRef}
                            sx = {{
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#EEEEEE"
                                },
                                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#EEEEEE"
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#EEEEEE"
                                },
                                "& .MuiOutlinedInput-multiline": {
                                    backgroundColor: "#FFFFFF"
                                },
                                "& .MuiOutlinedInput-root": {
                                    padding: "7px 0 2px 0"
                                }
                            }}
                            placeholder='Comment' 
                            required={false} 
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleOnFocus}
                            onSelect={handleOnSelect}
                            name='comment'
                            multiline
                            // rows={1}
                            maxRows={4}
                            fullWidth
                            // InputProps={{ disableUnderline: true }}
                            inputProps={{ style:
                                            {
                                                backgroundColor:'#eeeeee',
                                                padding:'1px',
                                                fontSize: '12px', 
                                                lineHeight: '20px', 
                                                letterSpacing: '-0.32',
                                                position:'absolute',
                                                bottom:'-20px',
                                                zIndex: '1',
                                                borderRadius:'4px',
                                                width: '-webkit-fill-available'
                                            }
                                        }}
                        />
                    </div>
                    <div className={classes.commformbtn}>
                        {enableSendIcon ?
                            <IconButton type='submit'><SendIcon fontSize='large' htmlColor='#4AA6DE' /></IconButton> 
                        :  
                            null
                        }
                    </div> 
                    <Snackbar
                        open={openForVideo}
                        autoHideDuration={3000}
                        onClose={handleCloseVideo}
                        message="Cannot upload. Video size exceeds 50 MB."
                        action={actionVideo}
                    />
                    <Snackbar
                        open={openForImage}
                        autoHideDuration={3000}
                        onClose={handleCloseImage}
                        message="Cannot upload. Image size exceeds 10 MB."
                        action={actionImage}
                    />
                </div>
            </form>
        </div> 
    )
})