import React, { useEffect, useRef, useState } from "react"
import {useLayoutEffect} from 'react';
import {apiImageLoading, apiPosterImageLoading} from '../lookup/lookup'


export function useLockBodyScroll() {
  useLayoutEffect(() => {
   // Get original value of body overflow
   const originalStyle = window.getComputedStyle(document.body).overflow;  
   // Prevent scrolling on mount
   document.body.style.overflow = 'hidden';
   // Re-enable scrolling when component unmounts
   return () => document.body.style.overflow = originalStyle;
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function constructFormData(policyData) {
    const fd = new FormData()
    const policyFields = policyData.fields
    const objectEntries = Object.entries(policyFields)
    for ( const [key, value] of objectEntries){
        fd.append(key, value)
    }
    return fd
}

export function imagePreviewGenerate(file, callback){
     // Process for Image Preview
     let fileReader = new FileReader();
     if (file.type.match('image')) {
         fileReader.onloadend = () => {
            callback(fileReader.result)
         }
         fileReader.readAsDataURL(file)
     }
     // Process for Video Preview
     if (file.type.match('video')) {
        var c = document.createElement('canvas');
        var w = c.width = 200;
        var h = c.height = 200;
        var pathColor = "rgba(255,255,255,0.5)";
        var b = Math.min(w, h);
        var ctx = c.getContext('2d');
        ctx.rect(0, 0, w, h);
        ctx.fillStyle = "black";
        ctx.fill();
        
        // Draw circle
        ctx.beginPath();
        ctx.arc(w / 2, h / 2, b * .16, 0, 2 * Math.PI);
        ctx.strokeStyle = pathColor;
        ctx.lineWidth = Math.round(b * .03);
        ctx.stroke();
        ctx.closePath();
        
        // Draw triangle
        ctx.beginPath();
        ctx.moveTo(w / 2 - (b * .05), h / 2 - (b * .08));
        ctx.lineTo(w / 2 + (b * .08), h / 2);
        ctx.lineTo(w / 2 - (b * .05), h / 2 + (b * .08));
        ctx.lineTo(w / 2 - (b * .05), h / 2 - (b * .05))
        ctx.fillStyle = pathColor;
        ctx.fill();

        let image = c.toDataURL('image/png');
        let success = image.length > 10;
        if (success) {
            callback(image)
        }
     } 
}


export function getPolicyForS3(file, endpoint, callback){
        const data = {
            medianame: file.name,
            mediatype: file.type
        }
        const jsonData = JSON.stringify(data)
        const xhr = new XMLHttpRequest();
        const csrftoken = getCookie('csrftoken');
        xhr.open("POST", endpoint)
        xhr.setRequestHeader('Content-Type', 'application/json')
        
        if (csrftoken) {
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
            xhr.setRequestHeader("X-CSRFToken", csrftoken) 
        }
        xhr.onload = function() {
            if (xhr.status === 200) {
                //Get Policy Data
                const policyResponseData = JSON.parse(xhr.responseText)
                callback(file, policyResponseData)
            } else {
               console.log(xhr.response)
            }
        }
        xhr.send(jsonData)
}

export function getPosterImagePolicyForS3(file, slug, endpoint, callback){
    const data = {
        medianame: file.name,
        mediatype: file.type,
        slug: slug
    }
    const jsonData = JSON.stringify(data)
    const xhr = new XMLHttpRequest();
    const csrftoken = getCookie('csrftoken');
    xhr.open("POST", endpoint)
    xhr.setRequestHeader('Content-Type', 'application/json')
    
    if (csrftoken) {
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        xhr.setRequestHeader("X-CSRFToken", csrftoken) 
    }
    xhr.onload = function() {
        if (xhr.status === 200) {
            //Get Policy Data
            const policyResponseData = JSON.parse(xhr.responseText)
            callback(file, policyResponseData)
        } else {
           console.log(xhr.response)
        }
    }
    xhr.send(jsonData)
}


export function ProcessEditedMedia(data, endpoint, callback){
    const jsonData = JSON.stringify(data)
    const xhr = new XMLHttpRequest();
    const csrftoken = getCookie('csrftoken');
    xhr.open("POST", endpoint)
    xhr.setRequestHeader('Content-Type', 'application/json')
    
    if (csrftoken) {
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        xhr.setRequestHeader("X-CSRFToken", csrftoken)
    }
    xhr.onload = function() {
        if (xhr.status === 200) {
            const ResponseData = JSON.parse(xhr.responseText)
            callback(ResponseData, xhr.status )
        } else {
           console.log(xhr.response, xhr.status)
        }
    }
    xhr.send(jsonData)
}


export function ProcessPosterFileUpload(data, endpoint, callback){
    const jsonData = JSON.stringify(data)
    const xhr = new XMLHttpRequest();
    const csrftoken = getCookie('csrftoken');
    xhr.open("POST", endpoint)
    xhr.setRequestHeader('Content-Type', 'application/json')
    
    if (csrftoken) {
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        xhr.setRequestHeader("X-CSRFToken", csrftoken)
    }
    xhr.onload = function() {
        if (xhr.status === 200) {
            const ResponseData = JSON.parse(xhr.responseText)
            callback(ResponseData, xhr.status )
        } else {
           console.log(xhr.response, xhr.status)
        }
    }
    xhr.send(jsonData)
}


export function  deleteWithPolicy(awsUploadKey, endpoint) {
    if (awsUploadKey !== null) {
        const jsonDeleteData = JSON.stringify(awsUploadKey)
        const ddHR = new XMLHttpRequest()
        const csrftoken = getCookie('csrftoken');
        ddHR.open("DELETE", endpoint, true)
        ddHR.setRequestHeader('Content-Type', 'application/json')
        if (csrftoken) {
            ddHR.setRequestHeader("X-Requested-With", "XMLHttpRequest")
            ddHR.setRequestHeader("X-CSRFToken", csrftoken)
        }
        ddHR.onload = function() {
            if (ddHR.status === 200) {
                // callback(ddHR.status)
            } else {
                console.log("Unable to delete Image. Please try again.")
            }
        }
        ddHR.send(jsonDeleteData)
    }
}

export function deleteUserMediaFile(key, endpoint, callback) {
    const jsonDeleteData = JSON.stringify(key)
    const ddHR = new XMLHttpRequest()
    const csrftoken = getCookie('csrftoken');
    ddHR.open("DELETE", endpoint, true)
    ddHR.setRequestHeader('Content-Type', 'application/json')
    ddHR.setRequestHeader('X-CSRFTOKEN', csrftoken)
    ddHR.onload = function() {
        if (ddHR.status === 200) {
            callback({"key": key}, 200)
        } else {
            callback({"error": "File delete error"}, 400)
        }
    }
    ddHR.send(jsonDeleteData)
}


export function getCommentObject(data, endpoint, callback) {
    const jsonData = JSON.stringify(data)
    const ddHR = new XMLHttpRequest()
    const csrftoken = getCookie('csrftoken');
    ddHR.open("GET", endpoint, true)
    ddHR.setRequestHeader('Content-Type', 'application/json')
    if (csrftoken) {
        ddHR.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        ddHR.setRequestHeader("X-CSRFToken", csrftoken)
    }
    ddHR.onload = function() {
        if (ddHR.status === 200) {
            callback(JSON.parse(ddHR.response))
        } else {
            console.log("Unable to retrieve Comment. Please try again.")
        }
    }
    ddHR.send(jsonData)
}


const delay = ms => new Promise(res => setTimeout(res, ms));

export async function verifyImage(imgUrl, callback) {
    const image = new Image()
    var loaded = false
    var unmount = false
    var j = 1
    const loadImage = async () => {
        if (!loaded) {
            image.src = imgUrl;
            image.onload = () =>{
                loaded = true
                callback(loaded)
            }
            image.onerror = () =>{
                j++
                if (j === 60) {
                    unmount = true
                    callback(loaded)
                } 
            }
        }
    }
    for (let i = 1; i <= 60; i++){
        if (!unmount) {
            if (!loaded) {
                await delay(1000);
                loadImage()
            } 
        }
    }
}

export async function verifyVideo(videoUrl, callback) {
    const video = document.createElement('video');
    var loaded = false
    var unmount = false
    var j = 1
    const loadVideo = async () => {
        if (!loaded) {
            video.src = videoUrl;
            video.onloadedmetadata = (event) => {
                // console.log('The duration and dimensions of the media and tracks are now known. ');
                loaded = true
                video.remove();
                callback(loaded)
            };
            // video.oncanplay = (event) => {
            //     console.log("video can play but is not fully loaded");
            //     loaded = true
            //     video.remove();
            //     callback(loaded)
            // }
            video.onerror = (e) =>{
                // console.log("error",e)
                j++
                if (j === 60) {
                    unmount = true
                    callback(loaded)
                } 
            }
        }
    }
    for (let i = 1; i <= 60; i++){
        if (!unmount) {
            if (!loaded) {
                await delay(1000);
                loadVideo()
            } 
        }
    }
}



export function ImageLoading(prefixurl, callback) {
    const handleLoadSrc = (response, status) => {
        if (status === 200){
            callback(response.loadingimg_url)
        } else {
            callback(null)
        }
    }
    apiImageLoading(prefixurl, handleLoadSrc)
}

export function PosterImageLoading(prefixurl, callback) {
    const handleLoadSrc = (response, status) => {
        if (status === 200){
            callback(response.loadingimg_url)
        } else {
            callback(null)
        }
    }
    apiPosterImageLoading(prefixurl, handleLoadSrc)
}


export const VisualViewport = ({
  as: Element = "div",
  children,
  style = {},
  ...props
}) => {
  const ref = useRef (null)

  const [viewport, setViewport] = useState ({
    "maxHeight": "100vh",
    "maxWidth": "100vw"
  })

  const updateViewport = () => {
    setViewport ({
      "maxHeight": window.visualViewport.height,
      "maxWidth": window.visualViewport.width
    })

    window.scrollTo (0, ref.current.offsetTop)
  }

  useEffect (() => {
    if (
      typeof window !== "undefined"
        && typeof window.visualViewport !== "undefined"
    ) {
      updateViewport ()

      window.visualViewport.addEventListener ("resize", updateViewport)

      return () =>
        window.visualViewport.removeEventListener ("resize", updateViewport)
    }
  }, [])

  return (
    <Element
      ref={ref}
      style={{ ...style, ...viewport }}
      {...props}
    >
      {children}
    </Element>
  )
}