import React, { useContext, useState, useEffect } from 'react';

//third party plugins
import Typography from "@mui/material/Typography";
import PullToRefresh from "react-simple-pull-to-refresh";

// local
import UrlContext from '../../lookup/prefixurl'
import {LocationsFollowedInline}  from './profilelocationinline'
import {apiUserLocationsFollowed } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'


export function LocationsFollowed(props) {
    const prefixurl = useContext(UrlContext)
    const [userLocations, setUserLocations] = useState(null);
    const [doneLoading, setDoneLoading] = useState(false);

    useEffect(() => {
        apiUserLocationsFollowed(prefixurl, props.username, handleUserLocationsLookup)
    }, [prefixurl, props])
    
    const handleRefreshc = () => {
        apiUserLocationsFollowed(prefixurl, props.username, handleUserLocationsLookup)
        return new Promise((resolve) => {
            setTimeout(resolve, 1000)
        });
    };


    const handleUserLocationsLookup = (response, status) => {
        if (status === 200){
            setUserLocations(response)
            setDoneLoading(true)
        }
    }

    return (
        <div>
            {(doneLoading === true) 
            ? 
            <div>
            <PullToRefresh
                onRefresh={handleRefreshc}
                pullingContent = {false}
            >
                <div>
                    <div className={classes.bndhdr} >
                        <Typography style={{ fontSize: '1em', color: '#ffffff' }}>
                            Following{' '}
                            {userLocations.locationfollowed_by.length > 1 ? userLocations.locationfollowed_by.length + ' locations' : 
                                userLocations.locationfollowed_by.length === 1 ? userLocations.locationfollowed_by.length + ' location' : 
                                userLocations.locationfollowed_by.length === 0 ? 'no Locations' : null
                            } 
                        </Typography>
                    </div>
                    {userLocations.locationfollowed_by && userLocations.locationfollowed_by.map((locationfollowedby, i) => {
                        return (
                            <div key={i} className={classes.echdiv1} >
                                <LocationsFollowedInline locationfollowed={locationfollowedby} 
                                                         canSetNotification={userLocations.sessionUser === props.username} />
                            </div>
                            )
                        })
                    }
                </div>
            </PullToRefresh>
            </div> 
             : 
               null
            }
        </div>
    )
}

