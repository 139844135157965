import React, {useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

// local
import UrlContext from '../lookup/prefixurl'
import classes from '../blogs/assets/style.module.css'
import { 
        apiNotifyReadUpdate, 
        apiNotifyRemove, 
        apiNotifyRemoveUndo,
        apiDonotNotifylectureToggle,
        apiUserDoNotNotifyToggle
    } from '../lookup/lookup';


export function NotifyInline(props) {
    const {notification} = props
    const {notificationState} = props
    const {donotnotifyState} = props
    const prefixurl = useContext(UrlContext)
    const [disp, setDisp] = useState(true)
    const [readFlag, setReadFlag] = useState(false)
    const [state, setState] = useState({ bottom: false })
    const [snackPack, setSnackPack] = React.useState([]);
    const [open, setOpen] = React.useState(true);
    const [messageInfo, setMessageInfo] = React.useState(undefined);

    const [status, setStatusBase] = useState("");
    const setStatus = msg => setStatusBase({ msg, date: new Date() });



    useEffect(()=>{
        //
    },[disp, setDisp])


    useEffect(()=>{
        setReadFlag(notification.read)
    },[props, notification.read])

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpen(false);
        }
    }, [snackPack, messageInfo, open]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    
    const handleRemove = (response, status) => {
        if (status !== 200){
            console.log("could not remove notification")
        }
    }
    const handleRemoveUndo = (response, status) => {
        if (status !== 200) {
            console.log("could not undo notification removed.")
        }
    }

    const handleNotificationRemove = (event)=> {
        event.preventDefault()
        setStatus("Done Reading")
        const message ='Notification Removed'
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
        apiNotifyRemove(prefixurl, notification.id, handleRemove)
        setDisp(false)
    }

    const handleActionNotificationEvent = (response, status) => {
        if (status === 200) {
            notification.donotnotifyfromlecture = response.donotnotifyfromlecture
            notificationState(response.donotnotifyfromlecture, notification.target_text)
        }
    }

    const handleNotificationToggle = () =>{
        apiDonotNotifylectureToggle(prefixurl, notification.target_text, handleActionNotificationEvent)
    }


    const handleDoNotNotifyToggle = (event) =>{
        event.preventDefault()
        apiUserDoNotNotifyToggle(prefixurl, 
                                    notification.actor_content_object.username, 
                                    notification.actor_content_object.donotnotified ? "start" : "stop", (response, status) => {
                                        if (status === 200) {
                                            notification.actor_content_object.donotnotified = response.donotnotified
                                            donotnotifyState(response.donotnotified, notification.actor_content_object.username)
                                        }
                                    }
                                )
    }


    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                <ListItem button key={'notifyremove'} onClick={handleNotificationRemove}>
                    <ListItemIcon>{<NotificationsOffIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>Remove Notification</Typography>
                </ListItem>
                <ListItem button key={'donotnotify'} onClick={handleNotificationToggle}>
                    <ListItemIcon>{<NotificationsPausedIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {notification.donotnotifyfromlecture 
                        ? `Turn on notifications for ${notification.target_content_object.title}` 
                        : `Turn off notifications for ${notification.target_content_object.title}`}
                    </Typography>
                </ListItem>
                <ListItem button key={'donotnotified'} onClick={handleDoNotNotifyToggle}>
                    <ListItemIcon>{<NotificationsPausedIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {notification.actor_content_object.donotnotified
                        ? `Turn on notifications from 
                                ${notification.actor_content_object.first_name ? notification.actor_content_object.first_name : notification.actor_content_object.username}` 
                        : `Turn off notifications from 
                                ${notification.actor_content_object.first_name ? notification.actor_content_object.first_name : notification.actor_content_object.username}`
                        }
                    </Typography>
                </ListItem>
            </List>
        </div>
    );
    
    const handleUndo =()=> {
        apiNotifyRemoveUndo(prefixurl, notification.id, handleRemoveUndo)
        setDisp(true)
        handleClose()
    }
    
    const handleSetReadFlag =(response, status)=> {
        if (status === 200) {
            setReadFlag(true)
        }
    }

    const handleReadFlag = () => {
        if (readFlag === false){
            apiNotifyReadUpdate(prefixurl, notification.id, handleSetReadFlag)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
    const handleExited = () => {
        setMessageInfo(undefined);
    };

    return (
      <div>
          {notification !== undefined ?
            disp ?
                <div className={classes.notifycontainer} style={{border:!readFlag && 'solid #2d516f', backgroundColor:!readFlag && '#2d516f'}} >
                    <a  href= {notification.target_content_object.url} style={{color:"black", textDecoration:"none"}} onClick={handleReadFlag}>
                    <div style={{display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center', position:'relative'}}>
                        <div style={{marginRight:'12px',alignSelf:'flex-start', position:'relative', display:'flex', flexDirection:'column', marginBottom:'8px', marginTop:'8px'}}>
                            <div className={classes.avatar34}> 
                                <img className={classes.avtrimg} src={notification.actor_content_object.avatar} alt=''></img>
                            </div>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center'}}>
                            <div style={{flexBasis:'0px', paddingTop:'12px', position:'relative', display:'flex', justifyContent:'space-between', 
                                            alignItems:'stretch', flexShrink:'1', paddingBottom:'12px', flexDirection:'column', flexGrow:'1'}}>
                                <div style={{color:'#ffffff'}}>
                                    <span style={{wordBreak:'break-word', fontSize:'1.2rem', textAlign:'left', fontWeight:'500'}}>
                                    <Typography style={{ fontSize: '1.25rem', color: '#ffffff' }}>
                                        <strong>{notification.actor_content_object.first_name}{' '}{notification.actor_content_object.last_name}</strong>
                                        {' '}{notification.verb}{' '}
                                        <strong>{notification.target_content_object.title}</strong>
                                    </Typography>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>  
                    </a>
                    <div className={classes.bookmarkmenu}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={toggleDrawer('bottom', true)}
                            >
                            <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                        </IconButton>
                        <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                            {list('bottom')}
                        </Drawer>
                    </div>
                </div>  : null
          : ""}
        {
            status 
            ? 
            <Snackbar
                key={messageInfo ? messageInfo.key : undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                onExited={handleExited}
                message={messageInfo ? messageInfo.message : undefined}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleUndo}>
                        Undo
                        </Button>
                    </React.Fragment>
                    }
                sx = { theme => ({
                    '&.MuiSnackbar-root': {
                        [theme.breakpoints.down('md')]: {
                            bottom: 100,
                          },
                    }
                })}
            />
            : null
        }  
      </div>
    )
}
