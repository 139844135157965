import React, { useState, useEffect } from 'react';

import SlidingPane from "react-sliding-pane";
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';


// local
import { UrlProvider } from '../../lookup/prefixurl'
import { GroupInline} from './groupinline'
import {GroupMainListInline} from './groupmainlistinline';
import {apiAddNewGroup, apiGroupCreate, apiGroupMainListMyItems, apiGroupMainList} from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import Creategroup from '../group/Creategroup';
import {GroupFeed} from '../group/groupfeed';
import Backdrop from '../group/Backdrop';
import {SearchPopup} from './searchpopup';


export function GroupMainList(props) {
    const {prefixurl}                           = props;
    const [scrollToPos, setScrollToPos]         = useState(0);
    const [nextUrl, setNextUrl]                 = useState(null)
    const [groupMyItems, setGroupMyItems]       = useState(false)
    const [groupItems, setGroupItems]           = useState(false)
    const [groups, setGroups]                   = useState([])
    const [myGroups, setMyGroups]               = useState([])
    const [refreshGroup, setRefreshGroup]       = useState(false)
    const [doneLoading, setDoneLoading]         = useState(false)
    const [creatingGroup, setCreatingGroup]     = useState(false)
    const [name, setName]                       = useState('')
    const [description, setDescription]         = useState('')
    const [privacy, setPrivacy]                 = useState(null)
    const [errorName, setErrorName]             = useState(false)
    const [errorPrivacy, setErrorPrivacy]       = useState(false)
    const [newGroupId, setNewGroupId]           = useState(false)
    const [groupSlug, setGroupSlug]             = useState(null)
    const [stateGroupFeed, setStateGroupFeed]   = useState({isPaneGroupFeed: false})
    const [buttonPopup, setButtonPopup]         = useState(false)
    const [showCreateBtn, setShowCreateBtn]     = useState(true)
    let newIndex     = null;


    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreGroups()
        }
    }

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollToPos);
    }

    const startCreateEventHandler = () => {
        setCreatingGroup(true);
    }

    const  handleGroupCreation = (e) => {
        setName('')
        setDescription('')
        setPrivacy(null)
        setErrorPrivacy(false)
        setErrorName(false)
        setCreatingGroup(false);
        setGroupSlug(e.slug)
        setNewGroupId(e.id)
        const handleAddNewGroup = (response, status) => {
            var editedNewGroup = [response.results[0]].concat(myGroups);
            setMyGroups(editedNewGroup)
            setRefreshGroup(!refreshGroup)
            setStateGroupFeed({ isPaneGroupFeed: true })
        }
        apiAddNewGroup(prefixurl, e.slug, handleAddNewGroup)  
    }

    const groupCancelHandler = () => {
        setName('')
        setDescription('')
        setPrivacy(null)
        setErrorPrivacy(false)
        setErrorName(false)
        setCreatingGroup(false);
    }

    const groupCreateHandler = () => {

        if (name.trim().length === 0) {
            setErrorName(true)
        }
        if (privacy === null) {
            setErrorPrivacy(true)
        }
        if (name.trim().length !== 0 && privacy !== null) {
            apiGroupCreate(prefixurl, name, description, privacy, handleGroupCreation)
        }
    }

    const nameEventHandler = (e) => {
        setName(e.target.value)
        setErrorName(false)
    }

    const descriptionEventHandler = (e) => {
        setDescription(e.target.value)
    }

    const privacyEventHandler = (e) => {
        setPrivacy(e.target.value)
        setErrorPrivacy(false)
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '&.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
      }));
      
      const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#106ba3',
        },
      });
      
      function BpRadio(props) {
        return (
          <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
          />
        );
      }

    useEffect(()=> {
        if (refreshGroup === false) {
            setRefreshGroup(true)
        }
    },[refreshGroup, setRefreshGroup])

   

    useEffect(() => {
        if (groupMyItems === false){
            setGroupMyItems(!groupMyItems)
            const handleProfileMyGroupListEvent = async (response, status) => {
                if (status === 200) {
                    const newMyGroups = response.results
                    setMyGroups(newMyGroups)
                    setGroupMyItems(true)
                    const handleProfileGroupListEvent = (response, status) => {
                        if (status === 200) {
                            const newGroups = response.results
                            setGroups(newGroups)
                            setNextUrl(response.next)
                            setGroupItems(true)
                            setDoneLoading(true)
                        }
                    }
                    await apiGroupMainList(prefixurl, handleProfileGroupListEvent)
                }
            }
            apiGroupMainListMyItems(prefixurl, handleProfileMyGroupListEvent)            
        }
    }, [groupMyItems, setGroupMyItems, groupItems, setGroupItems, prefixurl])


    const loadMoreGroups = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newGroups = [...groups].concat(response.results)
                    setGroups(newGroups)
                } else {
                  console.log("There was an error")
                }
            }
            apiGroupMainList(prefixurl, handleLoadNextResponse, nextUrl)
        }
    }


    const handleGroupEdit = async (response) => {
        console.log("response from edit:", response)
        const editedMyGroups = ([...myGroups])
        newIndex = editedMyGroups.findIndex(group => group.group.slug === response.slug)
        if (newIndex >= 0) {
            editedMyGroups[newIndex].group = response
            setMyGroups(editedMyGroups)
            setRefreshGroup(!refreshGroup)
        }
    }
 
    const handleDelete = (itemDeleted) => {
        const editedMyGroups = ([...myGroups])
        newIndex = editedMyGroups.findIndex(group => group.group.slug === itemDeleted)
        editedMyGroups.splice(newIndex, 1)
        setMyGroups(editedMyGroups)
        setRefreshGroup(!refreshGroup)
    }

    const handleGroupLeft = (itemLeft) => {
        const editedMyGroups = ([...myGroups])
        newIndex = editedMyGroups.findIndex(group => group.group.slug === itemLeft)
        editedMyGroups.splice(newIndex, 1)
        setMyGroups(editedMyGroups)
        setRefreshGroup(!refreshGroup)
    }

    const handleSearchPopupClose = () => {
        setButtonPopup(false)
        setShowCreateBtn(true)
    }

    const handleSearchFocus = (event) => {
        setButtonPopup(true); 
        setShowCreateBtn(false);
        document.activeElement.blur(); 
    }

    return (
        <div className='container-fluid' style={{overflow:'auto'}}>
            <div className='row'>
                {
                    showCreateBtn 
                    ?
                        <div style={{ position:'fixed',
                                                                right:'0',
                                                                left:'0', 
                                                                backgroundColor: '#172734', 
                                                                height: '86px', 
                                                                opacity:'0.9',
                                                                zIndex:'1'
                                                                }}>
                        </div>
                    : null
                }
            </div>
            
            <div className='row justify-content-md-center'>
                <div className='col-sm-auto'>
                    <div className={classes.margintop}>
                        {   
                            (doneLoading === true) 
                            ?
                            <div className={classes.grouplist} onScroll={handleScroll} style={{overflowY:'auto'}}>
                                <div className={classes.blogMainPost}>
                                    <Typography color="textSecondary" align='left' gutterBottom={true}  style={{ fontSize: '1.5em', color: '#ffffff', marginTop:"10px" }}> 
                                        Groups
                                        {showCreateBtn 
                                            ?
                                            <Link onClick={startCreateEventHandler} underline="none" style={{color:"#2195F3", float:'right', paddingRight:"10px"}}>
                                                Create
                                            </Link>
                                            : null
                                        }
                                    </Typography>
                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px", marginBottom:"10px"}}> 
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop:'20px', paddingBottom:'20px'}}>
                                            <FormControl 
                                                sx={{ 
                                                    m: 1, width: '15ch',
                                                        '& .MuiOutlinedInput-root': {
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#ffffff"
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#ffffff"
                                                            }
                                                        }
                                                    }} 
                                                style={{minWidth:'300px', backgroundColor:"#ffffff"}}>

                                                <OutlinedInput
                                                    id="outlined-adornment-search"
                                                    type={'text'}
                                                    autoComplete="off"
                                                    onFocus={handleSearchFocus}
                                                    style={{fontSize:'1.3rem', color:'#182734'}}
                                                    placeholder='Search Groups...'
                                                />
                                            </FormControl>
                                        </div>
                                        {!showCreateBtn
                                        ?
                                        <SearchPopup trigger={buttonPopup} setTrigger={handleSearchPopupClose} prefixurl={prefixurl}/>
                                        : null
                                        }
                                    </div>
                                    {creatingGroup && <Backdrop /> }
                                    {creatingGroup && 
                                        <Creategroup title="Create Group" canCancel canCreate onCancel={groupCancelHandler} onCreate={groupCreateHandler}>
                                            <form>
                                                <FormControl fullWidth error={errorName} variant="standard" style={{paddingBottom: '20px', color:'#fff'}}
                                                    sx={{ 
                                                        '& .MuiOutlinedInput-root': {
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#ffffff"
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#ffffff"
                                                            }
                                                        }
                                                    }} 
                                                >
                                                    <OutlinedInput
                                                        id="name"
                                                        autoComplete="off"
                                                        placeholder="Group name..."
                                                        autoFocus
                                                        style={{fontSize:'1.3rem', color:'#182734', background:'#fff'}}
                                                        value={name} 
                                                        onChange={nameEventHandler}  
                                                        error={errorName}
                                                    />
                                                    {errorName && <FormHelperText style={{fontSize:'0.95rem'}}>Add a group name</FormHelperText>}
                                                </FormControl>

                                                <FormControl fullWidth variant="standard" style={{paddingBottom: '20px'}}
                                                    sx={{ 
                                                        '& .MuiOutlinedInput-root': {
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#ffffff"
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#ffffff"
                                                            }
                                                        }
                                                    }} 
                                                > 
                                                    <OutlinedInput
                                                        id="description"
                                                        autoComplete="off"
                                                        placeholder="Description..."
                                                        multiline
                                                        style={{fontSize:'1.3rem', color:'#182734', background:'#fff'}}
                                                        value={description} 
                                                        onChange={descriptionEventHandler}  
                                                    />
                                                </FormControl>
                                                <div style={{padding: '20px', background: '#0D171F', border:'solid 1px #335670', borderRadius:'6px'}}> 
                                                    <FormControl error={errorPrivacy} variant="standard">
                                                        <FormLabel id="privacy" style={{fontSize:'1.35rem', color:'#fff'}}>Privacy</FormLabel>
                                                        <RadioGroup aria-labelledby="privacy" name="privacy" value={privacy} onChange={privacyEventHandler}>
                                                            <FormControlLabel 
                                                                sx = {{'&.MuiFormControlLabel-label' : { color : '#ffffff'}}}
                                                                value="O" control={<BpRadio />} label="Open for all"   labelPlacement="start" 
                                                            />
                                                            <FormControlLabel  
                                                                sx = {{'&.MuiFormControlLabel-label' : { color : '#ffffff'}}}
                                                                value="P" control={<BpRadio />} label="Make Private"  labelPlacement="start" 
                                                            />
                                                        </RadioGroup>
                                                        {errorPrivacy && <FormHelperText style={{fontSize:'0.95rem'}}>Choose Privacy Type</FormHelperText>}
                                                    </FormControl>
                                                </div>
                                            </form>
                                        </Creategroup> 
                                    }

                                    {myGroups.length > 0 
                                    ?
                                    <Typography style={{ fontSize: '1.25em', color: '#ffffff'}} gutterBottom>
                                        My Groups
                                    </Typography>
                                    : null
                                    }
                                    
                                    {myGroups.length > 0 
                                        ? 
                                        myGroups.map((mygroupItem, myIndex)=>{
                                            return (
                                                <div key={myIndex}>
                                                    <UrlProvider value={prefixurl}>
                                                    <GroupInline 
                                                        group={mygroupItem} 
                                                        onGroupEdit={handleGroupEdit}
                                                        onLeft={handleGroupLeft} 
                                                        onDelete={handleDelete} 
                                                        positionFix = {handlePosFixed} 
                                                        mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)}   }
                                                    />
                                                    </UrlProvider>
                                                </div>
                                                )
                                            }) 
                                        :   null
                                    }

                                    {groups.length > 0 
                                    ?
                                    <Typography style={{ fontSize: '1.25em', color: '#ffffff', marginTop:'20px' }} gutterBottom>
                                        All Groups
                                    </Typography>
                                    : null
                                    }
                                    {groups.length > 0 
                                        ? groups.map((groupItem, index)=>{
                                            return (
                                                <div key={index}>
                                                    <UrlProvider value={prefixurl}>
                                                        <GroupMainListInline 
                                                            group={groupItem} 
                                                            onGroupEdit={handleGroupEdit}
                                                            onLeft={handleGroupLeft} 
                                                            onDelete={handleDelete} 
                                                            positionFix = {handlePosFixed} 
                                                            mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)}   }
                                                        />
                                                    </UrlProvider>
                                                </div>
                                                )
                                            }) 
                                        :   null
                                    }
                                    <div style={{'marginBottom':'50px'}}></div>
                                </div>
                                <SlidingPane
                                    className={classes.postCreate}
                                    overlayClassName='groupFeedOverlay'
                                    isOpen={stateGroupFeed.isPaneGroupFeed}
                                    width='100%'
                                    title=""
                                    onRequestClose={() => setStateGroupFeed({ isPaneGroupFeed: false })}
                                >   
                                    <UrlProvider value={prefixurl}>
                                        <GroupFeed  groupId={newGroupId}
                                                    groupSlug={groupSlug} 
                                                    didGroupEdit={handleGroupEdit} 
                                                    didDelete={handleDelete}
                                        />
                                    </UrlProvider>
                                </SlidingPane>
                            </div>
                            : null 
                        }
                    </div>
                </div>
            </div>
        </div>
     )
}


export default GroupMainList