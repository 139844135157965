import React,{useState} from 'react';

// Third Party
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
// Local
import {apiNameAdd} from '../lookup/lookup';


const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#E0E3E7",
                    "--TextField-brandBorderHoverColor": "#B2BAC2",
                    "--TextField-brandBorderFocusedColor": "#6F7E8C",
                    "& label.Mui-focused": {
                    color: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
                },
            },
            MuiInput: {
                styleOverrides: {
                root: {
                    "&:before": {
                    borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                    borderBottom:
                        "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
                },
            },
        },
});


export function NameAdd(props) {
    const {prefixurl}               = props
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname]   = useState('')
    const outerTheme = useTheme();

    const handleNameAdd =(response, status) => {
        if (status === 200) {
            var homepage = prefixurl.substr(0,prefixurl.length - 3);
            window.location.replace(homepage);
        }
    }

    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value.trim())
    };

    const handleLasttnameChange = (event) => {
        setLastname(event.target.value.trim())
    };

    const handleSave = () => {
        apiNameAdd(prefixurl, firstname, lastname,  handleNameAdd)
    }
  

    return (
        <div>
        <div style={{paddingLeft:'50px',color:'#d5d8de', fontSize:'1.4rem'}}>
            Please state your name
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop:'20px', paddingBottom:'20px'}}>

            <div>
                <FormControl  sx = { theme => ({margin: theme.spacing(2),})} >
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            inputProps={{style: {fontSize: '25px', color:'#ffffff'}}}   
                            InputLabelProps={{style: {fontSize: '20px', color:'#374f80'}}}
                            style={{color:'#9e9e9e', minWidth:'280px', fontSize:'1.4rem'}} label="First name" variant="standard" 
                            onChange={handleFirstnameChange}
                        />
                    </ThemeProvider>
                </FormControl>
            </div>
            <div>
                <FormControl sx = { theme => ({ margin: theme.spacing(2), })}>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            inputProps={{style: {fontSize: '25px', color:'#ffffff'}}}
                            InputLabelProps={{style: {fontSize: '20px', marginBottom: '10px', color:'#374f80'}}}
                            style={{color:'#9e9e9e', minWidth:'280px', fontSize:'1.4rem'}} label="Last name" variant="standard" 
                            onChange={handleLasttnameChange}/>
                    </ThemeProvider>
                </FormControl>
            </div>

            <div style={{marginTop:'30px'}}>
                {firstname.length > 1
                ?
                    <Button 
                    style={{ backgroundColor: "#5f9de3"}}
                    size="large" variant="contained"  onClick={() => handleSave()}>Save</Button>
                :                
                    <Button 
                    style={{ backgroundColor: "grey"}}
                    size="large" variant="contained">Save</Button>                                        
                }
            </div>
        </div>
        </div>
     )
}


export default NameAdd