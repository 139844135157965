import React, { useState, useEffect } from 'react';

// local
import {apiNotifyList, apiNotifyDisplayedUpdate} from '../lookup/lookup';
import classes from '../blogs/assets/style.module.css'
import {NotifyInline} from './notifyinline'
import { UrlProvider } from '../lookup/prefixurl'


export function NotifyList(props) {
    const {prefixurl} = props
    const [doneLoading, setDoneLoading] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [nextUrl, setNextUrl] = useState(null)
    const [notificationsDidSet, setNotificationsDidSet] = useState(false)

    useEffect(() => {
        if (notificationsDidSet === false) { 
            const handleNotificationListLookup = (response, status) => {
                if (status === 200){
                    setDoneLoading(true)
                    setNotifications(response.results)
                    setNextUrl(response.next)
                    setNotificationsDidSet(true)
                }
            }
            const handleNotificationDisplayed = (response, status) => {
                if (status === 200){
                    // setNotifyDisplayed(null)
                }
            }
            apiNotifyList(prefixurl, handleNotificationListLookup)
            apiNotifyDisplayedUpdate(prefixurl, handleNotificationDisplayed)
        }
    }, [prefixurl, notifications, notificationsDidSet, setNotificationsDidSet])

    const loadMoreBlogs = (event) =>{
        // event.preventDefault()
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newNotifications = [...notifications].concat(response.results)
                    setNotifications(newNotifications)
                } else {
                  console.log("There was an error")
                }
            }
            apiNotifyList(prefixurl, handleLoadNextResponse, nextUrl)
        }
    }

    const handleNotifyToggle = (added, slug) => {
        const editedNotifications = ([...notifications])
        editedNotifications.forEach(function(obj) { 
            if (obj.target_text === slug){
                obj.donotnotifyfromlecture = added
            };
        });
        setNotifications(editedNotifications)
    }

    const handleDonotNotify = (donotnotified, username) => {
        const editedNotifications = ([...notifications])
        editedNotifications.forEach(function(obj) { 
            if (obj.actor_content_object.username === username){
                obj.actor_content_object.donotnotified = donotnotified
            };
        });
        setNotifications(editedNotifications)
    }

    window.onscroll = () => {
        if (
            document.documentElement.scrollHeight -
            document.documentElement.scrollTop ===
            document.documentElement.clientHeight
        ) {
            loadMoreBlogs()
        }
        
    }
 
    return (
        <div className='container-fluid' style={{overflow:'auto'}}>
            <div className='row'>
                <div className={theme => ({ top: '0px', [theme.breakpoints.up('md')]: { top: '0px', }})} 
                        style={{ position:'fixed',
                                right:'0',
                                left:'0', 
                                backgroundColor: '#172734', 
                                height: '86px', 
                                opacity:'0.9',
                                zIndex:'1'
                                }}>
                </div>
            </div>
            <div className='row justify-content-md-center'>
                <div className='col-sm-auto col-sm-offset-3'>
                    <div className={classes.margintop}>
                        <div style={{color: "#ffffff"}}><h3><strong>Notifications</strong></h3></div>
                        {(doneLoading === true) 
                        ?                      
                        <div className={classes.grouplist} style={{overflowY:'auto'}}>
                            <div className={classes.blogMainPost}>
                                <div className={classes.notifyPost}>
                                    {notifications.length > 0 
                                    ?   notifications.map((notificationItem, index)=>{
                                            return (
                                                <div key={index}>
                                                    <UrlProvider value={prefixurl}>
                                                        <NotifyInline 
                                                            notification={notificationItem} 
                                                            notificationState = {handleNotifyToggle}
                                                            donotnotifyState = {handleDonotNotify}  
                                                        />
                                                    </UrlProvider>
                                                </div>
                                                )
                                        })
                                    : 
                                        <div style={{backgroundColor:'#172734', padding: '3px 0 3px 10px',marginTop:'6px', marginBottom: '6px'}}>
                                            <p style={{color: 'white', marginBottom: '0px', lineHeight: '1.38105', letterSpacing: '.011em', fontSize: '0.9375rem !important'}}>
                                            You have no notifications
                                            </p>
                                        </div>
                                    }
                                </div>  
                            </div> 
                        </div>
                        : 
                            null 
                        }
                    </div>
                </div>
            </div>
        </div>
     )
}


export default NotifyList