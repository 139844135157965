import React, {useContext, useState, useEffect} from 'react';

// Third party plugins
import SlidingPane from "react-sliding-pane";
import 'react-sliding-pane/dist/react-sliding-pane.css'
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";


// local
import UrlContext from '../lookup/prefixurl'
import ProfileTabs from './profile/profile';
import {apiBlogUserLikes, apiUserFollowToggle} from '../lookup/lookup';
import classes from './assets/style.module.css';
import './assets/customSliding.css'
import {useLockBodyScroll} from './utilities'

 
export function UserLikes(props) {
    const {slug}                              = props
    const prefixurl                           = useContext(UrlContext)
    const [likesusers, setLikesusers]         = useState(null)
    const [doneLoading, setDoneLoading]       = useState(false)
    const [didEdit, setdidEdit]               = useState(false)
    const [disp, setDisp]                     = useState(false)
    const [followLoading, setFollowLoading]   = useState(false)
    const  [userProfile, setUserProfile]      = useState(null)
    const [userProfilePrivacyFlag, setUserProfilePrivacyFlag] = useState(false)
    const [userProfileBlockedFlag, setUserProfileBlockedFlag] = useState(false);
    const [sessionusr, setSessionUsr]         = useState(null);


    const [state, setState] = useState({
        isPaneOpen: false
    })

    useLockBodyScroll();

    const handleUserLikesLookup = (response, status) => {
        setSessionUsr(response.sessionUser)
        if (status === 200){
            setLikesusers(response)
            setDoneLoading(true)
        }
    }

    const handleFollowToggle = (event) => {
        if (!followLoading) {
            setFollowLoading(true)
            apiUserFollowToggle(prefixurl, event.username, event.followed ? "unfollow" : "follow", (response, status) => {
                if (status === 200) {
                    const editedLikesusers = likesusers
                    const index = editedLikesusers.likes.findIndex(like => like.username === event.username)
                    editedLikesusers.likes[index].followed = response.followed
                    setLikesusers(editedLikesusers)
                    setFollowLoading(false)
                    setDisp(!disp)
                }
            }  )
        }
    }

    const handleAvatar = (user, privacyFlag, blockedFlag)=>{
        setUserProfile(user.username)
        if (user.username === likesusers.sessionUser){
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            if (privacyFlag === true) {
                setUserProfilePrivacyFlag(true)
            }else {
                setUserProfilePrivacyFlag(false)
            }
            if (blockedFlag === true) {
                setUserProfileBlockedFlag(true)
            }else {
                setUserProfileBlockedFlag(false)
            }
        }
        setState({ isPaneOpen: true })
    }

    useEffect(() => {
        if (didEdit === false){
            setdidEdit(true)
            apiBlogUserLikes(prefixurl, slug, handleUserLikesLookup)
        }
    }, [prefixurl, props, slug, didEdit, setdidEdit])

    useEffect(()=>{
        if (disp === false){
            setDisp(!disp)
        }
       },[disp, setDisp])
    
    return (
        <div className='row' style={{backgroundColor:'#0d171f'}}>
            {(doneLoading === true) 
            ? 
            <div className='col-lg-6 col-lg-offset-3'>
                <div>
                    <div style={{display:'flex', flexDirection:'column', flexGrow:'1'}}>
                        {likesusers.likes.map((like, i) => (
 
                            <div key={i} className={classes.echdiv1}>
                                <div className={classes.echdiv2}>
                                    <div className={classes.echdiv3}>
                                        <div className={classes.avatar34} onClick={() => handleAvatar(like, like.private, like.blockedby)}>
                                            <img className={classes.avtrimg} src={like.avatar} alt=''></img>
                                        </div>
                                    </div>

                                    <div className={classes.echdiv4}>
                                        <div className={classes.echdiv5} onClick={() => handleAvatar(like, like.private, like.blockedby)} >
                                            <div>
                                                {like.private ?
                                                    <div className={classes.propiclock} >
                                                        <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                                                    </div> 
                                                : null}
                                                <Typography style={{ fontSize: '1.25em', color: '#ffffff' }}>
                                                { like.first_name 
                                                    ? like.first_name + ' ' + like.last_name
                                                    : like.username
                                                }
                                                </Typography>
                                            </div>
                                        </div>

                                        <div style={{paddingLeft:'10%'}}>
                                            { like.username !== likesusers.sessionUser 
                                            ?
                                                !like.private 
                                                    ? !like.blockedby
                                                        ? 
                                                            <button className="btn btn-sm btn-info" style={{width:'80px'}} onClick={() => handleFollowToggle(like)}>
                                                                { like.followed ? "UnFollow" : "Follow" }
                                                            </button>
                                                        : null
                                                : null
                                            : null
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='editedPostOverlay'
                    isOpen={state.isPaneOpen}
                    width='100%'
                    title=""
                    onRequestClose={() => setState({ isPaneOpen: false })}
                >
                    <ProfileTabs username={userProfile} sessionusr={sessionusr} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>

                </SlidingPane>
            </div> 
             : 
                null
            }
        </div>
    )
}

export default UserLikes

