import React, {useContext, useState, useEffect } from 'react';

// third party 'material-ui'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Typography from "@mui/material/Typography";

// local
import UrlContext from '../../lookup/prefixurl'
import {apiGroupDelete } from '../../lookup/lookup';
import {GroupFeed} from './groupfeed'
import classes from '../assets/style.module.css'

const THEME = createTheme({
    typography: {
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h5: {
        "fontWeight": 400,
      },
      h6: {
        "fontWeight": 100,
      },
    },
});

export function GroupMainListInline(props) {
    const {group}               = props;
    const {onGroupEdit}         = props;
    const {onLeft}              = props;
    const {onDelete}            = props;
    const {positionFix}         = props;
    const {mBScrollPos}         = props;
    const prefixurl             = useContext(UrlContext)
    const [didLike, setDidLike] = useState(false)
    const [stateDetail, setStateDetail] = useState({ isPaneOpenDetail: false })

    var navbarElement   = document.querySelector('.navbar-fixed-bottom');
    // console.log("GROUP:", group)
    const handleActionDeleteEvent = (response, status) => {
        if (status === 204) {
            onDelete(group.slug)
        }
    }

    const handleDelete = async (event) => {
       await apiGroupDelete(prefixurl, group.slug, handleActionDeleteEvent)
        setStateDetail({ isPaneOpenDetail: false })
    }

    const handleGroupLeft = async (event) => {
        onLeft(group.slug)
        setStateDetail({ isPaneOpenDetail: false })
     }
    
    const groupEdited = (updatedPost) => {
        onGroupEdit(updatedPost)
    }

    useEffect(()=>{
        if (didLike === false) { 
            setDidLike(true)
        }
    },[props, didLike, setDidLike])


    return (
      <div>
          {group !== undefined ?
            <article className={classes.bookmarkcontainer} >
                {group.get_icon_download_url ?
                    <div className={classes.bookmarkimg} onClick={() => {setStateDetail({isPaneOpenDetail: true}) }}>
                        <div>
                            <img 
                                placeholder={<div style={{width:'40px', height:'40px', backgroundColor:'#dedede'}}></div>} 
                                src={group.get_icon_download_url}
                                alt='group' 
                                width='40px'
                                style={{borderRadius:'4px'}}
                            />
                        </div>
                    </div>
                :  null}
                <div className={classes.bookmarkContent} onClick={() => {setStateDetail({isPaneOpenDetail: true}) }}>
                    <ThemeProvider theme={THEME}>
                        <Typography align='left' variant="h5" gutterBottom={true} style={{ fontSize: '1em', color: '#ffffff' }}>
                            {group.title}
                        </Typography>
                    </ThemeProvider>

                    <ThemeProvider theme={THEME}>
                        <Typography align='left' variant="h6" style={{ fontSize: '0.8em', color: '#ffffff' }}>
                            {group.total_members}
                            {group.total_members > 1 ? ' Members' : ' Member'}
                        </Typography>
                    </ThemeProvider>
                </div>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='groupFeedOverlay'
                    isOpen={stateDetail.isPaneOpenDetail}
                    width='100%'
                    title=""
                    from='right'
                    onRequestClose={() => 
                            {   setStateDetail({ isPaneOpenDetail: false }) 
                                positionFix(true)
                                if (navbarElement != null) {
                                    navbarElement.style.display = 'block';
                                }
                            }}
                >
                    <div>
                        <GroupFeed  
                            groupId={group.id}
                            groupSlug={group.slug}
                            didDelete={handleDelete} 
                            didGroupEdit={groupEdited}
                            didLeave={handleGroupLeft} 
                            mainBodyScrollPos = {mBScrollPos}
                        />
                    </div>
                </SlidingPane>
            </article> 
          : ""}
      </div>
    )
}