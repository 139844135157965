import styled from "styled-components";
import SnackbarBase from "@mui/material/Snackbar";
import SnackbarContentBase from "@mui/material/SnackbarContent";


export const Snackbar = styled(SnackbarBase)`
  /* >>> */
`;
export const SnackbarContent = styled(SnackbarContentBase)`
  /* >>> */
  && {
    background: #326690;
    padding: 0 0.5rem;
    text-align:center;
  }
`;

export const InfoWrapper = styled.span`
  display: flex;
  align-items: center;
`;
