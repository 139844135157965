
import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

//third party plugins
import SlidingPane from "react-sliding-pane";
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
// import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";

// local
import UrlContext from '../../lookup/prefixurl'
import { apiUserDoNotNotifyToggle } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import ProfileTabs from '../profile/profile';


export function ProfileFollowingInline(props) {
    const {following} =  props
    const {sessionUser} = props
    const prefixurl  = useContext(UrlContext)
    const [didEdit, setDidEdit] = useState(false)
    const [userProfilePrivacyFlag, setUserProfilePrivacyFlag] = useState(false)
    const [userProfileBlockedFlag, setUserProfileBlockedFlag] = useState(false);
    const [userProfile, setUserProfile] = useState(null)
    const [state, setState] = useState({isPaneOpen: false})

    
    useEffect(()=>{
        if (didEdit === false) { 
            setDidEdit(true)
        }
    },[props, didEdit, setDidEdit])

    const handleAvatar = (username, privacyFlag, blockedFlag)=>{
        setUserProfile(username)
        if (username === sessionUser) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(privacyFlag)
            setUserProfileBlockedFlag(blockedFlag)
        }
        setState({ isPaneOpen: true })
    }

    const handleDoNotNotifyToggle = (event) => {
        event.preventDefault()
        apiUserDoNotNotifyToggle(prefixurl, following.username, following.donotnotified ? "start" : "stop", (response, status) => {
            if (status === 200) {
                following.donotnotified = response.donotnotified
                setDidEdit(!didEdit)
            }
        })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  
                sx = {theme => ({
                                '&.MuiList-padding': {
                                    paddingBottom: '66px',
                                    [theme.breakpoints.up('md')]: {
                                    paddingBottom: '0px',
                                    },
                                }
                            })}
            >
                <ListItem key={'donotnotify'} onClick={handleDoNotNotifyToggle}>
                    <ListItemIcon>{<NotificationsPausedIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {following.donotnotified
                        ? `Turn on notifications from ${ following.first_name ? following.first_name : following.username }` 
                        : `Turn off notifications from ${ following.first_name ? following.first_name : following.username }`}
                    </Typography>
                </ListItem>
            </List>
        </div>
    );
    
    return (
        <div className={classes.echdiv2}>
            <div style={{width:'300px'}} >
                <div style={{float:'left', marginRight:'20px', marginTop:'6px', paddingBottom:'6px'}}>
                    <div className={classes.avatar34} onClick={() => handleAvatar(following.username, following.private, following.blockedby)}>
                        <img className={classes.avtrimg} src={following.avatar} alt=''></img>
                    </div>
                </div>

                <div style={{marginLeft:'60px', marginTop:'12px'}} onClick={() => handleAvatar(following.username, following.private, following.blockedby)} >
                    {following.private ?
                        <div className={classes.propiclock} >
                            <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                        </div> 
                    : null}
                    <span style={{color:'#ffffff', wordBreak:'break-word', fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                    <Typography style={{ fontSize: '1.25em', color: '#ffffff' }} gutterBottom>
                            { following.first_name 
                            ? following.first_name + ' ' + following.last_name
                            : following.username
                            }
                        </Typography>
                    </span>
                </div>

            </div>
            { !following.private ?
            <div className={classes.profilepostcmc}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={toggleDrawer('bottom', true)}
                    >
                    <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                </IconButton>
                <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                    {list('bottom')}
                </Drawer>                                        
            </div>
            : null }          
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='formCreateOverlay'
                isOpen={state.isPaneOpen}
                width='100%'
                title=""
                // from='bottom'
                onRequestClose={() => setState({ isPaneOpen: false })}
            >
                <ProfileTabs username={userProfile} sessionusr={sessionUser} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
            </SlidingPane>
        </div>
    )
}