import React, { useContext, useState, useEffect } from 'react';

// Third party plugins
import BookmarkIcon from '@mui/icons-material/Bookmark';

//local
import {apiBookmarkList} from '../lookup/lookup';
import classes from  './assets/style.module.css';
import {BookmarkInline} from './bookmarkinline';
import {useLockBodyScroll, verifyImage, verifyVideo, ImageLoading} from './utilities';
import UrlContext from '../lookup/prefixurl'


export function BookmarkList(props) {
    const {onEdit}          = props
    const {deletedBlogId}   = props
    const {bookmarkRemoved} = props
    const {mmla}            = props
    const {smlobj}          = props
    const prefixurl                             = useContext(UrlContext)
    const [blogs, setBlogs]                     = useState([])
    // const [bookmarksLoaded, setBookmarksLoaded] = useState(false)
    const [nextUrl, setNextUrl]                 = useState(null)
    const [blogsDidSet, setBlogsDidSet]         = useState(false)
    const [blogsDidDelete, setBlogsDidDelete]   = useState(false)
    const [imgLoadingUrl, setImgLoadingUrl]     = useState(null)
    const [scrollToPos, setScrollToPos]         = useState(0);

    

    let murl = null;
    let surl = null;
    let lMediaType = null
    let newIndex = null;
    
    useLockBodyScroll();
 
    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreBlogs()
        }
    }

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollToPos);
    }

    const handleDelete = (itemDeleted) => {
        const filteredBlogs = ([...blogs].filter(blog => blog.blogpost.id !== itemDeleted))
        setBlogs(filteredBlogs)
        setBlogsDidDelete(!blogsDidDelete)
        deletedBlogId(itemDeleted)
    }  

    const handleMediaProcess = async (response) => {
        const editedBlogs = ([...blogs])
        const handleDelayedMedialoadPushed = (loaded) => {
            if (loaded) {
                editedBlogs[newIndex].blogpost.get_sd_download_url = surl
                editedBlogs[newIndex].blogpost.get_media_download_url = murl
                editedBlogs[newIndex].blogpost.mediatype = lMediaType
            } 
        }
        if (lMediaType.slice(0,6) === "video/") {
            await verifyVideo(murl, handleDelayedMedialoadPushed)
        } else {
            await verifyImage(murl, handleDelayedMedialoadPushed)
        }
        await setBlogs(editedBlogs)
        //clear variables
        murl        = null;
        surl        = null;
        lMediaType  = null;
        newIndex    = null;
    }

    const handleBlogEdit = async (response) => {
        const editedBlogs = ([...blogs])
        newIndex = editedBlogs.findIndex(blog => blog.blogpost.slug === response.slug)
        if (newIndex >= 0) {
            const orig_media = editedBlogs[newIndex].blogpost.mediapath
            if (response.get_media_download_url) {
                if (orig_media !== response.mediapath) {  // if media file has changed
                    murl       = response.get_media_download_url
                    surl       = response.get_sd_download_url
                    lMediaType = response.mediatype
                    editedBlogs[newIndex].blogpost.content = response.content
                    editedBlogs[newIndex].blogpost.get_media_download_url = imgLoadingUrl
                    editedBlogs[newIndex].blogpost.get_sd_download_url = imgLoadingUrl
                    editedBlogs[newIndex].blogpost.mediatype = 'image/jpg'
                    setBlogs(editedBlogs);
                    await handleMediaProcess(response)
                } else {  // if media file has not changed
                    editedBlogs[newIndex].blogpost = response
                    setBlogs(editedBlogs)
                }
            } else { // if there is no media file
                editedBlogs[newIndex].blogpost = response
                setBlogs(editedBlogs)
            }
            onEdit(response)
        }
    }

    const handleBookmarkRemoved = (removedBookmark) => {
        const filteredBlogs = ([...blogs].filter(blog => blog.blogpost.slug !== removedBookmark))
        setBlogs(filteredBlogs)
        bookmarkRemoved(removedBookmark)
    }

    const handleImgLoadingUrl = (url) => {
        setImgLoadingUrl(url)
    }

    useEffect(() => {
        if (blogsDidSet === false) { 
            const handleBlogListLookup = (response, status) => {
                if (status === 200){
                    const newBlogs = response.results
                    setBlogs(newBlogs)
                    setNextUrl(response.next)
                    setBlogsDidSet(true)
                    ImageLoading(prefixurl, handleImgLoadingUrl)
                }
            }
            apiBookmarkList(prefixurl, handleBlogListLookup)
        }
      }, [props.username, prefixurl, blogs, blogsDidSet, setBlogsDidSet])
      
    const loadMoreBlogs = () =>{
        // event.preventDefault()
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newBlogs = [...blogs].concat(response.results)
                    setBlogs(newBlogs)
                } else {
                  console.log("There was an error")
                }
            }
            apiBookmarkList(prefixurl, handleLoadNextResponse, nextUrl)
        }
    }
    
    //cleanup
    useEffect(() => {
        return () => {
        //
        }
    })


    return (
        <div className='row'>
            <div className='col-sm-6 col-sm-offset-3' style={{backgroundColor: "#0d171f", marginBottom: "50px", overflowY:"auto"}}>
                <div className={classes.mnblknoscroll} onScroll={handleScroll}>
                    {
                    blogsDidSet
                    ?
                        blogs.length > 0 
                        ? blogs.map((blogItem, index)=>{
                                return (
                                    <div key={index}>
                                        <BookmarkInline 
                                            blog={blogItem} 
                                            onEdit={handleBlogEdit} 
                                            onDelete={handleDelete} 
                                            onRemoveBookmark={handleBookmarkRemoved} 
                                            mainMenuListArray={mmla} 
                                            submenuListObj={smlobj}
                                            positionFix = {handlePosFixed} 
                                            mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)}   }
                                        />
                                    </div>
                                    )
                            }) 
                        :   <article className={classes.bookmarkcontainer} >
                                <div className={classes.bookmarkicon} >
                                    <BookmarkIcon htmlColor="#4AA6DE" />
                                </div>
                                <div className={classes.bookmarkimg}></div>
                                <div className={classes.bookmarkContent}>
                                    <p className={classes.contentbody}>
                                        <span className={classes.truncateltc}>
                                            <span className={classes.bookmarklinecontent}>
                                                Bookmark Posts that you want to see again.
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </article>
                    : null
                    }
                    <div style={{'marginBottom':'50px'}}></div>
                </div>
            </div>
        </div>
     )
}


