
import React, { useState } from 'react';

//third party plugins
import Typography from "@mui/material/Typography";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// local
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import ProfileTabs from '../profile/profile';



export function SearchProfileMany(props) {
    const profiles                      = props
    const [state, setState]             = useState({isPaneOpen: false})
    const [sessionusr, setSessionUsr]   = useState(null)
    const [userProfile, setUserProfile] = useState(null)
    const [userProfilePrivacyFlag, 
             setUserProfilePrivacyFlag] = useState(false)
    const [userProfileBlockedFlag, 
             setUserProfileBlockedFlag] = useState(false);


    const handleAvatar = (username, sessionusr, privacyFlag, blockedFlag)=>{
        setUserProfile(username)
        setSessionUsr(sessionusr)
        setUserProfilePrivacyFlag(privacyFlag)
        setUserProfileBlockedFlag(blockedFlag)
        setState({ isPaneOpen: true })
    }

    return (
        <div>
            <div className={classes.bndhdr}>
                <Typography style={{ fontSize: '1em', color: '#ffffff' }}>
                    Profiles
                </Typography>
            </div>

            {
                profiles.profiles && profiles.profiles.map((profile, i) => (
                    <div key={i} className={classes.echdiv1}>
                        <div className={classes.echdiv2}>
                            <div className={classes.echdiv3}>
                                <div className={classes.avatar34} onClick={() => handleAvatar(profile.username, profile.sessionUser, profile.private, profile.blockedby)}>
                                    <img className={classes.avtrimg} src={profile.profilepic} alt=''></img>
                                </div>
                            </div>

                            <div className={classes.echdiv4}>
                                <div className={classes.echdiv5} onClick={() => handleAvatar(profile.username, profile.private, profile.blockedby)} >
                                    <div>
                                        {profile.private ?
                                            <div className={classes.propiclock} >
                                                <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                                            </div> 
                                        : null}
                                        <span style={{fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                                        <   Typography style={{ fontSize: '1.25em', color: '#ffffff' }} gutterBottom>
                                                { profile.first_name 
                                                ? profile.first_name + ' ' + profile.last_name
                                                : profile.username
                                                }
                                            </Typography>
                                        </span>
                                        { profile.username === profile.sessionUser
                                        ? 
                                        <Typography style={{ fontSize: '0.85em', color: '#ffffff' }}>
                                            You
                                        </Typography>
                                        : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
            }
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='formCreateOverlay'
                isOpen={state.isPaneOpen}
                width='100%'
                title=""
                onRequestClose={() => setState({ isPaneOpen: false })}
            >
                <ProfileTabs username={userProfile} sessionusr={sessionusr} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
            </SlidingPane>
            <div style={{'marginBottom':'50px'}}></div>
        </div>
    )
}