import React, {useContext, useState, useEffect } from 'react';
// Third party plugins
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SlidingPane from "react-sliding-pane";

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
// removed PullToRefresh as it conflicts with React-Photoswipe-Gallery
// import PullToRefresh from "react-simple-pull-to-refresh";

// local
import {BlogInline} from './bloginline';
import {apiBlogFeed, apiReportsTypeList} from '../lookup/lookup';
import {SearchForm} from './search/searchform';
import {BlogForm} from './blogform';
import {BookmarkList} from './bookmarklist';
import {NotificationList} from './notificationlist';
import {verifyImage, verifyVideo, ImageLoading} from './utilities';
import UrlContext from '../lookup/prefixurl'
import classes from './assets/style.module.css'


export function FeedList(props) {
    const {location}                                        = props
    const prefixurl                                         = useContext(UrlContext)
    const [blogs, setBlogs]                                 = useState([])
    const [blogsloaded, setBlogsLoaded]                     = useState(false)
    const [blogsListClass]                                  = useState('card')
    const [nextUrl, setNextUrl]                             = useState(null)
    const [blogsDidSet, setBlogsDidSet]                     = useState(false)
    const [disp, setDisp]                                   = useState(false)
    const [newNotificationCount, setNewNotificationCount]   = useState(null)
    const [imgLoadingUrl, setImgLoadingUrl]                 = useState(null);
    const [stateSearch, setStateSearch]                     = useState({isPaneSearch: false})
    const [stateForm, setStateForm]                         = useState({isPaneBlogCreate: false})
    const [stateBookmark, setStateBookmark]                 = useState({isPaneBookmark: false})
    const [stateNotification, setStateNotification]         = useState({isPaneNotification: false})
    const [ReportTypeItems, setReportTypeItems]             = useState(false)
    const [mainMenuList, setmainMenuList]                   = useState([])
    const [subMenu, setsubMenu]                             = useState({})
    // const [scrollToPos, setScrollToPos]                     = useState(0);
    const [sessionusr, setSessionUsr]                       = useState(null);
    const [yPos, setYPos]                                   = useState(null);

    let murl         = null;
    let surl         = null;
    let lMediaType   = null
    let newIndex     = null;


    const scrollToTop = () => window.scrollTo({
        top: 0
    })

    const handleNotificationDisplayed = (response) => {
        setNewNotificationCount(response)
    }

    const handleImgLoadingUrl = (url) => {
        setImgLoadingUrl(url)
    }

    const handleSetYPos = (yPos) => {
        window.scrollTo(0, yPos)
    }
    useEffect(()=>{
        if (disp === false){
            setDisp(!disp)
        }
    },[disp, setDisp])
    
    useEffect(() => {
        if (ReportTypeItems === false){
            setReportTypeItems(!ReportTypeItems)
            const handleReportTypesEvent = (response, status) => {
                if (status === 200) {
                    const obj = {}
                    response.forEach(el => obj[el.content] = el.reportSubtypes.map(e => e.content));
                    setsubMenu(obj)
                    setmainMenuList(Object.keys(obj))
                }
            }
            apiReportsTypeList(prefixurl, handleReportTypesEvent)
        }
    }, [ReportTypeItems, setReportTypeItems,mainMenuList, setmainMenuList, subMenu, prefixurl])



    const handleDelete = (itemDeleted) => {
        const editedBlogs = Object.assign([], blogs);
        newIndex = editedBlogs.findIndex(blog => blog.id === itemDeleted)
        editedBlogs.splice(newIndex, 1)
        setBlogs(editedBlogs)
    }

    const handleMediaProcess = async (response, blogIndex) => {
        const editedBlogs = ([...blogs])
        const handleDelayedMedialoadPushed = (loaded) => {
            if (loaded) {
                editedBlogs[blogIndex].get_sd_download_url = surl
                editedBlogs[blogIndex].get_media_download_url = murl
                editedBlogs[blogIndex].mediatype = lMediaType
            } 
        }
        if (lMediaType.slice(0,6) === "video/") {
            await verifyVideo(murl, handleDelayedMedialoadPushed)
        } else {
            await verifyImage(murl, handleDelayedMedialoadPushed)
        }
        await setBlogs(editedBlogs)
        //clear variables 
        murl        = null;
        surl        = null;
        lMediaType  = null;
        newIndex    = null;
    }
    
    const handleBlogEdit = async (response) => {
        const editedBlogs = ([...blogs])
        let blogIndex = null;

        blogIndex = editedBlogs.findIndex(blog => blog.slug === response.slug)
        if (blogIndex >= 0) {
            const orig_media = editedBlogs[blogIndex].mediapath

            if (response.get_media_download_url) {
                if (orig_media !== response.mediapath) {  // if media file has changed
                    murl       = response.get_media_download_url
                    surl       = response.get_sd_download_url
                    lMediaType = response.mediatype
                    editedBlogs[blogIndex].content = response.content
                    editedBlogs[blogIndex].get_media_download_url = imgLoadingUrl
                    editedBlogs[blogIndex].get_sd_download_url = imgLoadingUrl
                    editedBlogs[blogIndex].mediatype = 'image/gif'
                    setBlogs(editedBlogs);
                    setDisp(true)
                    await handleMediaProcess(response, blogIndex)
                } else {  // if media file has not changed
                    editedBlogs[blogIndex] = response
                    setBlogs(editedBlogs)
                    setDisp(true)
                }
            } else { // if there is no media file
                editedBlogs[blogIndex] = response
                setBlogs(editedBlogs)
                setDisp(true)
            }
        }
    }

    const handleBookmarkAdded = (added) => {
        if (added) {
            // setStatus("Bookmark added")
        } else {
            // setStatus("Bookmark removed")
        }
    }

    const handleDoNotNotify = (added) => {
        if (added) {
            // setStatus("Notifications turned off")
        } else {
            // setStatus("Notifications turned on")
        }
    }

    const handleBookmarkRemoved = async (response) => {
        const editedBlogs = ([...blogs])
        newIndex = editedBlogs.findIndex(blog => blog.slug === response)
        if (newIndex >= 0) {
            editedBlogs[newIndex].bookmarked = false
            setBlogs(editedBlogs);
        }
    }

    useEffect(() => {
        if (blogsDidSet === false) { 
            const handleBlogListLookup = (response, status) => {
                if (status === 200){
                    const newBlogs = response.results
                    setBlogs(newBlogs)
                    setSessionUsr(response.sessionUser)
                    setBlogsLoaded(true)
                    setNextUrl(response.next)
                    setNewNotificationCount(response.newNotificationCount)
                    // setAuthor(response.author)
                    setBlogsDidSet(true)
                }
            }
            apiBlogFeed(prefixurl, location, handleBlogListLookup)
            ImageLoading(prefixurl, handleImgLoadingUrl)
        }
    }, [props.username, prefixurl, location, blogs, blogsDidSet, setBlogsDidSet])

    const loadMoreBlogs = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newBlogs = [...blogs].concat(response.results)
                    setBlogs(newBlogs)
                } else {
                console.log("There was an error")
                }
            }
            apiBlogFeed(prefixurl, location, handleLoadNextResponse, nextUrl)
        }
    }
    
    const newBlog = async (response) => { // new post is added
        setStateForm({ isPaneBlogCreate: false })
        const editedBlogs = ([...blogs])
        editedBlogs.unshift(response)  // add the new post to the top of the list

        if (response.get_media_download_url) {
            murl       = response.get_media_download_url
            surl       = response.get_sd_download_url
            lMediaType = response.mediatype
            newIndex   = 0 // first in the array since this is a new post
            editedBlogs[newIndex].get_media_download_url = imgLoadingUrl
            editedBlogs[newIndex].get_sd_download_url = imgLoadingUrl
            editedBlogs[newIndex].mediatype = 'image/gif'
            setBlogs(editedBlogs)
            const handleDelayedNewMedialoadPushed = async (loaded) => {
                if (loaded) {
                    editedBlogs[newIndex].get_sd_download_url = surl
                    editedBlogs[newIndex].get_media_download_url = murl
                    editedBlogs[newIndex].mediatype = lMediaType
                    setBlogsDidSet(false)
                } 
            }

            setTimeout(async() => {
                if (lMediaType.slice(0,6) === "video/") {
                    await verifyVideo(murl, handleDelayedNewMedialoadPushed)
                } else {
                    await verifyImage(murl, handleDelayedNewMedialoadPushed)
                }
            }, 1000);
            scrollToTop()
        } else {
            setBlogs(editedBlogs)
            scrollToTop()
        }
    }

    window.onscroll = () => {
        setYPos(document.documentElement.scrollTop )
        if (
            document.documentElement.scrollHeight -
            document.documentElement.scrollTop ===
            document.documentElement.clientHeight
        ) {
            loadMoreBlogs()
        }
    }

    // useEffect(() => {
    //     function handleScrollEvent() {
    //       if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //          console.log("you're at the bottom of the page");
    //          // here add more items in the 'filteredData' state from the 'allData' state source.
    //       }
    //     }
    //     window.addEventListener('scroll', handleScrollEvent)
    //     return () => {
    //       window.removeEventListener('scroll', handleScrollEvent);
    //     }
    //   }, []) 


    return (
        <div className='container-fluid' style={{overflow:'hidden'}}>
            <div className='row'>
                <div 
                    // className={theme => ({ top: '0px', [theme.breakpoints.up('md')]: { top: '0px', }})} 
                    style={{ position:'fixed',
                            top:'0',
                            right:'0',
                            left:'0', 
                            backgroundColor: '#172734', 
                            height: '86px', 
                            opacity:'0.9',
                            zIndex:'1'
                            }}>
                        <div className={classes.blogMainPostHeader}>
                            <div className='blogCreate' style={{float:'right', position:'relative', paddingTop:'58px'}}>
                            <div style={{float:'left', width:'35px',height:'30px', marginRight:'25px'}} onClick ={() => setStateSearch({ isPaneSearch: true })}>
                                    <SearchIcon 
                                    htmlColor="#4AA6DE"
                                    fontSize='large'
                                    />
                                </div>
                                <div style={{float:'left', width:'35px',height:'30px', marginRight:'25px'}} onClick ={() => setStateForm({ isPaneBlogCreate: true })}>
                                    <AddCircleIcon 
                                    htmlColor="#4AA6DE"
                                    fontSize='large'
                                    />
                                </div>
                                <div style={{float:'left', width:'35px',height:'30px', marginRight:'25px'}} onClick ={() => setStateNotification({ isPaneNotification: true })}>
                                {newNotificationCount 
                                ?
                                <NotificationsActiveIcon 
                                    htmlColor="#ee8768"
                                    fontSize='large'
                                    />
                                    :
                                    <NotificationsNoneIcon 
                                    htmlColor="#4AA6DE"
                                    fontSize='large'
                                    />
                                }
                                
                                </div>

                                <div style={{float:'right', width:'60px',height:'30px'}} onClick ={() => setStateBookmark({ isPaneBookmark: true })}>
                                    <BookmarkIcon 
                                    htmlColor="#4AA6DE"
                                    fontSize='large'
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className='row'>
                <div className={location !== undefined ? classes.margintop84 : classes.margintop } style={{ marginLeft:'-14px', marginRight:'-14px'}}>
                    {!location ?
                        <Typography style={{ fontSize: '2em', color: '#ffffff', marginLeft:'20px' }} gutterBottom>
                            Feed
                        </Typography>
                        :
                        null
                    }
                    <div> 
                        { blogsloaded ? 
                                blogs.length > 0 ? 
                                    blogs.map((blogItem, index)=>{
                                        return (
                                            <div key={index}>
                                                <BlogInline 
                                                    blog={blogItem} 
                                                    key={`${index}-{blogItem.id}`}
                                                    sessionUser={props.username}
                                                    sessionusr={sessionusr} 
                                                    onDelete={handleDelete}
                                                    bookmarkState={handleBookmarkAdded}
                                                    notificationState = {handleDoNotNotify}
                                                    onEdit={handleBlogEdit}
                                                    mainMenuListArray = {mainMenuList}
                                                    subMenuObj = {subMenu}
                                                    elClass = {blogsListClass}
                                                    blogInlinegetYPos = {yPos}         // send scroll position of feed page
                                                    mBScrollPos = { handleSetYPos  }   // receive scroll position of feed page
                                                />
                                            </div>
                                        )
                                    }) 
                            :
                                <div className={classes.blogM}>
                                    <div style={{paddingLeft:'20px', paddingTop:'10px'}}>
                                        {location ?
                                            <div style={{paddingRight:'10px'}}>
                                                <Typography style={{ fontSize: '0.98em', color: '#ffffff' }} gutterBottom>
                                                    No one has posted on this location's wall. 
                                                </Typography>
                                                <Typography style={{ fontSize: '0.98em', color: '#ffffff' }} gutterBottom>
                                                    You can start creating posts with photos or videos that are relevant to this location. 
                                                </Typography>
                                                <br/>
                                            </div>
                                        :
                                            <div>
                                                <Typography style={{ fontSize: '0.98em', color: '#ffffff' }} gutterBottom>
                                                    Your personal feed is empty. 
                                                </Typography>
                                                <Typography style={{ fontSize: '0.98em', color: '#ffffff' }} gutterBottom>
                                                    You can add your own posts with photos or videos that can be 
                                                    viewed by people who are following you.
                                                </Typography>
                                                <br/>
                                                <Typography style={{ fontSize: '0.9em', color: '#ffffff' }} gutterBottom>
                                                    Set your profile as private if you do not want to be followed.
                                                </Typography>
                                                <br/>
                                            </div>
                                            }
                                        <div onClick ={() => setStateForm({ isPaneBlogCreate: true })}>
                                            <Button
                                                sx = {theme => ({
                                                    '&..MuiButton-root':{
                                                        color: theme.palette.getContrastText(blue[500]),
                                                        backgroundColor: blue[500],
                                                        '&:hover': {
                                                        backgroundColor: blue[700],
                                                        }
                                                    }
                                                })}
                                            size="small" variant="contained" color="primary" onClick ={() => setStateForm({ isPaneBlogCreate: true })} startIcon={<AddCircleIcon htmlColor='#ffffff'/>}>
                                                Add Post
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            : null
                            }
                    </div>
                </div>
                
            </div>
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='formCreateOverlay'
                isOpen={stateSearch.isPaneSearch}
                width='100%'
                title=""
                onRequestClose={() => setStateSearch({ isPaneSearch: false })}
            >
                <SearchForm sessionusr={sessionusr}/>
            </SlidingPane>
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='formCreateOverlay'
                isOpen={stateForm.isPaneBlogCreate}
                width='100%'
                title=""
                onRequestClose={() => setStateForm({ isPaneBlogCreate: false })}
            >
                <BlogForm didSubmit={newBlog} location={location}/>
            </SlidingPane>
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='postCreateOverlay'
                isOpen={stateNotification.isPaneNotification}
                width='100%'
                title=""
                onRequestClose={() => setStateNotification({ isPaneNotification: false })}
            >
                <NotificationList 
                    notificationDisplayed={handleNotificationDisplayed}
                    deletedBlogId={handleDelete}
                    onEdit={handleBlogEdit}
                    mainMenuListN = {mainMenuList} 
                    subMenuN = {subMenu}
                        />
            </SlidingPane>
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='postCreateOverlay'
                isOpen={stateBookmark.isPaneBookmark}
                width='100%'
                title=""
                onRequestClose={() => setStateBookmark({ isPaneBookmark: false })}
            >
                <BookmarkList 
                    username={blogs.sessionUser} 
                    deletedBlogId={handleDelete}
                    onEdit={handleBlogEdit} 
                    bookmarkRemoved={handleBookmarkRemoved}
                    mmla = {mainMenuList}
                    smlobj = {subMenu}
                    />
            </SlidingPane>
        </div> 
    )
}
