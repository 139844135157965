import React, {useState , useEffect} from 'react';
import '../../blogs/assets/searchpopup.css'

// Third party plugins
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';

// local
import { UrlProvider } from '../../lookup/prefixurl'
// import {VisualViewport}  from '../utilities';
import {useLockBodyScroll} from '../utilities';
import {apiSearchGroups} from '../../lookup/lookup';
import {GroupMainListInline} from './groupmainlistinline';
import classes from '../assets/style.module.css';



export function SearchPopup(props) {
    const {prefixurl}                       = props;
    const [scrollToPos, setScrollToPos]     = useState(0);
    const [doneLoading, setDoneLoading]     = useState(false);
    const [searchTerm, setSearchTerm]       = useState('')
    const [searchList, setSearchList]       = useState([])
    const [searchText, setSearchText]       = useState('')
    const [refreshGroup, setRefreshGroup]   = useState(false)
    
    useLockBodyScroll();
    let newIndex     = null;
    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    useEffect(()=> {
        if (refreshGroup === false) {
            setRefreshGroup(true)
        }
    },[refreshGroup, setRefreshGroup])

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollToPos);
    }
    

    const handleBlur = (event) => {
        event.stopPropagation()
        if (navbarElement != null) {
            navbarElement.style.display = 'block';
        }
    };

    const handleMouseDownSearch = (event) => {
        event.preventDefault();
    };

    const handleGroupEdit = async (response) => {
        const editedList = ([...searchList])
        newIndex = editedList.findIndex(group => group.slug === response.slug)
        if (newIndex >= 0) {
            editedList[newIndex].group = response
            setSearchList(editedList)
            setRefreshGroup(!refreshGroup)
        }
    }
 
    const handleDelete = (itemDeleted) => {
        const editedList = ([...searchList])
        newIndex = editedList.findIndex(group => group.slug === itemDeleted)
        editedList.splice(newIndex, 1)
        setSearchList(editedList)
        setRefreshGroup(!refreshGroup)
    }

    const handleGroupLeft = (itemLeft) => {
        const editedList = ([...searchList])
        newIndex = editedList.findIndex(group => group.slug === itemLeft)
        editedList.splice(newIndex, 1)
        setSearchList(editedList)
        setRefreshGroup(!refreshGroup)
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value)
        if (event.target.value.trim() !== '') {
            setSearchText(event.target.value.trim())
        }
    };

    const handleFormSubmit = (event) => {
        const handleSearchListLookup = (response, status) => {
            if (status === 200){
                setSearchList(response)
                setDoneLoading(true)
            }
        }
        event.preventDefault()
        apiSearchGroups(prefixurl, searchText,  handleSearchListLookup)
    }


    return (props.trigger) ? (
        // <VisualViewport>
        <div className='row'> 
            <div className='col-lg-6 col-lg-offset-3' style={{backgroundColor:'#0d171f'}}>
                <div style={{width:'100%'}}>
                    <div className= "popup">
                        <div className="popup-inner" style={{overflowY:"auto"}}>
                            <IconButton
                                aria-label="toggle password visibility"
                                style={{paddingTop:"40px", float:"right"}}
                                onClick={() =>  {
                                    setSearchTerm('')
                                    setDoneLoading(false)
                                    props.setTrigger();

                                }}
                                onMouseDown={handleMouseDownSearch}
                                edge="end"
                            >
                                <CloseIcon fontSize='large' disable='true' htmlColor='#9e9e9e' />
                            </IconButton>

                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop:'139px', paddingBottom:'20px'}}>
                                <form onSubmit={(event) => {handleFormSubmit(event)}}>
                                    <FormControl 
                                        sx={{ 
                                            m: 1, width: '15ch',
                                                '& .MuiOutlinedInput-root': {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#ffffff"
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#ffffff"
                                                    }
                                                }
                                            }} 
                                        style={{minWidth:'300px', backgroundColor:"#ffffff"}}
                                    >
                                        <OutlinedInput
                                            id="outlined-adornment-search"
                                            type={'text'}
                                            autoComplete="off"
                                            autoFocus
                                            value={searchTerm}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{fontSize:'1.3rem', color:'#182734'}}
                                            placeholder='Search Groups...'
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    type='submit'
                                                    onMouseDown={handleMouseDownSearch}
                                                    edge="end"
                                                >
                                                    {searchTerm.length > 0
                                                    ?
                                                    <SearchIcon fontSize='large' disable='true' htmlColor='#9e9e9e' />
                                                    : null
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </form>
                            </div>
                            {doneLoading
                            ? searchList.length > 0 
                                ? searchList.map((groupItem, index)=>{
                                    return (
                                        <div key={index} style={{marginTop:"20px"}}>
                                            <UrlProvider value={prefixurl}>
                                            <GroupMainListInline 
                                                group={groupItem} 
                                                onGroupEdit={handleGroupEdit}
                                                onLeft={handleGroupLeft} 
                                                onDelete={handleDelete} 
                                                positionFix = {handlePosFixed} 
                                                mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)} }
                                            />
                                            </UrlProvider>
                                        </div>
                                        )
                                    }) 
                                :    
                                <div>
                                    <article className={classes.bookmarkcontainer} >
                                        <div className={classes.bookmarkimg}>
                                            <div style={{width:"40px", height:"40px",
                                            borderRadius:'4px', backgroundColor:"#CCC"
                                            }}>
                                            </div>
                                        </div>
                                        <div className={classes.bookmarkContent} style={{marginTop:"15px"}}>
                                            <p className={classes.contentbody}> 
                                                <span className={classes.truncateltc}>
                                                    <span className={classes.bookmarklinecontent}>
                                                        No Groups found.
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </article> 
                                </div>
                            : null}
                            <div style={{'marginBottom':'50px'}}></div>
                        </div>

                    </div>
                </div>
            </div>
        </div> 
        // </VisualViewport>
        )
    : ""
}
