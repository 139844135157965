import React, {useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Drawer from '@mui/material/Drawer';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

// third party plugins
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// local
import UrlContext from '../lookup/prefixurl'
import {BlogDetail} from './detail'
import classes from './assets/style.module.css'
import { 
        apiNotificationReadUpdate, 
        apiNotificationRemove, 
        apiNotificationRemoveUndo,
        apiDonotNotifyPostToggle,
        apiUserDoNotNotifyToggle, 
        apiBlogDelete 
    } from '../lookup/lookup';

export function NotificationInline(props) {
    const {notification}        = props
    const {notificationState}   = props
    const {donotnotifyState}    = props
    const {onEdit}              = props
    const {onDelete}            = props
    const {mainMenuListArray}   = props
    const {subMenuObj}          = props
    const {positionFix}         = props
    const {mBScrollPos}         = props
    const prefixurl                     = useContext(UrlContext)
    const [disp, setDisp]               = useState(true)
    const [readFlag, setReadFlag]       = useState(false)
    const [stateDetail, setStateDetail] = useState({ isPaneOpenDetail: false })
    const [state, setState]             = useState({ bottom: false })
    const [snackPack, setSnackPack]     = useState([]);
    const [open, setOpen]               = useState(true);
    const [messageInfo, setMessageInfo] = useState(undefined);
    const [status, setStatusBase]       = useState("");

    const setStatus = msg => setStatusBase({ msg, date: new Date() });
    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    useEffect(()=>{
        //
    },[disp, setDisp])


    const handleBookmarkFromDetail = () => {}
    const handleNotificationfromDetail = () => {}


    useEffect(()=>{
        setReadFlag(notification.read)
    },[props, notification.read])

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpen(false);
        }
    }, [snackPack, messageInfo, open]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleActionDeleteEvent = (response, status) => {
        if (status === 204) {
            onDelete(notification.target_content_object.content_object.id)
        }
    }

    const handleDelete = async (event) => {
        await apiBlogDelete(prefixurl, notification.target_content_object.content_object.slug, handleActionDeleteEvent)
        setStateDetail({ isPaneOpenDetail: false })
        // all related notifications from the deleted blog should have been hard deleted
        // with django signals. So, simply hide this notification.
        setDisp(false)
    }

    const postEdited = (updatedPost) => {
        // if (updatedPost.comments !== undefined) {
        //     updatedPost.comments_total = updatedPost.comments.length
        // }
        onEdit(updatedPost)
    }
    
    const handleRemove = (response, status) => {
        if (status !== 200){
            console.log("could not remove notification")
        }
    }
    const handleRemoveUndo = (response, status) => {
        if (status !== 200) {
            console.log("could not undo notification removed.")
        }
    }

    const handleNotificationRemove = (event)=> {
        event.preventDefault()
        setStatus("Done Reading")
        const message ='Notification Removed'
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
        apiNotificationRemove(prefixurl, notification.id, handleRemove)
        setDisp(false)
    }
    
    const handleActionNotificationEvent = (response, status) => {
        if (status === 200) {
            notification.donotnotifyfrompost = response.donotnotifyfrompost
            notificationState(response.donotnotifyfrompost, notification.obj_text)
        }
    }

    const handleNotificationToggle = (event) => {
        apiDonotNotifyPostToggle(prefixurl, notification.obj_text, handleActionNotificationEvent)
    }

    const handleDoNotNotifyToggle = (event) =>{
        event.preventDefault()
        apiUserDoNotNotifyToggle(prefixurl, 
                                    notification.actor_content_object.username, 
                                    notification.actor_content_object.donotnotified ? "start" : "stop", (response, status) => {
                                        if (status === 200) {
                                            notification.actor_content_object.donotnotified = response.donotnotified
                                            donotnotifyState(response.donotnotified, notification.actor_content_object.username)
                                        }
                                    }
                                )
    }


    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {
            [{width: 'auto'}]: anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List 
             sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}
            >
                <ListItem button key={'bookmark'} onClick={handleNotificationRemove}>
                    <ListItemIcon>{<NotificationsOffIcon fontSize='large' /> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>Remove Notification</Typography>
                </ListItem>
                <ListItem button key={'donotnotify'} onClick={handleNotificationToggle}>
                    <ListItemIcon>{<NotificationsPausedIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {notification.donotnotifyfrompost 
                        ? 'Turn on notifications for this post' 
                        : 'Turn off notifications for this post'}
                    </Typography>
                </ListItem>
                <ListItem button key={'donotnotified'} onClick={handleDoNotNotifyToggle}>
                    <ListItemIcon>{<NotificationsPausedIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {notification.actor_content_object.donotnotified
                        ? `Turn on notifications from 
                            ${ notification.actor_content_object.first_name ? notification.actor_content_object.first_name : notification.actor_content_object.username }` 
                        : `Turn off notifications from 
                            ${ notification.actor_content_object.first_name ? notification.actor_content_object.first_name : notification.actor_content_object.username }`
                        }
                    </Typography>
                </ListItem>
            </List>
        </div>
    );
    
    const handleUndo =()=> {
        apiNotificationRemoveUndo(prefixurl, notification.id, handleRemoveUndo)
        setDisp(true)
        handleClose()
    }
    
    const handleSetReadFlag =(response, status)=> {
        if (status === 200) {
            setReadFlag(true)
        }
    }

    const handleReadFlag = () => {
        if (readFlag === false){
            apiNotificationReadUpdate(prefixurl, notification.id, handleSetReadFlag)
        }
    }
    
    // const handleClick = (message) => () => {
    //     setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    // };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
    const handleExited = () => {
        setMessageInfo(undefined);
    };

    return (
      <div>
          {notification !== undefined ?
            disp ?
                <div className={classes.notifycontainer} style={{border:'none', backgroundColor:!readFlag && '#2d516f'}} >
                    <div style={{display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center', position:'relative'}}
                    onClick={() => {setStateDetail({isPaneOpenDetail: true}) }}>
                        <div style={{marginRight:'12px',alignSelf:'flex-start', position:'relative', display:'flex', flexDirection:'column', marginBottom:'8px', marginTop:'8px'}}>
                            <div className={classes.avatar34}>
                                <img className={classes.avtrimg} src={notification.actor_content_object.avatar} alt=''></img>
                            </div>
                        </div>
                        <div style={{color:'#ffffff', display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center'}}>
                            <div style={{flexBasis:'0px', paddingTop:'12px', position:'relative', display:'flex', justifyContent:'space-between', 
                                            alignItems:'stretch', flexShrink:'1', paddingBottom:'12px', flexDirection:'column', flexGrow:'1'}}>
                                <div>
                                    <span style={{wordBreak:'break-word', fontSize:'1.25rem', textAlign:'left', fontWeight:'500'}}>
                                    <strong>
                                        { notification.actor_content_object.first_name 
                                        ? notification.actor_content_object.first_name + ' ' + notification.actor_content_object.last_name
                                        : notification.actor_content_object.username
                                        }
                                    </strong>
                                    {' '}{notification.verb}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>      
                    <div className={classes.bookmarkmenu}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={toggleDrawer('bottom', true)}
                            >
                            <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                        </IconButton>
                        <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                            {list('bottom')}
                        </Drawer>
                    </div>
                    <SlidingPane
                        // className='bookmark'
                        overlayClassName='notificationOverlay'
                        isOpen={stateDetail.isPaneOpenDetail}
                        width='100%'
                        title=""
                        from='right'
                        onRequestClose={() => 
                                            {   setStateDetail({ isPaneOpenDetail: false }) 
                                                positionFix(true)
                                                if (navbarElement != null) {
                                                    navbarElement.style.display = 'block';
                                                }
                                            }
                                        }
                    >
                        <div>
                            <BlogDetail 
                                slug={notification.target_content_object.content_object.slug} 
                                didDelete={handleDelete} 
                                didPostEdit={postEdited} 
                                bookmarkfromDetail={handleBookmarkFromDetail}
                                NotificationfromDetail={handleNotificationfromDetail}  
                                readFlag={handleReadFlag}
                                mainMenuList={mainMenuListArray} 
                                submenuList={subMenuObj}
                                mainBodyScrollPos = {mBScrollPos}
                                />
                        </div>
                    </SlidingPane>
                </div> : null
          : ""}
        {
            status 
            ? 
            <Snackbar
                key={messageInfo ? messageInfo.key : undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                onExited={handleExited}
                message={messageInfo ? messageInfo.message : undefined}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleUndo}>
                        Undo
                        </Button>
                    </React.Fragment>
                    }
                className={classes.notificationalert}
            />
            : null
        }  
      </div>
    )
}
