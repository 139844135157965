import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

//third party plugins
import SlidingPane from "react-sliding-pane";
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";


// local
import UrlContext from '../../lookup/prefixurl'
import { apiGroupUserAdminToggle, apiGroupRemoveUser } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import ProfileTabs from '../profile/profile';


export function GroupAdminsInline(props) {
    const {groupslug}                   = props;
    const {sessionuser}                 = props;
    const {sessionUserIsAdmin}          = props;
    const {userRemoved}                 = props;
    const {member}                      = props;
    const [isAdmin, setIsAdmin]         = useState(props.isAdmin);
    const prefixurl                     = useContext(UrlContext);
    const [didEdit, setDidEdit]         = useState(false);
    const [userProfilePrivacyFlag, 
            setUserProfilePrivacyFlag]  = useState(false);
    const [userProfileBlockedFlag, 
            setUserProfileBlockedFlag]  = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [state, setState]             = useState({isPaneOpen: false});
    
    useEffect(()=>{
        if (didEdit === false) { 
            setDidEdit(true)
        }
    },[props, didEdit, setDidEdit])

    const handleAvatar = (username, privacyFlag, blockedFlag)=>{
        setUserProfile(username)
        if (username === sessionuser) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(privacyFlag)
            setUserProfileBlockedFlag(blockedFlag)
        }
        setState({ isPaneOpen: true })
    }

    const handleUserAdminToggle = (event) => {
        event.preventDefault()
        apiGroupUserAdminToggle(prefixurl, groupslug, member.member.username, (response, status) => {
            if (status === 200) {
                setIsAdmin(response.admin)
                setDidEdit(!didEdit)
            }
        })
    }

    const handleRemoveUser = (event) => {
        event.preventDefault()
        apiGroupRemoveUser(prefixurl, groupslug, member.member.username, (response, status) => {
            if (status === 204) {
                userRemoved(member.member.username)
            }
        })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx( {width: 250}, {[{width: 'auto'}]: anchor === 'bottom',})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                {
                sessionUserIsAdmin
                ?
                    <ListItem button key={'admintoggle'} onClick={handleUserAdminToggle}>
                        <ListItemIcon>{<SupervisorAccountIcon fontSize='large'/> }</ListItemIcon>
                        <Typography style={{ fontSize: '1em', color: '#000000' }}>
                            {isAdmin
                            ? 'Dismiss as Admin' 
                            : 'Make group Admin'}
                        </Typography>
                    </ListItem>
                : null
                }
                {
                sessionUserIsAdmin
                ?
                <ListItem button key={'removeuser'} onClick={handleRemoveUser}>
                    <ListItemIcon>{<PersonIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {sessionuser === member.member.username ? 'Leave group' : 'Remove from group'}
                    </Typography>
                </ListItem>
                : null
                }
            </List>
        </div>
    );
    
    return (
        <div className={classes.echdiv2}>
            <div style={{width:'300px'}} >
                <div style={{float:'left', marginRight:'20px', marginTop:'6px', paddingBottom:'6px'}}>
                    <div className={classes.avatar34} onClick={() => handleAvatar(member.member.username, member.member.private, member.member.blockedby)}>
                        <img className={classes.avtrimg} src={member.member.avatar} alt=''></img>
                    </div>
                </div>
                <div style={{marginLeft:'60px', marginTop:'12px'}} onClick={() => handleAvatar(member.member.username, member.member.private, member.member.blockedby)} >
                    {member.member.private ?
                        <div className={classes.propiclock} >
                            <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                        </div> 
                    : null}
                    <span style={{color:'#ffffff', wordBreak:'break-word', fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                        <Typography style={{ fontSize: '1.25em', color: '#ffffff' }} gutterBottom>
                            { member.member.ui_displayname} <span style={{fontSize:"0.75em", color:"#FF4433"}}>&nbsp;&nbsp;&nbsp; {isAdmin ? ' ADMIN': null}</span>  
                        </Typography>
                    </span>
                </div>
            </div>
            { !member.creator ?
            <div className={classes.profilepostcmc}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={toggleDrawer('bottom', true)}
                    >
                    <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                </IconButton>
                <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                    {list('bottom')}
                </Drawer>                                        
            </div>
            : null }          
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='ProfileTabsOverlay'
                isOpen={state.isPaneOpen}
                width='100%'
                title=""
                // from='bottom'
                onRequestClose={() => setState({ isPaneOpen: false })}
            >
                <ProfileTabs username={userProfile} sessionusr={sessionuser} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
            </SlidingPane>
        </div>
    )
}