
import React, { useContext, useState, useEffect } from 'react';

// Third party plugins
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// local
import UrlContext from '../../lookup/prefixurl'
import { apiGroupMembers } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import {GroupMembersInline} from './groupmembersinline';



export function GroupMembers(props) {
    const {slug}                        = props;
    const prefixurl                     = useContext(UrlContext)
    const [sessionUser, setSessionUser] = useState(null)
    const [sessionUserIsAdmin, 
            setSessionUserIsAdmin]      = useState(false)
    const [nextUrl, setNextUrl]         = useState(null)
    const [members, setMembers]         = useState(null)
    const [doneLoading, setDoneLoading] = useState(false)
    const [openUserRemoved, 
            setOpenUserRemoved]         = useState(false);

    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreGroups()
        }
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenUserRemoved(false);
    };

    const actionSnack = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    );

    const handleGroupMembers = (response, status) => {
        if (status === 200){
            setMembers(response.results)
            setSessionUser(response.sessionUser)
            setSessionUserIsAdmin(response.sessionUserIsAdmin)
            setNextUrl(response.next)
            setDoneLoading(true)
        }
    }

    const handleUserRemoved = (removeduser) => {
        let newIndex = null;
        const editedMembers = members
        newIndex = editedMembers.findIndex(member => member.username === removeduser)
        editedMembers.splice(newIndex, 1)
        setMembers(editedMembers)
        setOpenUserRemoved(true)
    }

    useEffect(() => {
        apiGroupMembers(prefixurl, slug, handleGroupMembers)
    }, [prefixurl, slug, props])

    const loadMoreGroups = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newMembers = [...members].concat(response.results)
                    setMembers(newMembers)
                } else {
                  console.log("There was an error")
                }
            }
            apiGroupMembers(prefixurl, slug, handleLoadNextResponse, nextUrl)
        }
    }

    return (
        <div onScroll={handleScroll}>
            {(doneLoading === true) 
            ? 
            <div>
                <div>
                    {members && members.map((mbrItem, i) => {
                        return (
                            <div key={i} className={classes.echdiv1}>
                                <GroupMembersInline 
                                member={mbrItem}
                                groupslug={slug} 
                                sessionuser={sessionUser}
                                sessionUserIsAdmin = {sessionUserIsAdmin}
                                isAdmin={mbrItem.admin}
                                userRemoved = {handleUserRemoved}
                                />
                            </div>
                            )
                    })}
                </div>
                <Snackbar
                    open={openUserRemoved}
                    autoHideDuration={3000}
                    onClose={handleCloseSnack}
                    message="Member removed from Group."
                    action={actionSnack}
                    sx = {theme => ({
                        '&.MuiSnackbar-root': {
                            [theme.breakpoints.down('md')]: {
                                bottom: 100,
                            }
                        }
                    })}
                />
            </div> 
            : 
            null
            }
        </div>
    )
}