
import {backendLookup} from '.'

// get Blog feed
export function apiBlogFeed(prefixurl, location, callback, nextUrl) {
  let endpoint = "/post/feed/"
  if (location) {
    endpoint = `/post/feed/?location=${location}`
  }
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET",prefixurl, endpoint, callback)   
}

// Create Blogpost
export function apiBlogCreate(prefixurl, newBlog, locationId, callback) {
  const endpointUrl ="/post/create/"
  backendLookup("POST",prefixurl, endpointUrl, callback, {content: newBlog, lecture:locationId})   
}


// Edit Blogpost
export function apiBlogUpdate(prefixurl, editedVal, blogSlug, callback) {
    const endpointUrl = `/post/${blogSlug}/edit/`
    backendLookup("PUT",prefixurl, endpointUrl, callback, {slug:blogSlug, content: editedVal})   
  }

// Edit Comment or Reply
export function apiCommentReplyEdit(prefixurl, editedVal, pk, callback) {
    const endpointUrl = `/comments/${pk}/`
    backendLookup("PUT",prefixurl, endpointUrl, callback, {content: editedVal})
  }


//   Delete Blogpost
export function apiBlogDelete(prefixurl, blogSlug, callback) {
    const endpointUrl = `/post/${blogSlug}/delete/`
    backendLookup("DELETE",prefixurl, endpointUrl, callback)   
  }

// Create Comment
export function apiCommentCreate(prefixurl, data, blogSlug, callback) {
    const endpointUrl = `/comments/create/?type=blogpost&slug=${blogSlug}`
    let newComment = data.content
    let newMedianame = data.medianame
    let newMediapath = data.mediapath
    let newMediatype = data.mediatype
    backendLookup("POST", prefixurl, endpointUrl, callback, {content: newComment, medianame:newMedianame, mediapath:newMediapath, mediatype:newMediatype})   
  }
 
// Delete Comment or Reply
export function apiCommentReplyDelete(prefixurl, pk, callback) {
    const endpointUrl = `/comments/${pk}`
    backendLookup("DELETE", prefixurl, endpointUrl, callback)   
  }
    
// Create Reply
export function apiReplyCreate(prefixurl, newReply, blogSlug, parentId, callback) {
    const endpointUrl = `/comments/create/?type=blogpost&slug=${blogSlug}&parent_id=${parentId}`
    backendLookup("POST", prefixurl, endpointUrl, callback, {content: newReply})   
  }

// blog like toggle
export function apiBlogAction(prefixurl, blogSlug, action, callback) {
    const endpointURL = `/post/${blogSlug}/like/`
    backendLookup("PUT", prefixurl, endpointURL, callback )   
}

// set bookmark for a blog
export function apiBookmarkPost(prefixurl, blogSlug, callback) {
  const endpointURL = `/post/${blogSlug}/bookmark/`
  backendLookup("PUT", prefixurl, endpointURL, callback )   
}

//get all bookmarks
export function apiBookmarkList(prefixurl, callback, nextUrl) {
  let endpoint = "/post/bookmarked/"
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET", prefixurl, endpoint, callback)   
}

export function apiDonotNotifylectureToggle(prefixurl, lectureSlug, callback) {
  const endpointURL = `/courses/${lectureSlug}/lecturedonotnotify/`
  backendLookup("PUT", prefixurl, endpointURL, callback )   
}

export function apiDonotNotifyPostToggle(prefixurl, blogSlug, callback) {
  const endpointURL = `/post/${blogSlug}/donotnotify/`
  backendLookup("PUT", prefixurl, endpointURL, callback )   
}

export function apiUserFollowToggle(prefixurl, username, action, callback) {
  const data = {action: `${action && action}`.toLowerCase()}
  backendLookup("POST", prefixurl, `/userprofile/${username}/follow/`, callback, data)   
}

export function apiUserDoNotNotifyToggle(prefixurl, username, action, callback) {
  const data = {action: `${action && action}`.toLowerCase()}
  backendLookup("POST", prefixurl, `/userprofile/${username}/donotnotifytoggle/`, callback, data)   
}

// Comment/Reply like toggle
export function apiCommentOrReplyLike(prefixurl, pk, callback) {
    const endpointURL = `/comments/${pk}/like/`
    backendLookup("PUT", prefixurl, endpointURL, callback )   
}

// Get Blog Detail
export function apiBlogDetail(prefixurl, blogSlug, callback, prevPage) {
    let endpoint = `/post/${blogSlug}`
    if (prevPage !== null && prevPage !== undefined) {
      endpoint = `/post/${blogSlug}/?commentpage=${prevPage}`
    }
    backendLookup("GET", prefixurl, endpoint, callback)   
}

export function apiBlogUserLikes(prefixurl, blogSlug, callback) {
  backendLookup("GET", prefixurl, `/post/${blogSlug}/likes/`, callback)   
}

export function apiBlogUserCommentReplyLikes(prefixurl, CommentOrReply_id, callback) {
  backendLookup("GET", prefixurl, `/comments/${CommentOrReply_id}/likes/`, callback)   
}

export function apiUserProfileMain(prefixurl, username, callback) {
  backendLookup("POST", prefixurl, `/userprofile/${username}/profilemain/`, callback)   
}

export function apiProfileFeed(prefixurl, username, callback, nextUrl) {
  let endpoint = `/post/profilefeed/?username=${username}`
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET",prefixurl, endpoint, callback)   
}

export function apiUserProfileMedia(prefixurl, username, callback) {
  backendLookup("GET", prefixurl, `/userprofile/${username}/profilemedia/`, callback)   
}

//=====  this will allow pagination of media, but pagination of media may not be user friendly,
//===== so removed it.

// export function apiUserProfileMedia(prefixurl, username, callback, nextUrl) {
//   let endpoint = `/userprofile/profilemedia/?username=${username}`
//   if (nextUrl !== null && nextUrl !== undefined) {
//     endpoint = nextUrl.replace(prefixurl,"")
//   }
//   backendLookup("GET", prefixurl, endpoint, callback)   
// }


export function apiUserProfileFollowing(prefixurl, username, callback) {
  backendLookup("GET", prefixurl, `/userprofile/${username}/profilefollowing/`, callback)   
}

export function apiUserProfileFollowedBy(prefixurl, username, callback) {
  backendLookup("GET", prefixurl, `/userprofile/${username}/profilefollowedby/`, callback)   
}

export function apiUserLocationsFollowed(prefixurl, username, callback) {
  backendLookup("GET", prefixurl, `/courses/${username}/lecturesfollowed/`, callback)   
}

// get all users that are following a location
export function apiLocationFollowersList(prefixurl, locationslug, callback) {
  backendLookup("GET", prefixurl, `/courses/${locationslug}/lecturefollowedby/`, callback)   
}

export function apiNotificationList(prefixurl, callback, nextUrl) {
  let endpoint = "/notify/notificationlist/"
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET", prefixurl, endpoint, callback)   
}

export function apiNotificationDisplayedUpdate(prefixurl, callback) {
  let endpoint = "/notify/notificationlist/setdisplayed/"
  backendLookup("POST", prefixurl, endpoint, callback)   
}
export function apiNotificationReadUpdate(prefixurl, notification_id, callback) {
  let endpoint = `/notify/${notification_id}/setreadflag/`
  backendLookup("POST", prefixurl, endpoint, callback)   
}

export function apiNotificationRemove(prefixurl, notification_id, callback){
  let endpoint = `/notify/${notification_id}/setdelete/`
  backendLookup("POST", prefixurl, endpoint, callback)   
}

export function apiNotificationRemoveUndo(prefixurl, notification_id, callback){
  let endpoint = `/notify/${notification_id}/setdeleteundo/`
  backendLookup("POST", prefixurl, endpoint, callback)   
}


// Notify module related
export function apiNotifyList(prefixurl, callback, nextUrl) {
    // let endpoint = `/notify/ooimanotify/${username}/list/`
    let endpoint = "/notify/ooimanotify/list/"
    if (nextUrl !== null && nextUrl !== undefined) {
        endpoint = nextUrl.replace(prefixurl,"")
    }
    backendLookup("GET",prefixurl, endpoint, callback)   
  }
  
export function apiNotifyDisplayedUpdate(prefixurl, callback) {
let endpoint = "/notify/ooimanotify/setdisplayed/"
backendLookup("POST", prefixurl, endpoint, callback)   
}

export function apiNotifyReadUpdate(prefixurl, notification_id, callback) {
let endpoint = `/notify/${notification_id}/setreadflag/`
backendLookup("POST", prefixurl, endpoint, callback)   
}

export function apiNotifyRemove(prefixurl, notification_id, callback){
let endpoint = `/notify/${notification_id}/setdelete/`
backendLookup("POST", prefixurl, endpoint, callback)   
}

export function apiNotifyRemoveUndo(prefixurl, notification_id, callback){
let endpoint = `/notify/${notification_id}/setdeleteundo/`
backendLookup("POST", prefixurl, endpoint, callback)   
}

export function apiUserMediaDelete(prefixurl, key, callback) {
  let endpoint = '/post/usrimgdelete/'
  const data = {key: key}
  backendLookup("DELETE", prefixurl, endpoint, callback, data)   
}


export function apiImageLoading(prefixurl, callback) {
  const endpointUrl = '/cfg/loading_image/'
  backendLookup("GET",prefixurl, endpointUrl, callback)   
}

export function apiPosterImageLoading(prefixurl, callback) {
  const endpointUrl = '/cfg/posterloading_image/'
  backendLookup("GET",prefixurl, endpointUrl, callback)   
}


export function apiReportsTypeList(prefixurl, callback) {
  backendLookup("GET", prefixurl, `/reportuserposts/`, callback)   
}


export function apiUserReportsType(prefixurl, blogSlug, commentReplyId, reportTypeText, callback) {
  const endpointUrl =`/reportuserposts/create/?slug=${blogSlug}&commentReplyId=${commentReplyId}&reporttypetext=${reportTypeText}`
  backendLookup("POST",prefixurl, endpointUrl, callback)   
}

export function apiUserReportGroup(prefixurl, groupSlug, reportTypeText, callback) {
  const endpointUrl =`/reportuserposts/createforgroup/?slug=${groupSlug}&reporttypetext=${reportTypeText}`
  backendLookup("POST",prefixurl, endpointUrl, callback)   
}

export function apiBlockUserToggle(prefixurl, username, action, callback) {
  const data = {action: `${action && action}`.toLowerCase()}
  backendLookup("POST", prefixurl, `/userprofile/${username}/block/`, callback, data)   
}


export function apiUserProfileBlocked(prefixurl, username, callback) {
  backendLookup("GET", prefixurl, `/userprofile/${username}/profileblocked/`, callback)   
}

export function apiSearchList(prefixurl, searchText, callback) {
  const endpointUrl =`/search/?q=${searchText}`
  backendLookup("GET",prefixurl, endpointUrl, callback)   
}

export function apiGetProfileByName(prefixurl, firstName, lastName, callback) {
  const endpointUrl =`/userprofile/getprofilebyname/?firstname=${firstName}&lastname=${lastName}`
  backendLookup("GET",prefixurl, endpointUrl, callback)   
}

export function apiNameAdd(prefixurl, firstname, lastname, callback) {
  let endpoint = `/userprofile/nameadd/`
  const data = {firstname:firstname, lastname:lastname}
  backendLookup("POST",prefixurl, endpoint, callback, data)   
}


// create group
export function apiGroupCreate(prefixurl, name, description, privacy, callback) {
  const endpointUrl ="/groups/create/"
  var privacy_flg = 'O'
  if (privacy === 'P') {
    privacy_flg = true
  } else {
    privacy_flg = false
  }
  backendLookup("POST",prefixurl, endpointUrl, callback, {title: name, description: description, private: privacy_flg})   
}
//  Get List of all Groups for a user ( no pagination )
export function apiGroupMainListMyItems(prefixurl, callback, nextUrl) {
  let endpointUrl = '/groups/mainlistmygroups/'
  if (nextUrl !== null && nextUrl !== undefined) {
    endpointUrl = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET", prefixurl, endpointUrl, callback)   
}

//  Get List of all Groups
export function apiGroupMainList(prefixurl, callback, nextUrl) {
  let endpointUrl = '/groups/groupmainlist/'
  if (nextUrl !== null && nextUrl !== undefined) {
    endpointUrl = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET", prefixurl, endpointUrl, callback)   
}

export function apiAddNewGroup(prefixurl, groupSlug, callback) {
  let endpointUrl = `/groups/mainlistnewgroup/?slug=${groupSlug}`
  backendLookup("GET", prefixurl, endpointUrl, callback)   
}

// Single Group with Feed.
export function apiGroupMain(prefixurl, groupSlug, callback) {
  let endpointUrl = `/groups/${groupSlug}`
  backendLookup("GET", prefixurl, endpointUrl, callback)   
}

// Get Group List ( max 3 groups) to display on Profile Feed
export function apiProfileGroupList(prefixurl, username, callback, nextUrl) {
  let endpointUrl = `/groups/profilegrouplist/?username=${username}`
  if (nextUrl !== null && nextUrl !== undefined) {
    endpointUrl = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET", prefixurl, endpointUrl, callback)   
}
 
//  Get List of all Groups for a user
export function apiProfileGroupListAll(prefixurl, username, callback, nextUrl) {
  let endpointUrl = `/groups/profilegrouplistfull/?username=${username}`
  if (nextUrl !== null && nextUrl !== undefined) {
    endpointUrl = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET", prefixurl, endpointUrl, callback)   
}

// Edit Group
export function apiGroupUpdate(prefixurl, editedTitle, editedDescription, groupSlug, mediapath, callback) {
  const endpointUrl = `/groups/${groupSlug}/edit/`
  backendLookup("PUT",prefixurl, endpointUrl, callback, {slug:groupSlug, title:editedTitle, description:editedDescription})   
}

// Delete Group
export function apiGroupDelete(prefixurl, groupSlug, callback) {
  const endpointUrl = `/groups/${groupSlug}/delete/`
  backendLookup("DELETE",prefixurl, endpointUrl, callback)   
}

// Join Group
export function apiGroupJoin(prefixurl, groupSlug, callback) {
  const endpointUrl = `/groups/${groupSlug}/join/`
  backendLookup("POST",prefixurl, endpointUrl, callback)   
}

// Leave Group
export function apiGroupLeave(prefixurl, groupSlug, callback) {
  const endpointUrl = `/groups/${groupSlug}/leave/`
  backendLookup("DELETE",prefixurl, endpointUrl, callback)   
}

// Request to join Group
export function apiGroupRequest(prefixurl, groupSlug, callback) {
  const endpointUrl = `/groups/${groupSlug}/request/`
  backendLookup("POST",prefixurl, endpointUrl, callback)   
}

// Cancel Join request.
export function apiGroupRequestCancel(prefixurl, groupSlug, callback) {
  const endpointUrl = `/groups/${groupSlug}/requestcancel/`
  backendLookup("DELETE",prefixurl, endpointUrl, callback)   
}

// Get all members of a Group
export function apiGroupMembers(prefixurl, groupSlug, callback, nextUrl) {
  let endpoint = `/groups/groupmemberlist/?group=${groupSlug}`
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET",prefixurl, endpoint, callback)  
}

// Get all Admins of a Group
export function apiGroupAdmins(prefixurl, groupSlug, callback, nextUrl) {
  let endpoint = `/groups/groupadminlist/?group=${groupSlug}`
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET",prefixurl, endpoint, callback)  
}

// Make Group Admin ( let admin users make other group users as admin ) or
// Dismiss as Admin ( let admin users remove other admin users from being as Admin)
//    no admin should be able to dismiss the group creator to not be admin.
export function apiGroupUserAdminToggle(prefixurl, groupSlug, username, callback) {
  const endpointUrl = `/groups/${groupSlug}/${username}/admintoggle/`
  backendLookup("PUT",prefixurl, endpointUrl, callback)   
}

// Ability for Admins to remove users from a Group ( should not be able to remove the group creator)
export function apiGroupRemoveUser(prefixurl, groupSlug, username, callback) {
  const endpointUrl = `/groups/${groupSlug}/${username}/removeuser/`
  backendLookup("DELETE",prefixurl, endpointUrl, callback)   
}

// Get all requesters of a Group
export function apiGroupRequesters(prefixurl, groupSlug, callback, nextUrl) {
  let endpoint = `/groups/grouprequesters/?group=${groupSlug}`
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET",prefixurl, endpoint, callback)  
}

export function apiRequestAction(prefixurl, groupSlug, requester, action, callback) {
  const data = {action: `${action && action}`.toLowerCase()}
  const endpointUrl = `/groups/${groupSlug}/${requester}/requestaction/`
  backendLookup("POST",prefixurl, endpointUrl, callback, data)   
}

// search for Groups
export function apiSearchGroups(prefixurl, searchText, callback) {
  const endpointUrl =`/groups/groupsearch/?q=${searchText}`
  backendLookup("GET",prefixurl, endpointUrl, callback)   
}

// Get Group Blog Feed
export function apiGroupBlogFeed(prefixurl, groupId, callback, nextUrl) {
  let endpoint = `/grouppost/feed/?id=${groupId}`
  if (nextUrl !== null && nextUrl !== undefined) {
      endpoint = nextUrl.replace(prefixurl,"")
  }
  backendLookup("GET",prefixurl, endpoint, callback)   
}

// Create GroupBlogPost
export function apiGroupBlogCreate(prefixurl, newBlog, groupId, callback) {
  const endpointUrl ="/grouppost/create/"
  backendLookup("POST",prefixurl, endpointUrl, callback, {content: newBlog, group:groupId})   
}

// Get GroupBlogPost Detail
export function apiGroupBlogDetail(prefixurl, blogSlug, callback, prevPage) {
  let endpoint = `/grouppost/detail/${blogSlug}`
  if (prevPage !== null && prevPage !== undefined) {
    endpoint = `/grouppost/detail/${blogSlug}/?commentpage=${prevPage}`
  }
  backendLookup("GET", prefixurl, endpoint, callback)   
}

//   Delete GroupBlogPost
export function apiGroupBlogDelete(prefixurl, blogSlug, callback) {
  const endpointUrl = `/grouppost/${blogSlug}/delete/`
  backendLookup("DELETE",prefixurl, endpointUrl, callback)   
}


// Update GroupBlogpost
export function apiGroupBlogUpdate(prefixurl, editedVal, blogSlug, callback) {
  const endpointUrl = `/grouppost/${blogSlug}/edit/`
  backendLookup("PUT",prefixurl, endpointUrl, callback, {slug:blogSlug, content: editedVal})   
}


// Create GroupBlogpost Comment
export function apiGroupBlogCommentCreate(prefixurl, data, blogSlug, callback) {
  const endpointUrl = `/grouppost/commentcreate/?type=grouppost&slug=${blogSlug}`
  let newComment = data.content
  let newMedianame = data.medianame
  let newMediapath = data.mediapath
  let newMediatype = data.mediatype
  backendLookup("POST", prefixurl, endpointUrl, callback, {content: newComment, medianame:newMedianame, mediapath:newMediapath, mediatype:newMediatype})   
}

// Create GroupBlogpost Comment Reply
export function apiGroupBlogReplyCreate(prefixurl, newReply, blogSlug, parentId, callback) {
  const endpointUrl = `/grouppost/commentcreate/?type=grouppost&slug=${blogSlug}&parent_id=${parentId}`
  backendLookup("POST", prefixurl, endpointUrl, callback, {content: newReply})   
}

// Update Comment or Reply
export function apiGroupBlogCommentReplyEdit(prefixurl, editedVal, pk, callback) {
  const endpointUrl = `/grouppost/grouppostcomment/${pk}/`
  backendLookup("PUT",prefixurl, endpointUrl, callback, {content: editedVal})
}

// Delete GroupBlogpost Comment or Reply
export function apiGroupBlogCommentReplyDelete(prefixurl, pk, callback) {
  const endpointUrl = `/grouppost/grouppostcomment/${pk}`
  backendLookup("DELETE", prefixurl, endpointUrl, callback)   
}

// Get GroupBlogpost likes
export function apiGroupBlogUserLikes(prefixurl, blogSlug, callback) {
  backendLookup("GET", prefixurl, `/grouppost/${blogSlug}/likes/`, callback)   
}

// GroupBlogPost like toggle
export function apiGroupBlogAction(prefixurl, blogSlug, action, callback) {
  const endpointURL = `/grouppost/${blogSlug}/like/`
  backendLookup("PUT", prefixurl, endpointURL, callback )   
}

// Get GroupBlogpost Comment/Reply likes
export function apiGroupBlogUserCommentReplyLikes(prefixurl, CommentOrReply_id, callback) {
  backendLookup("GET", prefixurl, `/grouppost/grouppostcomment/${CommentOrReply_id}/likes/`, callback)    
}

// GroupBlogpost Comment/Reply like toggle
export function apiGroupBlogCommentOrReplyLike(prefixurl, pk, callback) {
  const endpointURL = `/grouppost/grouppostcomment/${pk}/like/`
  backendLookup("PUT", prefixurl, endpointURL, callback )   
}

// Report GroupBlogPost
export function apiGroupUserReportsType(prefixurl, blogSlug, commentReplyId, reportTypeText, callback) {
  const endpointUrl =`/reportusergroupposts/create/?slug=${blogSlug}&commentReplyId=${commentReplyId}&reporttypetext=${reportTypeText}`
  backendLookup("POST",prefixurl, endpointUrl, callback)   
}