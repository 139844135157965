import React, {useContext, useState, useEffect } from 'react';

import SlidingPane from "react-sliding-pane";
import Typography from "@mui/material/Typography";

// local
import {GroupInline} from './groupinline';
import {GroupListAll} from './grouplistall';
import {apiProfileGroupList} from '../../lookup/lookup';
import UrlContext from '../../lookup/prefixurl'
import classes from '../assets/style.module.css'


export function GroupList(props) {
    const prefixurl                       = useContext(UrlContext)
    const [totalGroups, setTotalGroups]   = useState(null);
    const [scrollToPos, setScrollToPos]   = useState(0);
    const [groupItems, setGroupItems]     = useState(false)
    const [groups, setGroups]             = useState([])
    const [refreshGroup, setRefreshGroup] = useState(false)
    const [doneLoading, setDoneLoading]   = useState(false)
    const [state, setState]               = useState({isPaneGroupListAllOpen: false})
    
    let newIndex     = null;

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollToPos);
    }

    useEffect(()=> {
        if (refreshGroup === false) {
            setRefreshGroup(true)
        }
    },[refreshGroup, setRefreshGroup])

    useEffect(() => {
        if (groupItems === false){
            setGroupItems(!groupItems)
            const handleProfileGroupListEvent = (response, status) => {
                if (status === 200) {
                    const newGroups = response.results
                    setTotalGroups(response.totalGroupCount)
                    setGroups(newGroups)
                    setGroupItems(true)
                    setDoneLoading(true)
                }
            }
            apiProfileGroupList(prefixurl, props.username, handleProfileGroupListEvent)
        }
    }, [groupItems, setGroupItems, prefixurl, props.username])


    const handleGroupEdit = async (response) => {
        const editedGroups = ([...groups])
        newIndex = editedGroups.findIndex(group => group.group.slug === response.slug)
        if (newIndex >= 0) {
            editedGroups[newIndex].group = response
            setGroups(editedGroups)
            setRefreshGroup(!refreshGroup)
        }
    }
 
    const handleDelete = (itemDeleted) => {
        const editedGroups = ([...groups])
        newIndex = editedGroups.findIndex(group => group.group.slug === itemDeleted)
        editedGroups.splice(newIndex, 1)
        setGroups(editedGroups)
        setTotalGroups(totalGroups - 1)
    }

    const handleGroupLeft = (itemLeft) => {
        const editedGroups = ([...groups])
        newIndex = editedGroups.findIndex(group => group.group.slug === itemLeft)
        editedGroups.splice(newIndex, 1)
        setGroups(editedGroups)
        setTotalGroups(totalGroups - 1)
    }


    return (
        <div>
            { doneLoading
            ?
            <div className={classes.blogMainPost}>
                <div style={{'display':'flex', marginBottom:"6px"}}>
                    <div style={{width:'100%', padding:'10px 10px 3px 10px' }} onClick={() => totalGroups > 0 ? setState({isPaneGroupListAllOpen: true}) : null }>
                        <Typography
                            //  sx = {{'&.MuiTypography-root' : {color: "#ffffff" }}}
                             color="textSecondary" align='left' gutterBottom={true} style={{float:'right', color:'#3d97f5', fontSize:'1rem', paddingRight:"10px"}}> 
                            Total Groups ({totalGroups})
                        </Typography>
                    </div>
                </div>
                <div className={classes.blogMainPost} >
                    {groups.length > 0 
                        ? groups.map((groupItem, index)=>{
                                return (
                                    <div key={index}>
                                        <GroupInline 
                                            group={groupItem} 
                                            onGroupEdit={handleGroupEdit}
                                            onLeft={handleGroupLeft} 
                                            onDelete={handleDelete} 
                                            positionFix = {handlePosFixed} 
                                            mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)}   }
                                        />
                                    </div>
                                    )
                            }) 
                        :   null
                    }
                </div>
                <SlidingPane
                        className={classes.postCreate}
                        overlayClassName='groupListAllOverlay'
                        isOpen={state.isPaneGroupListAllOpen}
                        width='100%'
                        title=""
                        from='right'
                        onRequestClose={() => setState({ isPaneGroupListAllOpen: false })}
                    >
                        <GroupListAll 
                            username={props.username}
                            onGroupEditY={handleGroupEdit} 
                            onLeftY={handleGroupLeft}
                            onDeleteY={handleDelete} 
                        />
                </SlidingPane>
            </div>
            : null}
        </div>
     )
}
