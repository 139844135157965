import React, {useContext, useState} from 'react';

// third party 'material-ui'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

//local
import UrlContext from '../../lookup/prefixurl'
import {apiGroupBlogAction, apiGroupBlogCommentOrReplyLike, apiGroupBlogCommentReplyDelete } from '../../lookup/lookup';
import classes from '../assets/style.module.css'



export function ActionBtn(props) {
  const prefixurl  = useContext(UrlContext)
  const {blog, action, onLike} = props
  const [liked, setLiked] = useState(blog.liked === true ? true : false)


  const handleActionBackendEvent = (response, status) => {
    if (status === 200){
      setLiked(response.liked)
      onLike(response)
    }
  }
  const handleClick = (event) => {
      event.preventDefault()
      apiGroupBlogAction(prefixurl, blog.slug, action.type, handleActionBackendEvent)
  }
  const likeIcon = liked === true ? <ThumbUpAltIcon 
                                        fontSize='default' 
                                        htmlColor='#4AA6DE'/>
                                    : <ThumbUpOutlinedIcon 
                                        fontSize='default' 
                                        htmlColor='#4AA6DE'/>
  return <button className={classes.clrButton}
            onClick={handleClick}>
            {likeIcon}           
          </button>
}

export function CommentLikeBtn(props) {
  const prefixurl  = useContext(UrlContext)
  const {blog, index, commentId} = props
  const [commentLiked, setCommentLiked] = useState(blog.comments[index].liked === true ? true : false)     
  const {onLike} = props
  const handleActionBackendEvent = (response, status) => {
      setCommentLiked(response.liked)
      let data = {
        "commentId":commentId,
        "likes":response.likes
      }
      onLike(data)
  }
  const handleClick = (event) => {
      event.preventDefault()
      apiGroupBlogCommentOrReplyLike(prefixurl, commentId, handleActionBackendEvent)
  }
  const likeIcon = commentLiked === true ? <ThumbUpAltIcon 
                                              fontSize='default' 
                                              htmlColor='#4AA6DE'/> 
                                          : <ThumbUpOutlinedIcon 
                                              fontSize='default' 
                                              htmlColor='#4AA6DE'/>
  return <button 
            className={classes.clrButton} 
            onClick={handleClick}>
            {likeIcon}   
          </button>
}


export function ReplyLikeBtn(props) {
  const prefixurl  = useContext(UrlContext)
  const {blog, commentIndex, index, replyId} = props
  const [replyLiked, setReplyLiked] = useState(
              blog.comments[commentIndex].replies[index].liked === true ? true : false)  
  const {onLike} = props 
  const handleReplyLikeBackendEvent = (response, status) => {
    setReplyLiked(response.liked)
    let data = {
      'commentIndex':commentIndex,
      'index':index,
      'likes':response.likes
    }
    onLike(data)
  }
  const handleClick = (event) => {
      event.preventDefault()
      apiGroupBlogCommentOrReplyLike(prefixurl, replyId, handleReplyLikeBackendEvent)
  }
  const likeIcon = replyLiked === true ? <ThumbUpAltIcon 
                                            fontSize='default' 
                                            htmlColor='#4AA6DE'/> 
                                        : <ThumbUpOutlinedIcon 
                                            fontSize='default' 
                                            htmlColor='#4AA6DE'/>
  return <button 
            className={classes.clrButton} 
            onClick={handleClick}>
            {likeIcon}
        </button>
}

export function CommentReplyDeleteAction(prefixurl, parentId, pk, callback ) {
  let data = null;
  const handleActionBackendEvent = (response, status) => {
      if (status === 204) {
        data = {
          "status":status,
          "parentId":parentId,
          "pk":pk
        }
      }
      callback(data)
  }
  apiGroupBlogCommentReplyDelete(prefixurl, pk, handleActionBackendEvent)
} 

  