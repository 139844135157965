import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ProfileTabs from './blogs/profile/profile'
import NotifyList from './notify/notifylist'
import LocationFollowersList from './blogs/locationfollowers'
import NameAdd from './nameadd/nameadd'
import GroupMainList from './blogs/group/groupmainlist'


const e = React.createElement

const blogsEl         = document.getElementById('ooima-user-blog')
const profileEl       = document.getElementById('ooima-profile-user')
const locFollowersEl  = document.getElementById('ooima-loc-followers')
const commlecNotifyEl = document.getElementById('ooima-commlec-notify')
const nameAddEl       = document.getElementById('ooima-name-addatlog-in')
const groupMainListEl = document.getElementById('ooima-group-list')



if (blogsEl)  {
  ReactDOM.createRoot(blogsEl).render(e(App, blogsEl.dataset));
} else if (profileEl) {
    ReactDOM.createRoot(profileEl).render(e(ProfileTabs, profileEl.dataset));
} else if (commlecNotifyEl) {
    ReactDOM.createRoot(commlecNotifyEl).render(e(NotifyList, commlecNotifyEl.dataset));
} 

if (locFollowersEl) {
  ReactDOM.createRoot(locFollowersEl).render(e(LocationFollowersList, locFollowersEl.dataset));
}

if (nameAddEl) {
  ReactDOM.createRoot(nameAddEl).render(e(NameAdd, nameAddEl.dataset));
}

if (groupMainListEl) {
  ReactDOM.createRoot(groupMainListEl).render(e(GroupMainList, groupMainListEl.dataset));
}


    