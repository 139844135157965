import React, { useContext, useState, useEffect } from 'react';

// Third party plugins
import PullToRefresh from "react-simple-pull-to-refresh";

// local
import {apiNotificationList, apiNotificationDisplayedUpdate} from '../lookup/lookup';
import classes from  './assets/style.module.css';
import {useLockBodyScroll} from './utilities';
import {NotificationInline} from './notificationinline'
import UrlContext from '../lookup/prefixurl'


export function NotificationList(props) {
    const {notificationDisplayed}   = props
    const {deletedBlogId}           = props
    const {onEdit}                  = props
    const {mainMenuListN}           = props
    const {subMenuN}                = props
    const prefixurl                         = useContext(UrlContext)
    const [doneLoading, setDoneLoading]     = useState(false)
    const [notifications, setNotifications] = useState([])
    const [nextUrl, setNextUrl]             = useState(null)
    const [notificationsDidSet, 
            setNotificationsDidSet]         = useState(false)
    const [scrollToPos, setScrollToPos]     = useState(0);

    useLockBodyScroll();

    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreNotifications()
        }
    }

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollToPos);
    }

    useEffect(() => {
        if (notificationsDidSet === false) { 
            const handleNotificationListLookup = (response, status) => {
                if (status === 200){
                    setDoneLoading(true)
                    setNotifications(response.results)
                    setNextUrl(response.next)
                    setNotificationsDidSet(true)
                }
            }
            const handleNotificationDisplayed = (response, status) => {
                if (status === 200){
                    notificationDisplayed(null)
                }
            }
            apiNotificationList(prefixurl, handleNotificationListLookup)
            apiNotificationDisplayedUpdate(prefixurl, handleNotificationDisplayed)
        }
      }, [prefixurl, notifications, notificationsDidSet, setNotificationsDidSet, notificationDisplayed])


    const handleRefreshc = () => {
        const handleNotificationListLookup = (response, status) => {
            if (status === 200){
                setDoneLoading(true)
                setNotifications(response.results)
                setNextUrl(response.next)
                setNotificationsDidSet(true)
            }
        }
        const handleNotificationDisplayed = (response, status) => {
            if (status === 200){
                notificationDisplayed(null)
            }
        }
        apiNotificationList(prefixurl, handleNotificationListLookup)
        apiNotificationDisplayedUpdate(prefixurl, handleNotificationDisplayed)
        return new Promise((resolve) => {
            setTimeout(resolve, 1000)
        });
    };
          
    const loadMoreNotifications = (event) =>{
        event.preventDefault()
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newNotifications = [...notifications].concat(response.results)
                    setNotifications(newNotifications)
                } else {
                  console.log("There was an error")
                }
            }
            apiNotificationList(prefixurl, handleLoadNextResponse, nextUrl)
        }
    }

    const handleNotificationToggle = (added, slug) => {
        const editedNotifications = ([...notifications])
        editedNotifications.forEach(function(obj) { 
            if (obj.obj_text === slug){
                obj.donotnotifyfrompost = added
            };
        });
        setNotifications(editedNotifications)
    }

    const handleDonotNotify = (donotnotified, username) => {
        const editedNotifications = ([...notifications])
        editedNotifications.forEach(function(obj) { 
            if (obj.actor_content_object.username === username){
                obj.actor_content_object.donotnotified = donotnotified
            };
        });
        setNotifications(editedNotifications)
    }


    const handleDelete = (itemDeleted) => {
        // const filteredBlogs = ([...blogs].filter(blog => blog.blogpost.id !== itemDeleted))
        // setBlogs(filteredBlogs)
        // setBlogsDidDelete(!blogsDidDelete)
        deletedBlogId(itemDeleted)
    }  

    const handleBlogEdit = (updatedPost) => {
        onEdit(updatedPost)
    }
    

    return (
        <div >
        {(doneLoading === true) 
        ?
        <PullToRefresh
            onRefresh={handleRefreshc}
            pullingContent = {false}
        > 
        {/* <div className='row' onScroll={handleScroll} style={{width:'100%', height:'100%', overflowY:'scroll'}} > */}
        <div className='row' onScroll={handleScroll} style={{height:'100%', overflowY:'scroll'}} >

            <div className='col-sm-6 col-sm-offset-3'>
                <div className={classes.blogMainPost}>
                    {notifications.length > 0 
                    ?   notifications.map((notificationItem, index)=>{
                            return (
                                <div key={index}>
                                    <NotificationInline 
                                        notification={notificationItem}
                                        notificationState = {handleNotificationToggle} 
                                        donotnotifyState = {handleDonotNotify}  
                                        onEdit = {handleBlogEdit} 
                                        onDelete = {handleDelete}
                                        mainMenuListArray = {mainMenuListN}
                                        subMenuObj = {subMenuN} 
                                        positionFix = {handlePosFixed} 
                                        mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)}   }
                                        />
                                </div>
                                )
                        })
                    :   
                        <div style={{backgroundColor:'#172734', padding: '3px 0 3px 10px',marginTop:'6px', marginBottom: '6px'}}>
                            <p style={{color: 'white', marginBottom: '0px', lineHeight: '1.38105', letterSpacing: '.011em', fontSize: '0.9375rem !important'}}>
                            You have no notifications
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div> 
        </PullToRefresh>
         : 
            null 
        }
    </div>
     )
}


