import React, {useContext, useState, useEffect } from 'react';

// Third party plugins
import SlidingPane from "react-sliding-pane";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';


// local
import { UrlProvider } from '../../lookup/prefixurl'
import {BlogInline} from '../bloginline';
import {apiProfileFeed, apiReportsTypeList, apiGroupCreate, apiGroupDelete} from '../../lookup/lookup';
import {BlogForm} from '../blogform';
import {verifyImage, verifyVideo, ImageLoading} from '../utilities';
import UrlContext from '../../lookup/prefixurl'
import classes from '../assets/style.module.css'
import { ProfileImage, ProfileFollow } from './profilecomponents'
import Creategroup from '../group/Creategroup';
import Backdrop from '../group/Backdrop';

import {GroupFeed} from '../group/groupfeed';
import {GroupList} from '../group/grouplist';


export function ProfileFeed(props) {
    const {location}                            = props
    const prefixurl                             = useContext(UrlContext)
    const [sessionUsr, setSessionUsr]           = useState(null)
    const [blogs, setBlogs]                     = useState([])
    const [blogsListClass]                      = useState('card')
    const [imgLoadingUrl, setImgLoadingUrl]     = useState(null);
    const [nextUrl, setNextUrl]                 = useState(null)
    const [blogsDidSet, setBlogsDidSet]         = useState(false)
    const [stateForm, setStateForm]             = useState({isPaneBlogCreate: false})
    const [ReportTypeItems, setReportTypeItems] = useState(false)
    const [mainMenuList, setmainMenuList]       = useState([])
    const [subMenu, setsubMenu]                 = useState({})
    const [scrollTo, setScrollTo]               = useState(0);
    const [creatingGroup, setCreatingGroup]     = useState(false)
    const [name, setName]                       = useState('')
    const [description, setDescription]         = useState('')
    const [privacy, setPrivacy]                 = useState(null)
    const [errorName, setErrorName]             = useState(false)
    const [errorPrivacy, setErrorPrivacy]       = useState(false)
    const [stateGroupFeed, setStateGroupFeed]   = useState({isPaneGroupFeed: false})
    const [newGroupSlug, setNewGroupSlug]       = useState(null)
    const [groupId, setGroupId]                 = useState(null)

    let murl = null;
    let surl = null;
    let lMediaType = null
    let newIndex = null;

    const scrollToTop = () => window.scrollTo({
        top: 0
    })

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollTo);
    }

    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreBlogs()
        }
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '&.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
      
    const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
    });
      
    function BpRadio(props) {
    return (
        <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
        />
    );
    }



    const nameEventHandler = (e) => {
        setName(e.target.value)
        setErrorName(false)
    }

    const descriptionEventHandler = (e) => {
        setDescription(e.target.value)
    }

    const privacyEventHandler = (e) => {
        setPrivacy(e.target.value)
        setErrorPrivacy(false)
    }

    const handleImgLoadingUrl = (url) => {
        setImgLoadingUrl(url)
    }

    const handleDelete = (itemDeleted) => {
        const editedBlogs = Object.assign([], blogs);
        newIndex = editedBlogs.findIndex(blog => blog.id === itemDeleted)
        editedBlogs.splice(newIndex, 1)
        setBlogs(editedBlogs)
    }

    const handleMediaProcess = async (response) => {
        const editedBlogs = ([...blogs])
        const handleDelayedMedialoadPushed = (loaded) => {
            if (loaded) {
                editedBlogs[newIndex].get_sd_download_url = surl
                editedBlogs[newIndex].get_media_download_url = murl
                editedBlogs[newIndex].mediatype = lMediaType
            } 
        }
        if (lMediaType.slice(0,6) === "video/") {
            await verifyVideo(murl, handleDelayedMedialoadPushed)
        } else {
            await verifyImage(murl, handleDelayedMedialoadPushed)
        }
        await setBlogs(editedBlogs)
        //clear variables
        murl        = null;
        surl        = null;
        lMediaType  = null;
        newIndex    = null;
    }
    
    const handleBlogEdit = async (response) => {
        const editedBlogs = ([...blogs])
        newIndex = editedBlogs.findIndex(blog => blog.slug === response.slug)
        const orig_media = editedBlogs[newIndex].mediapath
        if (response.get_media_download_url) {
            if (orig_media !== response.mediapath) {  // if media file has changed
                murl       = response.get_media_download_url
                surl       = response.get_sd_download_url
                lMediaType = response.mediatype
                editedBlogs[newIndex].content = response.content
                editedBlogs[newIndex].get_media_download_url = imgLoadingUrl
                editedBlogs[newIndex].get_sd_download_url = imgLoadingUrl
                editedBlogs[newIndex].mediatype = 'image/jpg'
                setBlogs(editedBlogs);
                await handleMediaProcess(response)
            } else {  // if media file has not changed
                editedBlogs[newIndex] = response
                setBlogs(editedBlogs)
            }
        } else { // if there is no media file
            editedBlogs[newIndex] = response
            setBlogs(editedBlogs)
        }
    }

    const handleBookmarkAdded = (added) => {
        if (added) {
            // setStatus("Bookmark added")
        } else {
            // setStatus("Bookmark removed")
        }
    }

    const handleDoNotNotify = (added) => {
        if (added) {
            // setStatus("Notifications turned off")
        } else {
            // setStatus("Notifications turned on")
        }
    }

    const  handleGroupCreation = (e) => {
        setName('')
        setDescription('')
        setPrivacy(null)
        setErrorPrivacy(false)
        setErrorName(false)
        setCreatingGroup(false);
        setGroupId(e.id)
        setNewGroupSlug(e.slug)
        // add the new Group to the top of the list
        // TBD
        setStateGroupFeed({ isPaneGroupFeed: true })
    }

    const handleGroupEdit = () => {}

    const handleNewGroupDelete = (groupDeleted) => {
        if (groupDeleted) {
            const handleNewGroupDeleteEvent = () => {}
            apiGroupDelete(prefixurl, newGroupSlug, handleNewGroupDeleteEvent)
        }
        setStateGroupFeed({ isPaneGroupFeed: false })
    }

    const startCreateEventHandler = (e) => {
        console.log("startCreate", e)
        setCreatingGroup(true);
    }

    const groupCancelHandler = () => {
        setName('')
        setDescription('')
        setPrivacy(null)
        setErrorPrivacy(false)
        setErrorName(false)
        setCreatingGroup(false);
    }

    const groupCreateHandler = () => {
        if (name.trim().length === 0) {
            setErrorName(true)
        }
        if (privacy === null) {
            setErrorPrivacy(true)
        }
        if (name.trim().length !== 0 && privacy !== null) {
            apiGroupCreate(prefixurl, name, description, privacy, handleGroupCreation)
        }
    }

    //cleanup
    useEffect(() => {
        return () => {
        }
    })
    
    useEffect(() => {
        if (ReportTypeItems === false){
            setReportTypeItems(!ReportTypeItems)
            const handleReportTypesEvent = (response, status) => {
                if (status === 200) {
                    const obj = {}
                    response.forEach(el => obj[el.content] = el.reportSubtypes.map(e => e.content));
                    setsubMenu(obj)
                    setmainMenuList(Object.keys(obj))
                }
            }
            apiReportsTypeList(prefixurl, handleReportTypesEvent)
        }
    }, [ReportTypeItems, setReportTypeItems,mainMenuList, setmainMenuList, subMenu, prefixurl])

    

    useEffect(() => {
        if (blogsDidSet === false) { 
            const handleBlogListLookup = (response, status) => {
                if (status === 200){
                    const newBlogs = response.results
                    setSessionUsr(response.sessionUser)
                    setBlogs(newBlogs)
                    setNextUrl(response.next)
                    setBlogsDidSet(true)
                }
            }
            apiProfileFeed(prefixurl, props.username, handleBlogListLookup)
            ImageLoading(prefixurl, handleImgLoadingUrl)
        }
      }, [props.username, prefixurl, location, blogs, blogsDidSet, setBlogsDidSet])


    const loadMoreBlogs = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newBlogs = [...blogs].concat(response.results)
                    setBlogs(newBlogs)
                } else {
                  console.log("There was an error")
                }
            }
            apiProfileFeed(prefixurl, props.username, handleLoadNextResponse, nextUrl)
        }
    }
    
    const newBlog = async (response) => { // new post is added
        setStateForm({ isPaneBlogCreate: false })
        const editedBlogs = ([...blogs])
        editedBlogs.unshift(response)  // add the new post to the top of the list

        if (response.get_media_download_url) {
            murl       = response.get_media_download_url
            surl       = response.get_sd_download_url
            lMediaType = response.mediatype
            newIndex   = 0 // first in the array since this is a new post
            editedBlogs[newIndex].get_media_download_url = imgLoadingUrl
            editedBlogs[newIndex].get_sd_download_url = imgLoadingUrl
            editedBlogs[newIndex].mediatype = 'image/jpg'
            await setBlogs(editedBlogs)
            const handleDelayedNewMedialoadPushed = async (loaded) => {
                if (loaded) {
                    editedBlogs[newIndex].get_sd_download_url = surl
                    editedBlogs[newIndex].get_media_download_url = murl
                    editedBlogs[newIndex].mediatype = lMediaType
                    await setBlogsDidSet(false)
                } 
            }

            setTimeout(async() => {
                if (lMediaType.slice(0,6) === "video/") {
                    await verifyVideo(murl, handleDelayedNewMedialoadPushed)
                } else {
                    await verifyImage(murl, handleDelayedNewMedialoadPushed)
                }
            }, 1000);
            scrollToTop()
        } else {
            setBlogs(editedBlogs)
            scrollToTop()
        }
    }

    return (
        <div onScroll={handleScroll} style={{width:'100%', height: 'calc(100vh - 152px)', overflowX:'hidden'}} >
            <div style={{width:'100%', textAlign:'center', marginTop:'10px'}}>
                {prefixurl !== undefined
                ? <React.Fragment>
                    <UrlProvider value={prefixurl}>
                        <ProfileImage username={props.username} />
                    </UrlProvider>
                    <div>
                        <UrlProvider value={prefixurl}> 
                            <ProfileFollow username={props.username} />
                        </UrlProvider>
                    </div>
                    </React.Fragment>
                : <React.Fragment>
                    <ProfileImage username={props.username} />
                    <div>
                        <ProfileFollow username={props.username} />
                    </div>
                    </React.Fragment>
                }
            </div>

            {/* Groups Start */}
            <div>
                <React.Fragment>
                {creatingGroup && <Backdrop /> }
                {creatingGroup && 
                    <Creategroup title="Create Group" canCancel canCreate onCancel={groupCancelHandler} onCreate={groupCreateHandler}>
                        <form>
                            <FormControl 
                                sx={{ 
                                        '& .MuiOutlinedInput-root': {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ffffff"
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ffffff"
                                            }
                                        }
                                    }} 
                                fullWidth error={errorName} variant="standard" style={{paddingBottom: '20px', color:'#fff'}}>
                                <OutlinedInput
                                    id="name"
                                    autoComplete="off"
                                    placeholder="Group name..."
                                    style={{fontSize:'1.3rem', color:'#182734', background:'#fff'}}
                                    value={name} 
                                    onChange={nameEventHandler}  
                                    error={errorName}
                                />
                                {errorName && <FormHelperText style={{fontSize:'0.95rem'}}>Add a group name</FormHelperText>}
                            </FormControl>

                            <FormControl 
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ffffff"
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ffffff"
                                        }
                                    }
                                }} 
                                fullWidth variant="standard" style={{paddingBottom: '20px'}}> 
                                <OutlinedInput
                                    id="description"
                                    autoComplete="off"
                                    placeholder="Description..."
                                    multiline
                                    style={{fontSize:'1.3rem', color:'#182734', background:'#fff'}}
                                    value={description} 
                                    onChange={descriptionEventHandler}  
                                />
                            </FormControl>
                            <div style={{padding: '20px', background: '#0D171F', border:'solid 1px #335670', borderRadius:'6px'}}> 
                                <FormControl error={errorPrivacy} variant="standard">
                                    <FormLabel id="privacy" style={{fontSize:'1.35rem', color:'#fff'}}>Privacy</FormLabel>
                                    <RadioGroup aria-labelledby="privacy" name="privacy" value={privacy} onChange={privacyEventHandler}>
                                        <FormControlLabel 
                                            sx = {{'& .MuiFormControlLabel-label' : { color : '#ffffff'}}}
                                            value="O" control={<BpRadio />} label="Open for all"   labelPlacement="start" 
                                        />
                                        <FormControlLabel  
                                            sx = {{'& .MuiFormControlLabel-label' : { color : '#ffffff'}}}
                                            value="P" control={<BpRadio />} label="Make Private"  labelPlacement="start" 
                                        />
                                    </RadioGroup>
                                    {errorPrivacy && <FormHelperText style={{fontSize:'0.95rem'}}>Choose Privacy Type</FormHelperText>}
                                </FormControl>
                            </div>
                        </form>
                    </Creategroup> 
                }
                <div style={{'display':'flex', "backgroundColor": "#172734", marginBottom:"6px"}}>
                        <div style={{width:'100%', padding:'10px 10px 3px 10px' }}>
                            <Typography color="textSecondary" align='left' gutterBottom={true} style={{fontSize:"1.35rem", color:"#ffffff"}}> 
                                Groups 
                                {props.username === sessionUsr
                                ? 
                                    <Button 
                                        sx = {theme => ({
                                            '&..MuiButton-root':{
                                                color: theme.palette.getContrastText(blue[500]),
                                                backgroundColor: blue[500],
                                                '&:hover': {
                                                backgroundColor: blue[700],
                                                }
                                            }
                                        })}
                                        size="small" variant="contained" color="primary" style={{float:'right', paddingRight:"10px"}} onClick={startCreateEventHandler}>
                                        Create
                                    </Button>
                                : null
                                }
                            </Typography>
                            <GroupList username={props.username} />
                        </div>
                </div>
                </React.Fragment>
            </div>

            {/* Groups END */}

            <div>
                <div>                                
                    {props.username === sessionUsr
                    ? 
                        <div style={{'display':'flex', "backgroundColor": "#172734"}}>
                            <div style={{width:'100%', padding:'10px 0px 3px 10px' }}>
                                <Typography 
                                    sx = {{'&.MuiTypography-root' : {color: "#0077b6" }}}
                                    color="textSecondary" align='left' variant='h6' gutterBottom={true}>  
                                        <Button 
                                         sx = {theme => ({
                                            '&.MuiButton-root':{
                                                color: theme.palette.getContrastText(blue[500]),
                                                backgroundColor: 'blue[500]',
                                                '&:hover': {
                                                backgroundColor: blue[700],
                                                }
                                            }
                                        })}
                                        size="small" variant="contained" color="primary" onClick ={() => setStateForm({ isPaneBlogCreate: true })} startIcon={<AddCircleIcon htmlColor='#ffffff'/>}>
                                            Add Post
                                        </Button>
                                </Typography>
                            </div>
                        </div>
                    : 
                        blogs.length > 0 
                        ?
                        <div style={{'display':'flex', "backgroundColor": "#172734"}}>
                            <div style={{width:'100%', padding:'10px 0px 3px 10px' }}>
                                <Typography color="textSecondary" align='left' gutterBottom={true} style={{fontSize:"1.35rem", color:"#ffffff"}}> 
                                    Posts
                                </Typography>
                            </div>
                        </div>
                        : 
                        null
                    }
                    {blogs.length > 0 ? blogs.map((blogItem, index)=>{
                        return (
                            <div key={index}>
                                <BlogInline 
                                    blog={blogItem} 
                                    sessionusr={sessionUsr} 
                                    onDelete={handleDelete}
                                    bookmarkState={handleBookmarkAdded}
                                    notificationState = {handleDoNotNotify}
                                    onEdit={handleBlogEdit}
                                    mainMenuListArray = {mainMenuList}
                                    subMenuObj = {subMenu} 
                                    elClass={blogsListClass}
                                    positionFix = {handlePosFixed}
                                    mBScrollPos = { (ypos) =>  { setScrollTo(ypos)}   }
                                    key={`${index}-{blogItem.id}`}
                                />
                            </div>
                        )
                    }) :""}
                </div>
            </div>
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='formCreateOverlay'
                isOpen={stateForm.isPaneBlogCreate}
                width='100%'
                title=""
                onRequestClose={() => setStateForm({ isPaneBlogCreate: false })}
            >
                <BlogForm didSubmit={newBlog} location={location}/>
            </SlidingPane>

            <SlidingPane
                className={classes.postCreate}
                overlayClassName='formCreateOverlay'
                isOpen={stateGroupFeed.isPaneGroupFeed}
                width='100%'
                title=""
                onRequestClose={() => setStateGroupFeed({ isPaneGroupFeed: false })}
            >
                <GroupFeed groupId={groupId} 
                           groupSlug={newGroupSlug} 
                           didGroupEdit={handleGroupEdit} 
                           didDelete={handleNewGroupDelete}
                />
            </SlidingPane>
        </div> 
     )
}
