import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

//third party plugins
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Typography from "@mui/material/Typography";
import Drawer from '@mui/material/Drawer';

// local
import UrlContext from '../../lookup/prefixurl'
import {apiDonotNotifylectureToggle } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'



export function LocationsFollowedInline(props) {
    const prefixurl = useContext(UrlContext)
    const {locationfollowed} = props
    const {canSetNotification} = props
    const [disp, setDisp] = useState(false);
    const [state, setState] = useState({isPaneOpen: false})

    useEffect(() => {
        //refresh
    }, [disp, setDisp])

    const handleLocationUrl = (locUrl) => {
        window.location.replace(locUrl);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                <ListItem button key={'donotnotify'} onClick={handleNotificationToggle}>
                    <ListItemIcon>{<NotificationsPausedIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        {locationfollowed.donotnotifyfromlecture
                        ? `Turn on notifications for ${locationfollowed.title}` 
                        : `Turn off notifications for ${locationfollowed.title}`}
                    </Typography>
                </ListItem>
            </List>
        </div>
    );

    const handleNotificationToggle = (event) => {
        event.preventDefault()
        apiDonotNotifylectureToggle(prefixurl, locationfollowed.slug, (response, status) => {
            if (status === 200) {
                locationfollowed.donotnotifyfromlecture = response.donotnotifyfromlecture
                setDisp(!disp)
            }
        })
    }


    return (
        <div className={classes.echdiv2} >
            <div>
                <div onClick={() => {handleLocationUrl(locationfollowed.url) }} style={{float:'left', marginRight:'20px', marginTop:'6px', paddingBottom:'6px'}}>
                    <span>
                        <LocationOnIcon fontSize='large' htmlColor='#4AA6DE' />
                    </span>
                </div>

                <div style={{marginLeft:'60px', marginTop:'12px'}} onClick={() => {handleLocationUrl(locationfollowed.url) }} >
                    <span style={{color:'#ffffff', wordBreak:'break-word', fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                        <Typography style={{ fontSize: '1.25rem', color: '#ffffff' }} gutterBottom>
                            {locationfollowed.title}
                        </Typography> 
                        <Typography variant="body1" gutterBottom>
                            {locationfollowed.city}{' '}{locationfollowed.state}{' '}{locationfollowed.statezip}
                        </Typography> 
                    </span>
                </div>
            </div>

            {canSetNotification
            ?
            <div className={classes.locmenu}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={toggleDrawer('bottom', true)}
                    >
                    <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                </IconButton>
                <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                    {list('bottom')}
                </Drawer>                                        
            </div>
            : null
            } 
        </div>
    )
}

