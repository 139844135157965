import React from 'react';
import Typography from "@mui/material/Typography";
import '../../blogs/assets/Creategroup.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';


const creategroup = props => (

    <div className="creategroup">
        <header className="creategroup__header">
            <Typography style={{ loat:'left', fontSize: '1.25em', color: '#ffffff'}}>
                {props.title}
                {props.canCancel && <CloseIcon onClick={props.onCancel} style={{float:'right'}} />}
            </Typography>
        </header>
        <section className="creategroup__content">
            {props.children}
        </section>
        <section className="creategroup__actions">
            {props.canCreate && <Button 
                                     sx = {theme => ({
                                        '&..MuiButton-root':{
                                            color: theme.palette.getContrastText(blue[500]),
                                            backgroundColor: blue[500],
                                            '&:hover': {
                                            backgroundColor: blue[700],
                                            }
                                        }
                                    })}
            variant="contained" color="primary" onClick={props.onCreate}>Create</Button>}
        </section>
    </div>

);

export default creategroup;