
import React, {useContext, useState, useEffect} from 'react';

// third party plugins
import SlidingPane from "react-sliding-pane";
import ProfileTabs from './profile/profile'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";

// local
import UrlContext from '../lookup/prefixurl'
import {apiBlogUserCommentReplyLikes, apiUserFollowToggle} from '../lookup/lookup';
import classes from './assets/style.module.css';
import './assets/customSliding.css'
import {useLockBodyScroll} from './utilities'


export function UserCommentOrReplyLikes(props) {
    const prefixurl                           = useContext(UrlContext)
    const [likesusers, setLikesusers]         = useState(null)
    const [doneLoading, setDoneLoading]       = useState(false)
    const [didEdit, setdidEdit]               = useState(false)
    const [followLoading, setFollowLoading]   = useState(false)
    const [userProfile, setUserProfile]       = useState(null)
    const [userProfilePrivacyFlag, setUserProfilePrivacyFlag] = useState(false)
    const [userProfileBlockedFlag, setUserProfileBlockedFlag] = useState(false);
    const [state, setState]                   = useState({ isPaneOpen: false});
    const [sessionusr, setSessionUsr]         = useState(null);

    useLockBodyScroll();

    const handleUserLikesLookup = (response, status) => {
        setSessionUsr(response.sessionUser)
        if (status === 200){
            setLikesusers(response)
            setDoneLoading(true)
        }
    }

    const handleFollowToggle = (event) => {
        if (!followLoading) {
            setFollowLoading(true)
            apiUserFollowToggle(prefixurl, event.username, event.followed ? "unfollow" : "follow", (response, status) => {
                if (status === 200) {
                    setFollowLoading(false)
                    setdidEdit(!didEdit)
                }
            }  )
        }
    }

    const handleAvatar = (username, privacyflag, blockedflag)=>{
        setUserProfile(username)
        if (likesusers.sessionUser === username) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(privacyflag)
            setUserProfileBlockedFlag(blockedflag)
        }
        setState({ isPaneOpen: true })
    }

    useEffect(() => {
        if (didEdit === false){
            setdidEdit(true)
            apiBlogUserCommentReplyLikes(prefixurl, props.id, handleUserLikesLookup)
        }
    }, [prefixurl, props, props.id, didEdit, setdidEdit])

    
    return (
        <div className='row' style={{backgroundColor:'#0d171f'}}>
            {(doneLoading === true) 
            ? 
            <div className='col-lg-6 col-lg-offset-3'>
                <div>
                    <div style={{display:'flex', flexDirection:'column', flexGrow:'1'}}>
                        {likesusers.likes.map((like, i) => ( 

                            <div key={i} className={classes.echdiv1}>
                                <div className={classes.echdiv2}>
                                    <div className={classes.echdiv3}>
                                        <div className={classes.avatar34} onClick={() => handleAvatar(like.username, like.private, like.blockedby)}>
                                            <img className={classes.avtrimg} src={like.avatar} alt=''></img>
                                        </div>
                                    </div>

                                    <div className={classes.echdiv4}>
                                        <div className={classes.echdiv5}  onClick={() => handleAvatar(like.username, like.private, like.blockedby)} >
                                            <div>
                                                {like.private ?
                                                    <div className={classes.propiclock}>
                                                        <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                                                    </div> 
                                                : null}

                                                <Typography style={{ fontSize: '1.25em', color: '#ffffff' }}>
                                                    { like.first_name 
                                                    ? like.first_name + ' ' + like.last_name
                                                    : like.username
                                                    }
                                                </Typography>
                                            </div>
                                        </div>

                                        <div style={{paddingLeft:'10%'}}>
                                            { like.username !== likesusers.sessionUser 
                                            ?
                                                !like.private 
                                                    ? !like.blockedby
                                                        ? 
                                                            <button className="btn btn-sm btn-info" style={{width:'80px'}} onClick={() => handleFollowToggle(like)}>
                                                                { like.followed ? "UnFollow" : "Follow" }
                                                            </button>
                                                        : null
                                                : null
                                            : null
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='editedPostOverlay'
                    isOpen={state.isPaneOpen}
                    width='100%'
                    title=""
                    // from='bottom'
                    onRequestClose={() => setState({ isPaneOpen: false })}
                >
                    <ProfileTabs username={userProfile} sessionusr={sessionusr} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>

                </SlidingPane>
            </div> 
            : 
                null
            }
        </div>
    )
}

export default UserCommentOrReplyLikes

