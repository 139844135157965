import React, { Component } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
// import {BlogsList} from './blogs/list'
import {FeedList} from './blogs/feed';

import { UrlProvider } from './lookup/prefixurl';


class App extends Component {

  render() {

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 895,
          lg: 1280,
          xl: 1920,
        },
      },
    })

    const {location} = this.props
    const {prefixurl} = this.props

    return (
      <UrlProvider value={prefixurl}>
        <ThemeProvider theme={theme}>
          <FeedList location={location}  /> 
        </ThemeProvider>
      </UrlProvider>
    ) 
  }
}


export default App;


