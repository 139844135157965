
import React, { useContext, useState } from 'react';

//third party plugins
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

// local
import UrlContext from '../../lookup/prefixurl'
import { apiBlockUserToggle } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'



export function ProfileBlockedInline(props) {
    const {userblocked}                     = props
    const {userUnblocked}                   = props
    const prefixurl                         = useContext(UrlContext)
    const [blockLoading, setBlockLoading ]  = useState(false);
    // const [didEdit, setDidEdit]             = useState(false)
    const [ubOpen, setUbOpen]               = useState(false); 


    const handleUnblock = (event) => {
        if (!blockLoading) {
            setBlockLoading(true)
            apiBlockUserToggle(prefixurl, event.username, "unblock", (response, status) => {
                if (status === 200) {
                    setBlockLoading(false)
                    userUnblocked(userblocked)
                }
            }  )
        }
    }

    
    return (
        <div className={classes.echdiv2}>
            <div style={{width:'300px'}} >
                <div style={{float:'left', marginRight:'20px', marginTop:'6px', paddingBottom:'6px'}}>
                    <div className={classes.avatar34}>
                        <img className={classes.avtrimg} src={userblocked.avatar} alt=''></img>
                    </div>
                </div>

                <div style={{marginLeft:'60px', marginTop:'17px'}} >
                    <span style={{color:'#ffffff', wordBreak:'break-word', fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                    <Typography style={{ fontSize: '1.25em', color: '#ffffff' }} gutterBottom>
                            { userblocked.first_name 
                            ? userblocked.first_name + ' ' + userblocked.last_name
                            : userblocked.username
                            }
                        </Typography>
                    </span>
                </div>
                <div className={classes.profilepostcmcblk}>
                    <Button 
                        sx = {{
                            "&.MuiButton-root": {
                                border: "2px white solid"
                              },
                              "&.MuiButton-text": {
                                color: "white"
                              },
                              "&.MuiButton-contained": {
                                color: "white"
                              },
                              "&.MuiButton-outlined": {
                                color: "white"
                              }
                        }}
                        variant="outlined" onClick={()=> {setUbOpen(true)}} >
                        Unblock
                    </Button>

                    <Dialog
                        open={ubOpen}
                        onClose={()=> {
                            setUbOpen(false)
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">
                        Unblock
                            {
                                userblocked.first_name 
                                ? 
                                userblocked.last_name 
                                    ? ' ' + userblocked.first_name  + ' ' + userblocked.last_name
                                    : ' ' + userblocked.first_name 
                                : ' ' + userblocked.username
                            } ?
                    </DialogTitle>
                        <DialogContent dividers>
                            <Typography paragraph>
                                If you unblock 
                                { userblocked.first_name 
                                ? ' ' + userblocked.first_name + ', '
                                : ' ' + userblocked.username + ', '
                                } 
                                the person will be able to start following you, and view your posts.
                            </Typography>
                            <Typography gutterBottom>
                                You will have to wait 48 hours if you want to block
                            { userblocked.first_name 
                                ? ' ' + userblocked.first_name 
                                : ' ' + userblocked.username + ', '
                                } again.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                        <Box 
                            display="flex" 
                            width={600} height={40} 
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button onClick={()=> {
                                            setUbOpen(false)
                                        }} color="primary">
                                Cancel
                            </Button>
                            <Button 
                                onClick= {() => {
                                    setUbOpen(false);
                                    handleUnblock(userblocked)
                                }} 
                                color="primary" autoFocus>
                                Unblock
                            </Button>
                            </Box>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}