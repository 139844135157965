import React, { useEffect } from "react";

import {
  Snackbar,
  SnackbarContent,
  InfoWrapper
} from "./style";


function SimpleSnackbar({ status, msg }) {
    const styles = {
            snakbar: {
              color: "#ffffff",
              width: "100px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              margin: "0 auto"
            }
      };

  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    setOpen(true);
  }, [status]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      style={{ top: '60px'}}
    >
      <SnackbarContent
        style = {styles.snakbar}
        status={status}
        contentprops={{
          "aria-describedby": "message-id"
        }}
        message={(
          <InfoWrapper id='message-id'>
            {msg || `${status}`}
          </InfoWrapper>
        )}
      />
    </Snackbar>
    
  );
}
export default SimpleSnackbar;
