import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

//third party plugins
import SlidingPane from "react-sliding-pane";
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BlockIcon from '@mui/icons-material/Block';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";


// local
import UrlContext from '../../lookup/prefixurl'
import { apiRequestAction } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import ProfileTabs from '../profile/profile';


export function GroupRequestsInline(props) {
    const {groupslug}                   = props;
    const {sessionuser}                 = props;
    const {requester}                   = props;
    const prefixurl                     = useContext(UrlContext);
    const [didEdit, setDidEdit]         = useState(false);
    const [userProfilePrivacyFlag, 
            setUserProfilePrivacyFlag]  = useState(false);
    const [userProfileBlockedFlag, 
            setUserProfileBlockedFlag]  = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [state, setState]             = useState({isPaneOpen: false});
    const [accepted, setAccepted]       = useState(false);
    const [rejected, setRejected]       = useState(false);
    
    useEffect(()=>{
        if (didEdit === false) { 
            setDidEdit(true)
        }
    },[props, didEdit, setDidEdit])

    const handleAvatar = (username, privacyFlag, blockedFlag)=>{
        setUserProfile(username)
        if (username === sessionuser) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(privacyFlag)
            setUserProfileBlockedFlag(blockedFlag)
        }
        setState({ isPaneOpen: true })
    }

    
    const handleRequestAcceptReject = (action) => {
        apiRequestAction(prefixurl, groupslug, requester.request_from.username, action, (response, status) => {
            if (status === 201) {
                if (action === 'accept') {
                    setAccepted(true)
                } else {
                    if (action === 'reject') {
                        setRejected(true)
                    }
                }
            }
        })
    }


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                <ListItem button key={'donotnotify'} onClick={() => handleRequestAcceptReject('reject')}>
                    <ListItemIcon>{<BlockIcon fontSize='large'/> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        Deny request
                    </Typography>
                </ListItem>
            </List>
        </div>
    );
    
    return (
        <div className={classes.echdiv2}>
            <div style={{width:'300px'}} >
                <div style={{float:'left', marginRight:'20px', marginTop:'6px', paddingBottom:'6px'}}>
                    <div className={classes.avatar34} onClick={() => handleAvatar(requester.request_from.username, requester.request_from.private, requester.request_from.blockedby)}>
                        <img className={classes.avtrimg} src={requester.request_from.avatar} alt=''></img>
                    </div>
                </div>

                <div style={{marginLeft:'60px', marginTop:'12px'}} onClick={() => handleAvatar(requester.request_from.username, requester.request_from.private, requester.request_from.blockedby)} >
                    {requester.request_from.private ?
                        <div className={classes.propiclock} >
                            <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                        </div> 
                    : null}
                    <span style={{color:'#ffffff', wordBreak:'break-word', fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                        <Typography style={{ fontSize: '1.25em', color: '#ffffff' }} gutterBottom>
                            { requester.request_from.ui_displayname} 
                        </Typography>
                    </span>
                </div>
            </div>
            <div style={{paddingRight:'15%'}}>
                { accepted
                ?
                <button className="btn btn-sm btn-info" disabled style={{width:'80px'}}>
                    Joined
                </button>
                : 
                    rejected
                    ?
                    <button className="btn btn-sm btn-info" disabled style={{width:'80px'}}>
                        Denied
                    </button>
                    :
                    <button className="btn btn-sm btn-info" style={{width:'80px'}} onClick={() => handleRequestAcceptReject('accept')}>
                        Accept
                    </button>
                }
            </div>
            { !requester.request_from.private ?
            <div className={classes.profilepostcmc}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={toggleDrawer('bottom', true)}
                    >
                    <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                </IconButton>
                <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                    {list('bottom')}
                </Drawer>                                        
            </div>
            : null }          
            <SlidingPane
                className={classes.postCreate}
                overlayClassName='ProfileTabsOverlay'
                isOpen={state.isPaneOpen}
                width='100%'
                title=""
                // from='bottom'
                onRequestClose={() => setState({ isPaneOpen: false })}
            >
                <ProfileTabs username={userProfile} sessionusr={sessionuser} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
            </SlidingPane>
        </div>
    )
}