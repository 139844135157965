import React, {useState, useEffect} from 'react';

// third party plugins
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';


//local
import { UrlProvider } from '../../lookup/prefixurl';
import { GroupRequests } from './grouprequests' ;
import { GroupMembers } from './groupmembers' ;
import { GroupAdmins } from './groupadmins' ;



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function GroupMemberTabs(props) {
  const {grpslug}    = props;
  const {prefixurl}   = props;
  const {sessionUserIsAdmin} = props;
  const [value, setValue] = useState(0);
  
  useEffect(()=>{
    if (prefixurl === undefined) {
      document.body.style.overflow = 'hidden';
    } 
    return () => (document.body.style.overflow = 'visible')
  }, [prefixurl])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // cleanup
  useEffect(() => {
    return () => {
    }
  })

  return (
    <div className='row'>
      <div className='col-lg-6 col-lg-offset-3'>
        <div style={{width:'100%'}}>
          <div className="panel space-4">
            <div>
                <div>
                    <AppBar position="static" style={{backgroundColor:'#172734'}} >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"  
                        TabIndicatorProps={{style: {backgroundColor: "#2d516f"}}}
                      >
                        {sessionUserIsAdmin
                        ?
                        <Tab 
                          sx = {theme => ({
                            '&.MuiTab-root' : {
                              textTransform: 'capitalize',
                              color: '#ffffff',
                              fontSize: '1.35rem',
                              [theme.breakpoints.up('md')]: {
                                minWidth: '120px',
                              },
                              '&:focus': {
                                opacity: 1,
                              },
                            }
                          })}
                          disableRipple
                          label="Requests" {...a11yProps(0)} />
                        : null
                        }
                
                        <Tab 
                          sx = {theme => ({
                            '&.MuiTab-root' : {
                              textTransform: 'capitalize',
                              color: '#ffffff',
                              fontSize: '1.35rem',
                              [theme.breakpoints.up('md')]: {
                                minWidth: '120px',
                              },
                              '&:focus': {
                                opacity: 1,
                              },
                            }
                          })}
                          disableRipple
                        label="Members" {...a11yProps(
                          sessionUserIsAdmin ? 1 : 0
                          )} />
                          
                        <Tab 
                          sx = {theme => ({
                            '&.MuiTab-root' : {
                              textTransform: 'capitalize',
                              color: '#ffffff',
                              fontSize: '1.35rem',
                              [theme.breakpoints.up('md')]: {
                                minWidth: '120px',
                              },
                              '&:focus': {
                                opacity: 1,
                              },
                            }
                          })}
                          disableRipple
                          label="Admins" {...a11yProps(
                          sessionUserIsAdmin ? 2 : 1
                          )} />
                      </Tabs>
                    </AppBar>
                  {prefixurl !== undefined
                    ? <React.Fragment> 
                        <UrlProvider value={prefixurl}>
                        {sessionUserIsAdmin
                          ?
                          <TabPanel value={value} index={0} >
                            <GroupRequests slug={grpslug} />
                          </TabPanel>
                          : null
                        }
                          <TabPanel value={value} index={
                            sessionUserIsAdmin ? 1 : 0
                            } >
                            <GroupMembers slug={grpslug} />
                          </TabPanel>
                          <TabPanel value={value} index={
                             sessionUserIsAdmin ? 2 : 1
                            } >
                            <GroupAdmins slug={grpslug} />
                          </TabPanel>
                        </UrlProvider>
                      </React.Fragment>
                  : <React.Fragment>
                      {sessionUserIsAdmin
                      ?
                      <TabPanel value={value} index={0} >
                        <GroupRequests slug={grpslug} />
                      </TabPanel>
                      : null
                      }
                      <TabPanel value={value} index={
                         sessionUserIsAdmin ? 1 : 0
                        } >
                        <GroupMembers slug={grpslug} />
                      </TabPanel>
                      <TabPanel value={value} index={
                         sessionUserIsAdmin ? 2 : 1
                        } >
                        <GroupAdmins slug={grpslug} />
                      </TabPanel>
                    </React.Fragment>}
                  </div>
            </div>
          </div>  
        </div>  
      </div> 
    </div>  
  );
}


export default GroupMemberTabs