import React, {useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Drawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";

// third party plugins
import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// local
import UrlContext from '../lookup/prefixurl'
import {apiBlogDelete, apiBookmarkPost } from '../lookup/lookup';
import {BlogDetail} from './detail'
import classes from './assets/style.module.css'


export function BookmarkInline(props) {
    const {blog}                = props
    const {onEdit}              = props
    const {onDelete}            = props
    const {onRemoveBookmark}    = props
    const {mainMenuListArray}   = props
    const {submenuListObj}      = props
    const {positionFix}         = props
    const {mBScrollPos}         = props
    const prefixurl                     = useContext(UrlContext)
    const [didLike, setDidLike]         = useState(false)
    const [stateDetail, setStateDetail] = useState({ isPaneOpenDetail: false })
    const [state, setState]             = useState({ bottom: false })
    
    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    
    const handleReadFlag = () => {}

    const handleActionBookmarkEvent = (response, status) => {
        if (status === 200) {
            onRemoveBookmark(blog.blogpost.slug)
        }
    }

    const handleBookmark = (event)=> {
        event.preventDefault()
        apiBookmarkPost(prefixurl, blog.blogpost.slug, handleActionBookmarkEvent)
    }

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {
            [ {width: 'auto'}]: anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  
             sx = { theme => ({
                '&.MuiList-padding' : {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                  }
            })}>
                <ListItem key={'bookmark'} onClick={handleBookmark}>
                <ListItemIcon>{<BookmarkIcon /> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>Remove Bookmark</Typography>
                </ListItem>
            </List>
        </div>
    );

    const handleActionDeleteEvent = (response, status) => {
        if (status === 204) {
            onDelete(blog.blogpost.id)
        }
    }

    const handleDelete = async (event) => {
        await apiBlogDelete(prefixurl, blog.blogpost.slug, handleActionDeleteEvent)
        setStateDetail({ isPaneOpenDetail: false })
    }

    const postEdited = (updatedPost) => {
        onEdit(updatedPost)
    }

    useEffect(()=>{
        if (didLike === false) { 
            setDidLike(true)
        }
    },[props, didLike, setDidLike])


    return (
      <div>
          {blog !== undefined ?
            <article className={classes.bookmarkcontainer} >
                <div className={classes.bookmarkicon} onClick={() => {setStateDetail({isPaneOpenDetail: true}) }}>
                    <BookmarkIcon htmlColor="#4AA6DE" />
                </div>
                {blog.blogpost.get_media_download_url ?
                    <div className={classes.bookmarkimg} onClick={() => {setStateDetail({isPaneOpenDetail: true}) }}>
                   
                        <div >
                            <img 
                                placeholder={<div style={{width:'80px', height:'40px', backgroundColor:'#dedede'}}></div>} 
                                src={blog.blogpost.get_sd_download_url}
                                alt='post' 
                                width='120px'
                            />
                        </div>
                    </div>
                    :  null
                }
                <div className={classes.bookmarkContent} onClick={() => {setStateDetail({isPaneOpenDetail: true}) }}>
                    <p className={classes.contentbody}>
                        <span className={classes.truncateltc}>
                            <span className={classes.bookmarklinecontent}>
                            {blog.blogpost.content}
                            </span>
                        </span>
                    </p>
                    <div className={classes.bookmarkName}>
                        <div className={classes._37iC3bl} >
                            <span>
                                <span className={classes.bookmarkcreated}>
                                { blog.blogpost.user.first_name 
                                    ? blog.blogpost.user.first_name + ' ' + blog.blogpost.user.last_name
                                    : blog.blogpost.user.username
                                    }
                                </span>
                                <span className={classes.authorct}>
                                    Saved{' '}{moment(blog.timestamp).fromNow()}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.bookmarkmenu}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={toggleDrawer('bottom', true)}
                        >
                        <MoreHorizIcon fontSize='large' htmlColor='#ffffff'/>
                    </IconButton>
                    <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                        {list('bottom')}
                    </Drawer>
                </div>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='editPostOverlay'
                    isOpen={stateDetail.isPaneOpenDetail}
                    width='100%'
                    title=""
                    from='right'
                    onRequestClose={() => 
                                        {   setStateDetail({ isPaneOpenDetail: false }) 
                                            positionFix(true)
                                            if (navbarElement != null) {
                                                navbarElement.style.display = 'block';
                                            }
                                        }
                                    }
                >
                    <div><BlogDetail slug={blog.blogpost.slug} 
                                     didDelete={handleDelete} 
                                     didPostEdit={postEdited} 
                                     readFlag={handleReadFlag}
                                     mainMenuList={mainMenuListArray} 
                                     submenuList={submenuListObj} 
                                     mainBodyScrollPos = {mBScrollPos}
                                     />
                    </div>
                </SlidingPane>
            </article> 
          : ""}
      </div>
    )
}