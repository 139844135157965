import React, {useContext, useEffect, useState} from 'react';

//third party 
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import TextField from '@mui/material/TextField';

//local
import UrlContext from '../../lookup/prefixurl'
import {apiGroupBlogReplyCreate, apiGroupBlogCommentReplyEdit} from '../../lookup/lookup';
import classes from '../assets/style.module.css';


export default React.forwardRef(function GroupBlogReplyCreateEdit(props, ref) {
    const {didReply}       = props
    const {blogSlug}       = props
    const {parentId}       = props
    const {repId}          = props
    const {uiDisplayname}  = props
    const {className}      = props
    const textAreaReplyRef = ref
    const prefixurl        = useContext(UrlContext)

    const [enableSendIcon, setEnableSendIcon]  = useState(false)
    const [inFocus, setInFocus]                = useState(false);

    var navbarElement = document.querySelector('.navbar-fixed-bottom');
    
    useEffect(() => {
        setEnableSendIcon(false)
    }, [props])

    const handleBackendCommentProcess = (response, status) => {
      if (status === 200 || status === 201) {
          let data = {
              "parentId":parentId,

              "repId":repId,
              "response":response
          }
        didReply(data)
        setEnableSendIcon(false)
      } else {
          alert("AN error occurred.")
      }
    }
    const handleChange = (event) => {
        event.preventDefault()
        setEnableSendIcon(true)
        let key = event.target.name
        let value = event.target.value.trim()
        if (key === 'reply'){
            if (value.length > 1000){
                value = value.substring(0,1000)
            }
            if (value.length === 0){
                setEnableSendIcon(false)
            } 
        }
    }

    const handleOnFocus = (event) => {
        if (navbarElement != null) {
            navbarElement.style.display = 'none';
            setInFocus(true);
        }
    }

    const handleBlur = (event) => {
        event.stopPropagation()
        if (event.relatedTarget === undefined || event.relatedTarget === null) {  // if Submit button is not clicked
            setTimeout(() => {
                textAreaReplyRef.current.value = ''
                setEnableSendIcon(false)
            }, 100);
         }
        if (navbarElement != null) {
            navbarElement.style.display = 'block';
            setInFocus(false);
        }
    }

    const handleCancel = () => {
        didReply('cancel')
        setEnableSendIcon(false)
    }

    const handleSubmit =(event) => {
        event.preventDefault()
        const newVal = textAreaReplyRef.current.value
        if (newVal.trim() !== '') {
            if (repId === null) {
                apiGroupBlogReplyCreate(prefixurl, newVal, blogSlug, parentId, handleBackendCommentProcess)
            } else {
                apiGroupBlogCommentReplyEdit(prefixurl, newVal, repId, handleBackendCommentProcess)
            }
            textAreaReplyRef.current.value = ''
            setEnableSendIcon(false)
        }
    }


    return (
        <div className={className}>
            <form key='reply' 
            // sx = {{
            //     '&.MuiTextField-root': {
            //         width: '100%',                   
            //         backgroundColor:'white',
            //         borderRadius:'5px'
            //     },
            //     '&.MuiOutlinedInput-multiline':{
            //         padding: '18.5px 40px 18.5px 10px',
            //     },
            //     '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            //         borderColor: "#EEEEEE"
            //     }
            // }}
            noValidate autoComplete="off" onSubmit={handleSubmit} onFocus={(event) => {handleOnFocus(event)}}>
                <div className={inFocus ? classes.replyWrapperInFocus : classes.replyWrapper}>
                <div className={classes.replyBox}>
                    <div className={classes.replyname}>
                            Replying to {uiDisplayname}
                            <IconButton onClick={handleCancel} style={{padding:'0px 0px 0px 20px'}}>
                                <CancelSharpIcon htmlColor='#ffffff' fontSize='small' />
                            </IconButton> 
                        </div>
                        <div className={classes.replyInput}>
                            <TextField
                                sx = {{
                                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#EEEEEE"
                                    },
                                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#EEEEEE"
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#EEEEEE"
                                    },
                                    "& .MuiOutlinedInput-multiline": {
                                        backgroundColor: "#FFFFFF"
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px 0 2px 0"
                                    },
                                }}
                                key='rep'
                                id="filled-textarea-reply"
                                inputRef={textAreaReplyRef}
                                placeholder='Reply' 
                                required={true} 
                                onChange={handleChange}
                                onBlur={(e) => {handleBlur(e)}}
                                name='reply'
                                multiline
                                maxRows={4}
                                fullWidth
                                // InputProps={{ disableUnderline: true }}
                                inputProps={{ style: 
                                                {
                                                    backgroundColor:'#eeeeee',
                                                    // padding:'1px 20px 1px 1px', 
                                                    fontSize: '12px', 
                                                    lineHeight: '20px', 
                                                    letterSpacing: '-0.32',
                                                    position:'absolute',
                                                    bottom:'-20px',
                                                    zIndex: '1',
                                                    borderRadius:'4px',
                                                    width: '-webkit-fill-available'

                                                }
                                            }}
                            />
                        </div>
                        <div className={classes.replyformbtn}>
                            {enableSendIcon ?
                                <IconButton type='submit'>
                                    <SendIcon fontSize='large' htmlColor='#4AA6DE' />
                                </IconButton> 
                                : 
                                null
                            }
                        </div>
                </div>
                </div>
            </form>
        </div> 
    )
})
