
import React, { useContext, useState, useEffect } from 'react';

//third party plugins
import Typography from "@mui/material/Typography";
import PullToRefresh from "react-simple-pull-to-refresh";


// local
import UrlContext from '../../lookup/prefixurl'
import { apiUserProfileBlocked } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import {ProfileBlockedInline} from './profileblockedinline';


export function ProfileBlocked(props) {
    const prefixurl  = useContext(UrlContext)
    const [usersBlocked, setUsersBlocked] = useState(null)
    const [doneLoading, setDoneLoading] = useState(false)

    useEffect(() => {
        apiUserProfileBlocked(prefixurl, props.username, handleUsersBlocked)
    }, [prefixurl, props])
    
    const handleRefreshc = () => {
        apiUserProfileBlocked(prefixurl, props.username, handleUsersBlocked)
        return new Promise((resolve) => {
            setTimeout(resolve, 1000)
        });
    };


    const handleUsersBlocked = (response, status) => {
        if (status === 200){
            setUsersBlocked(response.blocked)
            setDoneLoading(true)
        }
    }

    const handleUnblocked = (response) => {
        const filteredUsersBlocked = ([...usersBlocked].filter(userblocked => userblocked.username !== response.username))
        setUsersBlocked(filteredUsersBlocked)
    }

    return (
        <div>
            {(doneLoading === true) 
            ? 
            <div>
            <PullToRefresh
                onRefresh={handleRefreshc}
                pullingContent = {false}
            >
                <div>
                    <div className={classes.bndhdr}>
                        <Typography style={{ fontSize: '1em', color: '#ffffff' }}>
                            {/* Blocked{' '}
                            {usersBlocked.blocked.length > 1 ? usersBlocked.blocked.length + ' people' : 
                                usersBlocked.blocked.length === 1 ? usersBlocked.blocked.length + ' person' : 
                                usersBlocked.blocked.length === 0 ? 'none' : null
                            }  */}
                            Once a person is blocked, they can can no longer see your posts, view your profile or see the locations you are following. They will also not be able to follow you. 
                        </Typography>
                    </div>
                    {usersBlocked && usersBlocked.map((blockedItem, i) => {
                        return (
                                <div key={i} className={classes.echdiv1}>
                                    <ProfileBlockedInline userblocked={blockedItem} userUnblocked={handleUnblocked} />
                                </div>
                                )
                    })}
                </div>
            </PullToRefresh>
            </div> 
             : 
               null
            }
        </div>
    )
}