import React, { useContext, useState } from 'react';

// Third Party
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

// Local 
import {apiSearchList} from '../../lookup/lookup';
import UrlContext from '../../lookup/prefixurl';
import {SearchInline} from './searchinline';
import {useLockBodyScroll} from '../utilities';
import {VisualViewport}  from '../utilities';




export function SearchForm(props) {
    const prefixurl = useContext(UrlContext)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchList, setSearchList] = useState([])
    
    useLockBodyScroll();

    var navbarElement   = document.querySelector('.navbar-fixed-bottom');


    const handleFocus = (event) => {
        if (navbarElement != null) {
            navbarElement.style.display = 'none';
        }
    };

    const handleBlur = (event) => {
        event.stopPropagation()
        if (navbarElement != null) {
            navbarElement.style.display = 'block';
        }
    };


    const handleClickClear = () => {
        setSearchTerm('');
    };
    
    const handleMouseDownSearch = (event) => {
        event.preventDefault();
    };

    const handleSearchListLookup = (response, status) => {
        if (status === 200){
            setSearchList(response)
        }
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value)
        if (event.target.value.trim() !== '') {
            let searchText = event.target.value.trim()
            apiSearchList(prefixurl, searchText,  handleSearchListLookup)
        }
    };
    

    return (
        <VisualViewport>
        <div className='row' style={{height:'100%', overflowY:'scroll'}}  >
            <div className='col-sm-6 col-sm-offset-3'>
                <div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop:'20px', paddingBottom:'20px', borderBottom: 'solid 1px #182734'}}>
                        <FormControl 

                            sx={{ 
                                m: 1, width: '15ch',
                                    '& .MuiOutlinedInput-root': {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ffffff"
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ffffff"
                                        }
                                    }
                                }} 
                            variant="outlined" style={{minWidth:'300px', backgroundColor:"#ffffff"}}>
                            <OutlinedInput
                                id="outlined-adornment-search"
                                type={'text'}
                                autoComplete="off"
                                value={searchTerm}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                style={{fontSize:'1.3rem', color:'#182734'}}
                                placeholder='Search people...'
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickClear}
                                    onMouseDown={handleMouseDownSearch}
                                    edge="end"
                                    >
                                    {searchTerm.length > 0
                                    ?
                                    <ClearIcon fontSize='small' disable='false' htmlColor='#9e9e9e' />
                                    : 
                                    <SearchIcon fontSize='large' disable='true' htmlColor='#9e9e9e' />
                                    }
                                    </IconButton>

                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div>
                    {searchList.length > 0 
                    ?   searchList.map((searchListItem, index)=>{
                            return (
                                <div key={index}>
                                    <SearchInline 
                                        searchItem={searchListItem}
                                    />
                                </div>
                            )
                        })
                    :   null
                    }
                    </div>
                    <div style={{'marginBottom':'50px', position:'fixed', bottom:'0', right:'0', left:'0'}}></div>
                </div>
            </div>
        </div>
        </VisualViewport>
    );
}

