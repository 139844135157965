import React, {useContext, useState, useEffect } from 'react';

import Typography from "@mui/material/Typography";


// local
import {GroupInline} from './groupinline';
import {apiProfileGroupListAll} from '../../lookup/lookup';
import UrlContext from '../../lookup/prefixurl'
import classes from '../assets/style.module.css'
import {VisualViewport, useLockBodyScroll}  from '../utilities';



export function GroupListAll(props) {
    const {onGroupEditY}                  = props;
    const {onLeftY}                       = props;
    const {onDeleteY}                     = props;
    const prefixurl                       = useContext(UrlContext);
    const [totalGroups, setTotalGroups]   = useState(null);
    const [scrollToPos, setScrollToPos]   = useState(0);
    const [nextUrl, setNextUrl]           = useState(null)
    const [groupItems, setGroupItems]     = useState(false)
    const [groups, setGroups]             = useState([])
    const [refreshGroup, setRefreshGroup] = useState(false)
    const [name, setName]                 = useState(null)
    const [doneLoading, setDoneLoading]   = useState(false)

    let newIndex     = null;
    useLockBodyScroll();

    const handleScroll = (e) =>{
        console.log("handleScroll")
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreGroups()
        }
    }

    const handlePosFixed = (event) => {
        window.scrollTo(0, scrollToPos);
    }

    useEffect(()=> {
        if (refreshGroup === false) {
            setRefreshGroup(true)
        }
    },[refreshGroup, setRefreshGroup])


    
    useEffect(() => {
        if (groupItems === false){
            setGroupItems(!groupItems)
            const handleProfileGroupListEvent = (response, status) => {
                if (status === 200) {
                    const newGroups = response.results
                    setTotalGroups(response.totalGroupCount)
                    setName(
                            response.group_requested_for_firstname 
                            ? response.group_requested_for_firstname + "'s Groups"
                            : response.group_requested_for_firstname + "'s Groups" 
                            )
                    setGroups(newGroups)
                    setNextUrl(response.next)
                    setGroupItems(true)
                    setDoneLoading(true)
                }
            }
            apiProfileGroupListAll(prefixurl, props.username, handleProfileGroupListEvent)
        }
    }, [groupItems, setGroupItems, prefixurl, props.username])

    const loadMoreGroups = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newGroups = [...groups].concat(response.results)
                    setGroups(newGroups)
                } else {
                  console.log("There was an error")
                }
            }
            apiProfileGroupListAll(prefixurl, props.username, handleLoadNextResponse, nextUrl)
        }
    }


    const handleGroupEdit = async (response) => {
        const editedGroups = ([...groups])
        newIndex = editedGroups.findIndex(group => group.group.slug === response.slug)
        if (newIndex >= 0) {
            editedGroups[newIndex].group = response
            setGroups(editedGroups)
            setRefreshGroup(!refreshGroup)
        }
        onGroupEditY(response)
    }
 

    const handleDelete = (itemDeleted) => {
        const editedGroups = ([...groups])
        newIndex = editedGroups.findIndex(group => group.group.slug === itemDeleted)
        editedGroups.splice(newIndex, 1)
        setGroups(editedGroups)
        setTotalGroups(totalGroups - 1)
        onDeleteY(itemDeleted)
    }

    const handleGroupLeft = (itemLeft) => {
        const editedGroups = ([...groups])
        newIndex = editedGroups.findIndex(group => group.group.slug === itemLeft)
        editedGroups.splice(newIndex, 1)
        setGroups(editedGroups)
        setTotalGroups(totalGroups - 1)
        onLeftY(itemLeft)
    }


    return (
        <VisualViewport>
        <div>
            { doneLoading
            ?
                <div className="row">
                    <div className='col-lg-6 col-lg-offset-3' style={{backgroundColor: "#0d171f", marginBottom: "50px", overflowY:"auto"}}>
                        <div className={classes.mnblknoscroll} onScroll={handleScroll} style={{marginTop:"20px", height:"800px"}}>
                            <div style={{'display':'flex', marginBottom:"6px"}}>
                                <div style={{width:'100%', padding:'10px 10px 3px 10px'}}>
                                    <Typography color="textSecondary" align='left' gutterBottom={true} style={{color:'#ffffff', float:'left', fontSize:'1.2rem'}}> 
                                        {name} 
                                    </Typography>
                                    <Typography color="textSecondary"  gutterBottom={true} style={{color:'#ffffff', float:'right', fontSize:'1.2rem'}}> 
                                        Total ({totalGroups})
                                    </Typography>
                                </div>
                            </div>
                            {/* <div style={{height:"200px"}}>
                            </div> */}
                            <div className={classes.blogMainPost} style={{marginTop:"20px"}}  >
                                {groups.length > 0 
                                    ? groups.map((groupItem, index)=>{
                                            return (
                                                <div key={index}>
                                                    <GroupInline 
                                                        group={groupItem} 
                                                        onGroupEdit={handleGroupEdit}
                                                        onLeft={handleGroupLeft} 
                                                        onDelete={handleDelete} 
                                                        positionFix = {handlePosFixed} 
                                                        mBScrollPos = { (ypos) =>  { setScrollToPos(ypos)}   }
                                                    />
                                                </div>
                                                )
                                        }) 
                                    :   null
                                }
                                <div style={{marginBottom:'50px'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            : null}
        </div>
        </VisualViewport>
     )
}
