import React, {useContext, useState, useEffect, useRef} from 'react';
// import {withRouter} from 'react-router-dom';
import clsx from 'clsx';


// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlagIcon from '@mui/icons-material/Flag';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { blue } from '@mui/material/colors';


// Third party plugins
import moment from 'moment';
import { Player, ControlBar, BigPlayButton, VolumeMenuButton } from 'video-react';
// import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import { Gallery, Item } from 'react-photoswipe-gallery'
import LazyLoad from 'react-lazy-load';
import SlidingPane from "react-sliding-pane";
import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'
import 'video-react/dist/video-react.css';
import 'react-sliding-pane/dist/react-sliding-pane.css'
import '../assets/customSliding.css'

// local
import UrlContext from '../../lookup/prefixurl'
import {apiGroupBlogDetail, apiGroupUserReportsType} from '../../lookup/lookup';
import ProfileTabs from '../profile/profile';
import {GroupUserLikes} from './groupuserlikes';
import {GroupUserCommentOrReplyLikes} from './groupusercommentlikes';
import {ActionBtn, CommentLikeBtn, ReplyLikeBtn, CommentReplyDeleteAction} from './groupblogbuttons';
import GroupBlogReplyCreateEdit from './groupblogreplyprocess';
import GroupBlogCommentCreateEdit from './groupblogcommentcreate';
import {verifyImage, verifyVideo, ImageLoading, VisualViewport, useLockBodyScroll} from '../utilities';
import { GroupBlogForm } from './groupblogform';
import classes from '../assets/style.module.css';
import Placeholder from '../assets/images/placeholder.png';


const textStyleTitle = {
    color: "#060b10",
    fontWeight: 900,
    fontSize: "1.1em"
};

const textStyle = {
    color: "#060b10",
    fontSize: "1.1em"
};



export function GroupBlogDetail(props) {
    const {didDelete}                         = props;
    const {didPostEdit}                       = props;
    const {slug}                              = props;
    const {readFlag}                          = props;
    const {mainMenuList}                      = props;
    const {submenuList}                       = props;
    const {mainBodyScrollPos}                 = props;
    const prefixurl                           = useContext(UrlContext);
    const [sessionUser, setSessionUser]       = useState();
    const [userProfile, setUserProfile]       = useState(null);
    const [userProfilePrivacyFlag, 
            setUserProfilePrivacyFlag]        = useState(false);
    const [userProfileBlockedFlag, 
            setUserProfileBlockedFlag]        = useState(false);
    const [blog, setBlog]                     = useState(null);
    const [blogDoesNotExist, 
            setBlogDoesNotExist]              = useState(false);
    const [doneLoading, setDoneLoading]       = useState(false);
    const [didEdit, setdidEdit]               = useState(false);
    const [dOpen, setDOpen]                   = useState(false);
    const [comm, setComm]                     = useState(null);
    const [parentId, setParentId]             = useState(null);
    const [commReplyId, setCommReplyId]       = useState(null);
    const [repId, setRepId]                   = useState(null);
    const [uiDisplayname, setUiDisplayname]   = useState(null)
    const [showCommBox, setShowCommBox]       = useState(true);
    const [showReplyBox, setShowReplyBox]     = useState(false);
    const [commPrevPage, setCommPrevPage]     = useState(null);
    const [newComment, setNewComment]         = useState(false);
    const [newReply, setNewReply]             = useState(false);
    const [imgLoadingUrl, setImgLoadingUrl]   = useState(null);
    const inputCommRef                        = useRef();
    const inputReplyRef                       = useRef();
    const commRef                             = useRef();
    const replyRef                            = useRef();
    const [refreshBlog, setRefreshBlog]       = useState(false);
    const [loaded, setLoaded]                 = useState(false);
    const [commImgloaded, setCommImgLoaded]   = useState(false);
    const [menuName, setMenuName]             = useState(null);
    const [confirmpg, setconfirmpg]           = useState(null);
    const [menuInBetween, setMenuInBetween]   = useState(null);
    const [stateReportMenu, 
            setStateReportMenu]               = useState({ bottom: false })
    const [reportSubmittedFlag, 
            setReportSubmittedFlag]           = useState(false)
    const [commentReplyId, setCommentReplyId] = useState(null)

    const [state, setState]                   = useState({isPanePostOpen: false})
    const [avatarState, setAvatarState]       = useState({ isPaneOpenUsr: false })
    const [stateUserlikes, setStateUserlikes] = useState({ isPaneOpenLikes: false })
    const [stateCommentUserlikes, 
            setStateCommentUserlikes]         = useState({ isPaneOpenCommentLikes: false })
    const [stateMenu, setStateMenu]           = useState({ bottom: false })

    const bull           = <span sx = {{
                                        display: 'inline-block',
                                        margin: '0 12px',
                                        transform: 'scale(0.8)',
                                }}
                             >•</span>;
    const imageStyle     = !loaded ? { display: "none" } : {};
    const commImageStyle = !commImgloaded ? { display: "none" } : {};

    let murl           = null;
    let surl           = null;
    let lMediaType     = null;
    let newIndex       = null;
    
    useLockBodyScroll();

    const options = {
        arrowPrev: false,
        arrowNext: false,
        zoom: false,
        close: true,
        counter: false,
        bgOpacity: 1,
    }

    const handleImageLoaded = () => {
        setLoaded(true)
    }

    const handleCommImageLoaded = () => {
        setCommImgLoaded(true)
    }

    const isInView = (el) => {
        let box = el.getBoundingClientRect();
        return box.top < window.innerHeight && box.bottom >= 0;
    }
    
    const handleImgLoadingUrl = (url) => {
        setImgLoadingUrl(url)
    }

    const handleBlogDetailLookup = (response, status) => {
        if (status === 200){
            setDoneLoading(true)
            setSessionUser(response.sessionUser)
            if (response.comment_total_pages > 1 ) {
                if (response.comment_current_page > 1) {
                    setCommPrevPage(response.comment_current_page - 1)
                } else {
                    setCommPrevPage(null)
                }
            }
            setBlog(response)
        } else {
            setBlogDoesNotExist(true)
        }
    }
    
    useEffect(() => {
        if (didEdit === false){
            setDoneLoading(true)
            setdidEdit(true)
            apiGroupBlogDetail(prefixurl, slug, handleBlogDetailLookup)
            readFlag(true)
            ImageLoading(prefixurl, handleImgLoadingUrl)
            mainBodyScrollPos(window.pageYOffset)
        }
    }, [props, prefixurl, slug, didEdit, setdidEdit, readFlag, mainBodyScrollPos])

    
    useEffect(()=> {
        if (refreshBlog === false) {
            setRefreshBlog(true)
        }
    },[refreshBlog, setRefreshBlog])

    useEffect(()=> {
        if (newComment === false) {
            if (commRef.current) {
                commRef.current.scrollIntoView({ block: "end", inline: "nearest" });
            }
            setNewComment(true)
        }
    },[newComment, setNewComment])

    useEffect(()=> {
        if (newReply === false) {
            if (replyRef.current !== undefined){
                var inView = isInView(replyRef.current)
                if (inView === false) {
                    replyRef.current.scrollIntoView({ block: "end", inline: "nearest" });
                }
            }
            setNewReply(true)
        }

    },[newReply, setNewReply])


    const handlePostEdit   = () => { setState({ isPanePostOpen: true }) }
    const handleDialogOpen = () => { setDOpen(true)};
    const handleClose      = () => {setDOpen(false)};

    const handleAvatar = (username, privacyFlag, blockedFlag)=>{
        setUserProfile(username)
        if (username === blog.sessionUser) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(privacyFlag)
            setUserProfileBlockedFlag(blockedFlag)
        }
        setAvatarState({ isPaneOpenUsr: true })
    }

    const handleBlogDetailAddComments = async (response, status) => {
        if (status === 200){
            if (response.comment_total_pages > 1 ) {
                if (response.comment_current_page > 1) {
                    setCommPrevPage(response.comment_current_page - 1)
                } else {
                    setCommPrevPage(null)
                }
            }
            const editedBlog = blog
            editedBlog.comment_current_page = response.comment_current_page
            editedBlog.comments = [ ...response.comments, ...editedBlog.comments]
            setBlog(editedBlog)
            setRefreshBlog(!refreshBlog)
        }

    }

    const handlePreviousComments = () => {
        apiGroupBlogDetail(prefixurl, slug, handleBlogDetailAddComments, commPrevPage)
    }

    const handleBlogLike = async (response) => {
        blog.likes = response.likes
        blog.liked = response.liked
        const editedBlog = blog
        await didPostEdit(editedBlog)
        setRefreshBlog(!refreshBlog)

    }

    // POST ReportType submitted by user.
    const handleReportSubmission =(reportTypeText) => {
        const handleReportSubmitted = (response, status) => {
            if (status === 201) {
                setReportSubmittedFlag(!reportSubmittedFlag)
            }
        }
        apiGroupUserReportsType(prefixurl, blog.slug, commentReplyId, reportTypeText, handleReportSubmitted)
    }

    const handleCommentLike = async (data) => {
        const editedBlog = blog
        const index = editedBlog.comments.findIndex(comment => comment.id === data.commentId)
        editedBlog.comments[index].likes = data.likes
        setBlog(editedBlog)
        setRefreshBlog(!refreshBlog)
    }
    const handleReplyLike = async (data) => {
        const editedBlog = blog
        editedBlog.comments[data.commentIndex].replies[data.index].likes = data.likes
        setBlog(editedBlog)
        setRefreshBlog(!refreshBlog)
    }

    const handleCommentProcessAll = async (data) => {
        await handleCommentProcessMain(data)
        if (murl) { // if post processing of video is needed
            await handleCommentPostProcessVideo()
        }
        //clear variables
        murl = null;
        surl = null;
        lMediaType = null
        newIndex = null;
    }

    const handleCommentProcessMain = async (data) => {
        const editedBlog = blog
        murl             = data.response.get_media_download_url
        surl             = data.response.get_sd_download_url
        lMediaType       = data.response.mediatype
        if (data.commId !== null) {
            newIndex = editedBlog.comments.findIndex(comment => comment.id === data.commId)
            editedBlog.comments[newIndex] = data.response

            if (data.response.get_media_download_url) {
                editedBlog.comments[newIndex].get_media_download_url = imgLoadingUrl
                editedBlog.comments[newIndex].get_sd_download_url = imgLoadingUrl
                editedBlog.comments[newIndex].mediatype = 'image/gif'
                setBlog(editedBlog); 
            } 
        } else {
            //New Comment => push with valid ( processed from AWS S3) media.
            if (data.response.get_media_download_url) {
                let commLength = editedBlog.comments.push(data.response)
                editedBlog.comments_total  = editedBlog.comments_total + 1
                newIndex = commLength - 1

                editedBlog.comments[newIndex].get_sd_download_url = imgLoadingUrl
                editedBlog.comments[newIndex].get_media_download_url = imgLoadingUrl
                editedBlog.comments[newIndex].mediatype = 'image/gif'
                setBlog(editedBlog);                                   
            } else {   //New Comment => if media does not exist
                editedBlog.comments.push(data.response)
                editedBlog.comments_total  = editedBlog.comments_total + 1
            }
            setNewComment(!newComment)
        }
        setBlog(editedBlog);
        setComm(null);
        didPostEdit(editedBlog) 
    }

    const handleCommentPostProcessVideo = async () => {
        const editedBlog = blog
        const handleDelayedMedialoadPushed = (loaded) => {
            if (loaded) {
                editedBlog.comments[newIndex].get_sd_download_url = surl
                editedBlog.comments[newIndex].get_media_download_url = murl
                editedBlog.comments[newIndex].mediatype = lMediaType
            } 
        }
        if (lMediaType.slice(0,6) === "video/") {
            await verifyVideo(murl, handleDelayedMedialoadPushed)
        } else {
            await verifyImage(murl, handleDelayedMedialoadPushed)
        }
        setBlog(editedBlog);
        didPostEdit(editedBlog) 
    }

    const handleReplyProcess = async (data) => {
        if (data !== 'cancel') {
            const editedBlog = blog
            if (data.repId === null) {   //new Reply
                const newReply = data.response
                const index = editedBlog.comments.findIndex(comment => comment.id === data.parentId)
                editedBlog.comments[index].replies.push(newReply)
                setNewReply(!newReply)
            } else {                    //edited Reply
                const editedReply = data.response
                const index = editedBlog.comments.findIndex(comment => comment.id === data.parentId)
                const replyindex = editedBlog.comments[index].replies.findIndex(reply => reply.id === data.repId)
                editedBlog.comments[index].replies[replyindex] = editedReply
            }
            setBlog(editedBlog);
            setComm(null);
            setParentId(null);
            setRepId(null);
            setShowReplyBox(false)
            setShowCommBox(true)
            setRefreshBlog(!refreshBlog)
        } else {
            setComm(null);
            setParentId(null);
            setRepId(null);
            setShowReplyBox(false)
            setShowCommBox(true)
            setRefreshBlog(!refreshBlog)
        }
        setTimeout(() => {
            setShowCommBox(true)
            setRefreshBlog(!refreshBlog)
        },200);
        
    }
    
    const handleCommReset = (event) => {
        setComm(null)
        setParentId(null);
        setRepId(null);
        setShowCommBox(true)
        setShowReplyBox(false)
        inputCommRef.current.value='';
        setRefreshBlog(!refreshBlog)
    }

    
    const handleReplyFocus = (e, ParentId, RepId, ui_displayname) => {
        e.stopPropagation()
        setComm(null)
        setParentId(ParentId);
        setRepId(RepId);
        setUiDisplayname(ui_displayname);
        setShowCommBox(false)
        setShowReplyBox(true)
        inputCommRef.current.value='';
        if (!RepId) {
            inputReplyRef.current.value='';
        }  
    }
    

    const handleBlogDelete = (event) => {
        event.preventDefault()
        setDOpen(false)
        didDelete(true)
    }

    const handleCommentDelete = (data) =>{
        if (data.status === 204) {
            const editedBlog = blog
            const toFilterBlog = editedBlog
            editedBlog.comments = toFilterBlog.comments.filter(comment => comment.id !== data.pk)
            editedBlog.comments_total = editedBlog.comments_total - 1
            setBlog(editedBlog)
            didPostEdit(editedBlog)
            setRefreshBlog(!refreshBlog) 
        }
    }
    const handleReplyDelete = (data) => {
        const editedBlog = blog
        const toFilterBlog = editedBlog
        const index = editedBlog.comments.findIndex(comment => comment.id === data.parentId)
        editedBlog.comments[index].replies = toFilterBlog.comments[index].replies.filter(reply => reply.id !== data.pk)
        setBlog(editedBlog)
        setRefreshBlog(!refreshBlog)
    }
    
    const handleMediaProcess = async (response) => {
        const editedBlog = blog
        const handleDelayedMedia = (loaded) => {
            if (loaded) {
                editedBlog.get_sd_download_url = surl
                editedBlog.get_media_download_url = murl
                editedBlog.mediatype = lMediaType
            } 
        }
        if (lMediaType.slice(0,6) === "video/") {
            await verifyVideo(murl, handleDelayedMedia)
        } else {
            await verifyImage(murl, handleDelayedMedia)
        }
        setBlog(editedBlog)
        //clear variables
        murl        = null;
        surl        = null;
        lMediaType  = null;
    }

    const formSubmit = async (updatedBlog) => {
        setState({ isPanePostOpen: false })
        const editedBlog = blog
        const orig_media = editedBlog.mediapath

        if (updatedBlog.get_media_download_url) {
            if (orig_media !== updatedBlog.mediapath) {  // if media file has changed
                murl       = updatedBlog.get_media_download_url
                surl       = updatedBlog.get_sd_download_url
                lMediaType = updatedBlog.mediatype
                editedBlog.content = updatedBlog.content
                editedBlog.get_media_download_url = imgLoadingUrl
                editedBlog.get_sd_download_url = imgLoadingUrl
                editedBlog.mediatype = 'image/gif'
                setBlog(editedBlog);
                setTimeout( async () => {
                    await handleMediaProcess(updatedBlog)
                }, 500);
            } else {  // if media file has not changed
                editedBlog.content = updatedBlog.content
                setBlog(editedBlog)
            }
        } else { // if there is no media file
            editedBlog.content = updatedBlog.content
            editedBlog.get_hd_download_url = null
            editedBlog.get_sd_download_url = null
            editedBlog.get_media_download_url = null
            editedBlog.medianame = null
            editedBlog.mediapath = null
            editedBlog.mediatype = null
            setBlog(editedBlog)
        }
        didPostEdit(updatedBlog) // this is to send the updated post to the list page
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStateMenu({ ...stateMenu, [anchor]: open });
    };

    // Drawer for User reporting of Posts / Comments / Replies
    const toggleReportDrawer = (anchor, open, crType, crID) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        } 
        crType === 'P' ? setCommentReplyId(null) : setCommentReplyId(crID)
        setStateReportMenu({ ...stateReportMenu, [anchor]: open }); 
        setMenuName(null)
        setconfirmpg(null)
        setReportSubmittedFlag(false)
    };

    // start report type
    const reporttype = anchor => {
        const confirmpage = ["Submit"]
        let arr = menuName ? submenuList[menuName] : confirmpg ? confirmpage : mainMenuList ;

        const clickListener = text => {
          if (!menuName) {
              if (!confirmpg) {
                setMenuInBetween(text)  
                return setMenuName(text);
              } else {
                return setconfirmpg(text);
              }
          } else {
            setMenuName(null) 
            return setconfirmpg(text)
          }
        };

        return (
          <div
            className={classes.list}
            role="presentation"
            onKeyDown={toggleReportDrawer(anchor, false,"","")}
          >
            <List>
                <ListItem className="text-center">
                {!menuName && !confirmpg && <ListItemText primaryTypographyProps={{ style: textStyleTitle }} primary="Choose a reason to report"  /> } 
                </ListItem>
            </List>
            <List sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
              {arr.map((text, index) => (
                  <div key={text} >
                      {confirmpg && 
                            <Card sx={{ minWidth: 275, minHeight: 300 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                    You are reporting on
                                    </Typography>
                                    <br/>
                                    <Divider />
                                    <br/>
                                    <Typography style={{ fontSize: '1.3rem' }}  gutterBottom>
                                    {menuInBetween}
                                    </Typography>
                                    <Typography style={{ fontSize: '1.3rem' }} gutterBottom>
                                    {bull}{confirmpg}
                                    </Typography>
                                    <br/>
                                    <Divider />
                                    <br/>
                                    <Typography style={{ fontSize: '1.3rem' }}  gutterBottom>
                                        OOima will review your submission and take appropriate action.
                                    </Typography>
                                </CardContent>

                                <div className="text-center">
                                    <br/>
                                    {!reportSubmittedFlag 
                                        ?<Button 
                                        sx = {theme => ({
                                            '&..MuiButton-root':{
                                                color: theme.palette.getContrastText(blue[600]),
                                                backgroundColor: blue[600],
                                                '&:hover': {
                                                backgroundColor: blue[800],
                                                }
                                            }
                                        })}
                                        variant="contained"  onClick={() => handleReportSubmission(confirmpg)}>Submit</Button>
                                        :<Button variant="contained" disabled>Submitted !</Button>                                        
                                    }
                                <br/>
                                </div>
                                <br/>
                            </Card>
                            }  
                        {!confirmpg &&            
                            <ListItem key={text} 
                                    onClick={() => clickListener(text)}>
                                <ListItemText primaryTypographyProps={{ style: textStyle }} primary={text} />
                                <ChevronRightIcon style={{fontSize:'2rem'}}/>
                            </ListItem>
                        }
                        <Divider />
                </div>
              ))}
            </List>
          </div>
        );
      };
    // end report type

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                {sessionUser === blog.user.username ?
                    <ListItem button key={'edit'} onClick={handlePostEdit}>
                        <ListItemIcon>{<EditIcon fontSize='large' /> }</ListItemIcon>
                        <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Edit Post'} />
                    </ListItem>
                : null}
                {sessionUser === blog.user.username ?
                    <ListItem button key={'delete'} onClick={handleDialogOpen} >
                        <ListItemIcon>{<DeleteForeverIcon fontSize='large' /> }</ListItemIcon>
                        <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Delete Post'} />
                    </ListItem>
                : null}
                {sessionUser !== blog.user.username ?
                    <ListItem button key={blog.id} onClick = {toggleReportDrawer('bottom', true,'P', blog.slug)} >
                        <ListItemIcon>{<FlagIcon fontSize='large' /> }</ListItemIcon>
                        <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }} primary={'Report Post'}/>
                    </ListItem>
                : null}
            </List>
        </div>
    );


    const IsolatedCommMenu = props => {
        const [dCommOpen, setdCommOpen] = React.useState(false);
        const [stateCommMenu, setStateCommMenu] = useState({ bottom: false })

        const toggleCommDrawer = (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setStateCommMenu({ ...stateCommMenu, [anchor]: open });
        };
        
        const commlist = (anchor) => (
            <div
                key={props.comment.id}
                className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
                role="presentation"
                onClick={toggleCommDrawer(anchor, false)}
                onKeyDown={toggleCommDrawer(anchor, false)}
            >
                <List sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                    {sessionUser === props.comment.user.username?
                        <ListItem button key={'edit'}
                                    onClick={() => {
                                        setdCommOpen(false);
                                        setComm(props.comment);
                                        inputCommRef.current.value = props.comment.content;
                                        setTimeout(() => {
                                            inputCommRef.current.focus();
                                        }, 500);
                                    }} 
                        >
                            <ListItemIcon>{<EditIcon fontSize='large' /> }</ListItemIcon>
                            <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Edit Comment'} />
                        </ListItem>
                    : null}
                     {sessionUser === props.comment.user.username ?
                        <ListItem button key={'delete'} onClick={()=> { setdCommOpen(true) }}>
                            <ListItemIcon>{<DeleteForeverIcon fontSize='large' /> }</ListItemIcon>
                            <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Delete'} />
                        </ListItem>
                    : null}
                    {sessionUser !== props.comment.user.username ?
                        <ListItem button key={props.comment.id} onClick={toggleReportDrawer('bottom', true, 'C', props.comment.id)} >
                            <ListItemIcon>{<FlagIcon fontSize='large' /> }</ListItemIcon>
                            <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Report Comment'} />
                        </ListItem>
                    : null}
                </List>
            </div>
        );

        return(
            <div className={classes.postcmc} key={props.comment.id} >
            <React.Fragment>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={toggleCommDrawer('bottom', true)}
                >
                <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                </IconButton>
                <Drawer anchor={'bottom'} open={stateCommMenu['bottom']} onClose={toggleCommDrawer('bottom', false)}>
                    {commlist('bottom', props)}
                </Drawer>
            </React.Fragment>
            
            <Dialog
                key={props.comment.id}
                open={dCommOpen}
                onClose={()=> {
                    setdCommOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                    Do you want to Delete the Comment ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=> {setdCommOpen(false)}} 
                        color="primary"
                        style={{fontSize:"1.35rem"}}
                >
                    No
                </Button>
                <Button
                    key={props.comment.id}
                    onClick= {() => {
                        setdCommOpen(false);
                        CommentReplyDeleteAction(prefixurl, '',props.comment.id,handleCommentDelete);
                        handleCommReset()
                    }} 
                    color="primary" autoFocus
                    style={{fontSize:"1.35rem"}}>  
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        )
    }

    const IsolatedReplyMenu = props => {
        const [dReplyOpen, setdReplyOpen] = useState(false); 
        const [stateReplyMenu, setStateReplyMenu] = useState({ bottom: false })
        const toggleReplyDrawer = (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setStateReplyMenu({ ...stateReplyMenu, [anchor]: open });
        };
    
        const replylist = (anchor) => (
            <div
                className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
                role="presentation"
                onClick={toggleReplyDrawer(anchor, false)}
                onKeyDown={toggleReplyDrawer(anchor, false)}
            >
                <List sx = {theme => ({
                            '&.MuiList-padding': {
                                paddingBottom: '66px',
                                [theme.breakpoints.up('md')]: {
                                paddingBottom: '0px',
                                },
                            }
                        })}>
                    {sessionUser === props.reply.user.username
                        ?
                            <ListItem button key={'edit'}
                                        onClick={(e) => {
                                            setdReplyOpen(false);
                                            setParentId(props.comment.id);
                                            setRepId(props.reply.id);
                                            inputReplyRef.current.value = props.reply.content;
                                            handleReplyFocus(e, props.comment.id, props.reply.id, props.comment.user.ui_displayname);
                                        }}
                            >
                                <ListItemIcon>{<EditIcon fontSize='large' /> }</ListItemIcon>
                                <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Edit Reply'} />
                            </ListItem>
                        : null
                    }
                     {sessionUser === props.reply.user.username 
                        ?
                            <ListItem button key={'delete'} onClick={()=> { setdReplyOpen(true) }}>
                                <ListItemIcon>{<DeleteForeverIcon fontSize='large' /> }</ListItemIcon>
                                <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Delete'} />
                            </ListItem>
                        : null
                    }
                    {sessionUser !== props.reply.user.username 
                        ?
                            <ListItem button key={props.reply.id} onClick={toggleReportDrawer('bottom', true, 'R', props.reply.id)}>
                                <ListItemIcon>{<FlagIcon fontSize='large' /> }</ListItemIcon>
                                <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Report Reply'} />
                            </ListItem>
                        : null
                    }
                </List>
            </div>
        );

        return(
            <div className={classes.postcmc}>
            <React.Fragment>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={toggleReplyDrawer('bottom', true)}
                >
                <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                </IconButton>
                <Drawer anchor={'bottom'} open={stateReplyMenu['bottom']} onClose={toggleReplyDrawer('bottom', false)}>
                    {replylist('bottom', props)}
                </Drawer>
            </React.Fragment>
            
            <Dialog
                open={dReplyOpen}
                onClose={()=> {
                    setdReplyOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                    Do you want to Delete the Reply ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=> {
                    setdReplyOpen(false)
                }} color="primary" style={{fontSize:"1.35rem"}}>
                    No
                </Button>
                <Button onClick= {() => {
                    setdReplyOpen(false);
                    CommentReplyDeleteAction(prefixurl, props.comment.id, props.reply.id, handleReplyDelete)
                    setComm(null);
                    setParentId(null);
                    setRepId(null);
                    setShowReplyBox(false)
                    setTimeout(() => {
                        setShowCommBox(true)
                        setRefreshBlog(!refreshBlog)
                    },200);
                }} color="primary" autoFocus style={{fontSize:"1.35rem"}}>  
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        )
    }
    

    return (
        // here class 'row' required so that the detail does not become full screen
        <VisualViewport>
        <div className='row donotscroll' id='blgdetail'> 
            {(doneLoading === true) 
            ? 
            <div className='col-lg-6 col-lg-offset-3' style={{backgroundColor:'#0d171f'}}>
            <div style={{width:'100%'}}>
                {blog === null ? 
                    blogDoesNotExist
                    ?
                    <div>
                        <div className={classes.notifycontainer} style={{border:'none', backgroundColor:!readFlag && '#2d516f'}} >
                            <div style={{display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center', position:'relative'}}>
                                <div style={{marginRight:'12px',alignSelf:'flex-start', position:'relative', display:'flex', flexDirection:'column', marginBottom:'8px', marginTop:'8px'}}>
                                    <div className={classes.avatar34}>
                                    {/*  */}
                                    </div>
                                </div>
                                <div style={{color:'#ffffff', display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center'}}>
                                    <div style={{flexBasis:'0px', paddingTop:'12px', position:'relative', display:'flex', justifyContent:'space-between', 
                                                    alignItems:'stretch', flexShrink:'1', paddingBottom:'12px', flexDirection:'column', flexGrow:'1'}}>
                                        <div>
                                            <span style={{wordBreak:'break-word', fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                                                {'This post does not exist anymore.'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                    : null

                :
                    <div>
                    <div className={classes.mnblkauto}>
                        <div className={classes.blogM} 
                                onClick={()=>{handleCommReset()}}
                            >
                            <div className={classes._3sAFzPEm}>
                                <div className={classes.w1HLFFlu}>
                                    <div className={classes._37iC3bl} onClick={()=>handleAvatar(blog.user.username, blog.user.private, blog.user.blockedby)}>
                                        <div className={classes.avatar}>
                                            <div className={classes.avatar34}>
                                                <img className={classes.avtrimg} src={blog.user.avatar} alt=''></img>
                                            </div>
                                        </div>
                                        <span className={classes._1lxVetIX}>
                                            <span className={classes._3BGj4iHx}>
                                                { blog.user.first_name 
                                                ? blog.user.first_name + ' ' + blog.user.last_name
                                                : blog.user.username
                                                }
                                            </span>
                                            <span className={classes.authorct}>
                                                {moment(blog.timestamp).fromNow()}
                                            </span>
                                        </span>
                                    </div>
                                    <div className={classes.postcmc}>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={toggleDrawer('bottom', true)}
                                        >
                                        <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                                        </IconButton>
                                        <Drawer anchor={'bottom'} open={stateMenu['bottom']} onClose={toggleDrawer('bottom', false)}>
                                            {list('bottom')}
                                        </Drawer>
                                        {/* Drawer start for Post reporting */}
                                        <Drawer
                                            anchor={'bottom'} 
                                            open={stateReportMenu['bottom']} 
                                            onClose={toggleReportDrawer('bottom', false,"","")}
                                        >   
                                            {menuName && (
                                            <ListItem button onClick={() => setMenuName(null)}>
                                                <ChevronLeftIcon style={{fontSize:'3rem'}}/>
                                                <ListItemText /> {/* added this 'ListItemText' so that the CloseIcon positions to the far right */}
                                                <CloseIcon style={{fontSize:'2.5rem'}} onClick={toggleReportDrawer('bottom', false, "", "")} />
                                            </ListItem>
                                            )}
                                            {confirmpg && (
                                            <ListItem button onClick={() => {
                                                                            setMenuName(menuInBetween)
                                                                            setconfirmpg(null)}
                                                                            }>
                                                <ChevronLeftIcon style={{fontSize:'3rem'}}/>
                                                <ListItemText /> {/* added this 'ListItemText' so that the CloseIcon positions to the far right */}
                                                <CloseIcon style={{fontSize:'2.5rem'}} onClick={toggleReportDrawer('bottom', false,"","")} />
                                            </ListItem>
                                            )}
                                            {reporttype('bottom')}
                                        </Drawer>
                                        {/* Drawer end for Post reporting */}
                                    </div>
                                    <Dialog
                                        open={dOpen}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                                                Do you want to Delete the Post ?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary" style={{fontSize:"1.35rem"}}>
                                                No
                                            </Button>
                                            <Button onClick={handleBlogDelete} color="primary" autoFocus style={{fontSize:"1.35rem"}}>
                                                Yes
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                                <div className='content clearfix' style={{paddingLeft:'20px',marginRight:'10px'}}>
                                    <p className={classes.contentbody}>
                                        <span className={classes.truncateltc}>
                                            <span>
                                            {blog.content}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                                {blog.get_media_download_url 
                                    ?
                                        <div onClick={(e) => { e.stopPropagation()}} >
                                            <LazyLoad  offsetBottom={600}>
                                                <div>
                                                    {blog.mediatype.slice(0,6) === "video/" 
                                                        ?
                                                        <Player
                                                            playsInline
                                                            preload='none'
                                                            poster={ blog.get_sd_download_url }
                                                            src={ blog.get_media_download_url }>
                                                            <BigPlayButton position="center" />
                                                            <ControlBar autoHide={true} className="my-class" >
                                                                <VolumeMenuButton enabled />
                                                            </ControlBar>
                                                        </Player>
                                                        :
                                                        <div>
                                                            {!loaded && <img placeholder={Placeholder} src={Placeholder} alt='post'  width='100%' /> }
                                                            <Gallery key={blog.mediapath} options={options}>
                                                                <Item
                                                                original={blog.get_media_download_url}
                                                                thumbnail={blog.get_media_download_url}
                                                                width="800"
                                                                height="800"
                                                                >
                                                                {({ ref, open }) => (
                                                                    <img 
                                                                        placeholder={imgLoadingUrl} 
                                                                        ref={ref} 
                                                                        onClick={open}
                                                                        src={blog.get_sd_download_url}
                                                                        style={imageStyle}
                                                                        onLoad={handleImageLoaded} 
                                                                        alt='post' 
                                                                        width='100%'
                                                                    />
                                                                )}
                                                                </Item>
                                                            </Gallery>
                                                        </div>
                                                    }
                                                </div>
                                            </LazyLoad>
                                        </div>
                                    : null}
                                <div className={classes.postac}>
                                    <div className={classes._3LHT9vBz}>
                                        <ActionBtn blog={blog} onLike={handleBlogLike} action={{type: "like", display: "Likes"}}/>
                                    </div>
                                    <div className={classes.postactioncom}>
                                        <span className={classes.commentBubble}>
                                            <ChatBubbleOutlineRoundedIcon 
                                                fontSize='small'
                                                htmlColor='#4AA6DE'
                                            />
                                        </span>
                                        <span className={classes.postcct} onClick={()=>{handleCommReset()}}>
                                            {blog.comments_total}{' '}{blog.comments_total > 1 ? 'Comments' : 'Comment'}
                                        </span>
                                    </div>
                                    { blog.likes > 0 
                                    ?
                                    <div className={classes.postacc_t} onClick={() => setStateUserlikes({isPaneOpenLikes: true})}>
                                        <span className={classes.post_comm_cnttext}>
                                            {blog.likes}
                                        </span>
                                        <span className={classes.likeThumb}>
                                            <ThumbUpAltIcon
                                                fontSize='default' 
                                                htmlColor='#4AA6DE' />
                                        </span>
                                    </div>
                                    : null
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <div className={classes.commentM}>
                            {commPrevPage ?
                            <div className='morecomments' style={{padding:'0 0 15px 20px', color: '#ffffff'}} onClick={handlePreviousComments} >
                                <span className={classes._3BGj4iHy}> View previous comments... </span>
                            </div>
                            : null}

                            {blog.comments && blog.comments.map((comment, i) => (
                                <div className={classes._3sAFzPEm} key={"comment_" + comment.id}>
                                    <div className={classes.avatar} onClick={()=>handleAvatar(comment.user.username, comment.user.private, comment.user.blockedby)}>
                                        <div className={classes.avatar34}>
                                            <img className={classes.avtrimg} src={comment.user.avatar} alt=''></img>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.w1HLFFlc} >
                                            <div className={classes._37iC3bl} 
                                            onClick={()=>{handleCommReset()}} 
                                            >
                                                <span className={classes._1lxVetIX}>
                                                    <span className={classes._3BGj4iHy} onClick={()=>handleAvatar(comment.user.username, comment.user.private, comment.user.blockedby)}>
                                                        { comment.user.first_name 
                                                        ? comment.user.first_name + ' ' + comment.user.last_name
                                                        : comment.user.username
                                                        }
                                                    </span>
                                                    <span className={classes.authorcc}>
                                                        {moment(comment.timestamp).fromNow()}
                                                    </span>
                                                </span>
                                            </div>
                                            <IsolatedCommMenu  comment={comment}/>
                                        </div>
                                        <div className='content clearfix' style={{paddingLeft:'20px', paddingBottom:'10px', marginRight:'10px'}} 
                                            onClick={()=>{handleCommReset()}}
                                        >
                                            <p className={classes.commcontentbody}>
                                                <span className={classes.truncateltc}>
                                                    <span>
                                                    {comment.content}
                                                    </span>
                                                </span>
                                            </p>
                                            {comment.get_media_download_url 
                                            ?
                                                <div onClick={(e) => { e.stopPropagation()}} >
                                                    <LazyLoad offsetBottom={1600}>
                                                        <div className={classes.commMedia}>
                                                            {comment.mediatype.slice(0,6) === "video/" 
                                                                ?
                                                                <div className={classes.commMediaVideo}>
                                                                    <Player
                                                                        width='70%'
                                                                        height='177px'
                                                                        playsInline
                                                                        preload='none'
                                                                        fluid={false}
                                                                        poster={ comment.get_sd_download_url}
                                                                        src={ comment.get_media_download_url }>
                                                                        <BigPlayButton position="center" />
                                                                        <ControlBar autoHide={true} className="my-class" >
                                                                            <VolumeMenuButton enabled />
                                                                        </ControlBar>
                                                                    </Player>
                                                                </div>
                                                                : 
                                                                <div>
                                                                    {!commImgloaded && <img placeholder={Placeholder} src={Placeholder} alt='post'  width='200' /> }
                                                                    
                                                                    <Gallery key={comment.mediapath} options={options}>
                                                                        <Item
                                                                        original={comment.get_hd_download_url}
                                                                        thumbnail={comment.get_hd_download_url}
                                                                        width="800"
                                                                        height="800"
                                                                        >
                                                                        {({ ref, open }) => (
                                                                            <img 
                                                                                placeholder={imgLoadingUrl} 
                                                                                ref={ref} 
                                                                                onClick={open}
                                                                                src={comment.get_sd_download_url}
                                                                                style={commImageStyle}
                                                                                onLoad={handleCommImageLoaded}
                                                                                alt='post' 
                                                                                width='200'
                                                                            />
                                                                        )}
                                                                        </Item>
                                                                    </Gallery>
                                                                </div>
                                                            }
                                                        </div>
                                                    </LazyLoad>
                                                </div>
                                            : null}
                                        </div>
                                        
                                        <div className={classes.postacc} >
                                            <div className={classes.dbbl34} 
                                            onClick={()=>{handleCommReset()}}
                                            >
                                                <CommentLikeBtn 
                                                        blog={blog} 
                                                        index={i} 
                                                        commentId={comment.id} 
                                                        onLike={handleCommentLike} 
                                                        action={{type: "commentLike", display: "Likes"}}/>
                                            </div>
                                            <div id='postcctr1'>
                                                <span className={classes.postcct} onClick={(e)=>handleReplyFocus(e, comment.id, null, comment.user.ui_displayname)}>
                                                    Reply
                                                </span>
                                            </div>
                                            { comment.likes > 0 
                                            ?
                                            <div className={classes.postacc_t} 
                                                onClick={() => {
                                                    setCommReplyId(comment.id)
                                                    setStateCommentUserlikes({isPaneOpenCommentLikes: true})
                                                }}
                                            >
                                                <span className={classes.post_comm_cnttext}>
                                                    {comment.likes}
                                                </span>
                                                <span className={classes.likeThumb}>
                                                    <ThumbUpAltIcon
                                                        fontSize='default' 
                                                        htmlColor='#4AA6DE' />
                                                </span>
                                            </div>
                                            : null
                                            }
                                        </div> 
                                        <div ref={commRef} />
                                    </div>

                                    {comment.replies && comment.replies.map((reply, j) => (
                                        <div className={classes.replypad} key={"reply_" + reply.id}>
                                            <div className={classes.avatar} onClick={()=>handleAvatar(reply.user.username, reply.user.private, reply.user.blockedby)}>
                                                <div className={classes.avatar34}>
                                                    <img className={classes.avtrimgcmt} src={reply.user.avatar} alt=''></img>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.w1HLFFlr}>
                                                    <div className={classes._37iC3bl} 
                                                    onClick={()=>{handleCommReset()}}
                                                    >
                                                        <span className={classes._1lxVetIX}>
                                                            <span className={classes._3BGj4iHy} onClick={()=>handleAvatar(reply.user.username, reply.user.private, reply.user.blockedby)}>
                                                                { reply.user.first_name 
                                                                ? reply.user.first_name + ' ' + reply.user.last_name
                                                                : reply.user.username
                                                                }
                                                            </span>
                                                            <span className={classes.authorcc}>
                                                                {moment(comment.timestamp).fromNow()}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <IsolatedReplyMenu  comment={comment} reply={reply}/>
                                                </div>
                                                <div className='content clearfix' 
                                                style={{paddingLeft:'20px', paddingBottom:'10px'}}
                                                onClick={()=>{handleCommReset()}}
                                                >
                                                    <p className={classes.replycontentbody}>
                                                        <span className={classes.truncateltc}>
                                                            <span>
                                                            {reply.content}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className={classes.postacr}>
                                                    <div className={classes.dbbl34}>
                                                        <ReplyLikeBtn 
                                                            blog={blog} 
                                                            commentIndex={i} 
                                                            index={j} 
                                                            replyId={reply.id} 
                                                            onLike={handleReplyLike} 
                                                            action={{type: "replyLike", 
                                                            display: "Likes"}}/>
                                                    </div>
                                                    <div id='postcctr2'>
                                                        <span className={classes.postcct} onClick={(e)=>handleReplyFocus(e, comment.id, null, reply.user.ui_displayname)}>
                                                        Reply
                                                        </span>
                                                    </div>
                                                    { reply.likes > 0
                                                    ?
                                                    <div className={classes.postacc_t}
                                                    onClick={() => {
                                                        setCommReplyId(reply.id);
                                                        setStateCommentUserlikes({isPaneOpenCommentLikes: true})
                                                    }}
                                                    >
                                                        <span className={classes.post_comm_cnttext}>
                                                            {reply.likes}
                                                        </span>
                                                        <span className={classes.likeThumb}>
                                                            <ThumbUpAltIcon
                                                                fontSize='default' 
                                                                htmlColor='#4AA6DE' />
                                                        </span>
                                                    </div>
                                                    : null
                                                    }
                                                </div>
                                                <div ref={replyRef} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{ 
                            left:'0',width:'100%',
                            marginLeft:'auto',marginRight:'auto',
                            textAlign: 'center',backgroundColor: 'white',
                            }}>
                        <GroupBlogCommentCreateEdit
                            className   = {showCommBox ? classes.commEditBoxShow : classes.commEditBoxHide}
                            ref         = {inputCommRef} 
                            comm        = {comm} 
                            blogSlug    = {slug}
                            didComment  = {handleCommentProcessAll} 
                        />
                        <GroupBlogReplyCreateEdit
                            className     = {showReplyBox ? classes.replyEditBoxShow : classes.replyEditBoxHide}
                            ref           = {inputReplyRef} 
                            parentId      = {parentId} 
                            repId         = {repId}
                            uiDisplayname = {uiDisplayname} 
                            blogSlug      = {slug}
                            didReply      = {handleReplyProcess} 
                        />
                        <SlidingPane
                            className={classes.postCreate}
                            overlayClassName='formCreateOverlay'
                            isOpen={state.isPanePostOpen}
                            width='100%'
                            title=""
                            from='right'
                            onRequestClose={() => setState({ isPanePostOpen: false })}
                        >
                            <GroupBlogForm blog={blog} didGroupBlogSubmit={formSubmit}/> 
                        </SlidingPane>
                        <SlidingPane
                            className={classes.postCreate}
                            overlayClassName='ProfileTabsOverlay'
                            isOpen={avatarState.isPaneOpenUsr}
                            width='100%'
                            title=""
                            from='right'
                            onRequestClose={() => setAvatarState({ isPaneOpenUsr: false })}
                        >
                            <ProfileTabs username={userProfile} sessionusr={sessionUser} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
                        </SlidingPane>
                        <SlidingPane
                            className={classes.postCreate}
                            overlayClassName='UserLikesOverlay'
                            isOpen={stateUserlikes.isPaneOpenLikes}
                            width='100%'
                            title=""
                            onRequestClose={() => { setStateUserlikes({ isPaneOpenLikes: false }) }}
                        >
                            <div><GroupUserLikes slug={slug}  /></div>
                        </SlidingPane>
                        <SlidingPane
                            className={classes.postCreate}
                            overlayClassName='UserLikesOverlay'
                            isOpen={stateCommentUserlikes.isPaneOpenCommentLikes}
                            width='100%'
                            title=""
                            onRequestClose={() => { setStateCommentUserlikes({ isPaneOpenCommentLikes: false }) }}
                        >
                            <div><GroupUserCommentOrReplyLikes id={commReplyId} /></div>
                        </SlidingPane>
                    </div>
                    </div>
                }     
            </div> 
            </div>
             : 
               null 
            }
        </div>
        </VisualViewport>
    )
}

export default GroupBlogDetail
