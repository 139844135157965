import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

//third party plugins
// import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import { Gallery, Item } from 'react-photoswipe-gallery'
import SlidingPane from "react-sliding-pane";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";
import PullToRefresh from "react-simple-pull-to-refresh";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import SecurityIcon from  '@mui/icons-material/Security';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import BlockIcon from '@mui/icons-material/Block';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

// local
import UrlContext from '../../lookup/prefixurl'
import { apiUserProfileMain, 
         apiUserProfileFollowedBy, 
         apiUserFollowToggle,
         apiBlockUserToggle
        } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import ProfileTabs from '../profile/profile';



export function ProfileImage(props) {
    const prefixurl                         = useContext(UrlContext)
    const [didEdit, setdidEdit]             = useState(false)
    const [userprofile, setUserprofile]     = useState(null)
    const [doneLoading, setDoneLoading]     = useState(false)
    const [stateMenu, setStateMenu]         = useState({ bottom: false });
    const [aOpen, setAOpen]                 = useState(false); 
    const [bOpen, setBOpen]                 = useState(false); 
    const [ubOpen, setUbOpen]               = useState(false); 
    const [blockLoading, setBlockLoading ]  = useState(false);
   

    const options = {
        arrowPrev: false,
        arrowNext: false,
        zoom: false,
        close: true,
        counter: false,
        bgOpacity: 1,
    }

    const handleBlockOpen = () => {
        setBOpen(true);
    };

    const handleUnblockOpen = () => {
        setUbOpen(true);
    };

    useEffect(() => {
    if (didEdit === false){
        setdidEdit(true)
        apiUserProfileMain(prefixurl, props.username, handleUserLikesLookup)
    }
    }, [prefixurl, props, didEdit, setdidEdit])
    

    const handleUserLikesLookup = (response, status) => {
        if (status === 200){
            setUserprofile(response)
            setDoneLoading(true)
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStateMenu({ ...stateMenu, [anchor]: open });
    };

    const handleBlockToggle = (event) => {
        if (!blockLoading) {
            setBlockLoading(true)
            apiBlockUserToggle(prefixurl, event.username, event.blocked ? "unblock" : "block", (response, status) => {
                if ( response.wait_to_block === true ) {
                    setAOpen(true);
                }
                if (status === 200) {
                    setBlockLoading(false)
                    setdidEdit(!didEdit)
                }
            }  )
        }
    }

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })} >
                {
                    <ListItem key={'block'} onClick={userprofile.blocked ? handleUnblockOpen : handleBlockOpen } >
                        <ListItemIcon>{<BlockIcon fontSize='large' /> }</ListItemIcon>
                        <Typography style={{ fontSize: '1em', color: '#000000' }}>
                        { userprofile.blocked ? "Unblock" : "Block" }
                        </Typography>
                    </ListItem>
                }
            </List>
    
        </div>
        );
    
    return (
        <div>
            {(doneLoading === true) 
            ? 
            <div>
                <div style={{display:'flex', backgroundColor: "#172734"}}>
                    {userprofile.private ?
                        <div style={{'width':'15%'}}>
                            <Typography 
                                sx = {{
                                    '&.MuiTypography-root' : {
                                        color: "#FFFFFF"
                                    }
                                }}
                                color="textSecondary" align='center' variant='h3'>
                                <div>
                                    <VpnLockIcon htmlColor="#e63946" fontSize='large'/>
                                </div> 
                            </Typography>
                        </div>
                    : null}
                    <div style={{width:'100%', paddingLeft:userprofile.private ?'6px':'20px', paddingTop:'10px', paddingBottom:'8px', color:'#ffffff', position:'relative'}}>
                        <Typography 
                            sx = {{
                                '&.MuiTypography-root' : {
                                    color: "#FFFFFF"
                                }
                            }}
                            align='left' variant='h6' gutterBottom>  
                        {userprofile.first_name
                        ?   <div>
                                {userprofile.first_name}{" "}{userprofile.last_name}
                            </div>
                        : <span>{userprofile.username}</span>
                        }
                        </Typography>
                        {userprofile.username === userprofile.sessionUser 
                        ? null
                        :
                        <div className={classes.blockcmc}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={toggleDrawer('bottom', true)}
                            >
                            <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                            </IconButton>
                            <Drawer
                                anchor={'bottom'} 
                                open={stateMenu['bottom']} 
                                onClose={toggleDrawer('bottom', false)}
                                onClick={toggleDrawer('bottom', false)}

                            >
                                {list('bottom')}
                            </Drawer>
                            <Dialog
                            open={bOpen}
                            onClose={()=> {
                                setBOpen(false)
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            >
                            <DialogTitle id="alert-dialog-title">
                                Block
                                    {
                                        userprofile.first_name 
                                        ? 
                                            userprofile.last_name 
                                            ? ' ' + userprofile.first_name  + ' ' + userprofile.last_name
                                            : ' ' + userprofile.first_name 
                                        : ' ' + userprofile.username
                                    } ?
                            </DialogTitle>
                                <DialogContent dividers>
                                    <Typography paragraph>
                                    { userprofile.first_name 
                                        ? 
                                            userprofile.last_name 
                                            ? ' ' + userprofile.first_name  + ' ' + userprofile.last_name
                                            : ' ' + userprofile.first_name 
                                        : ' ' + userprofile.username
                                    } will no longer be able to:
                                    </Typography>
                                    <Typography gutterBottom>
                                     - Follow you
                                    </Typography>
                                    <Typography gutterBottom>
                                        - See your posts
                                    </Typography>
                                    <Typography paragraph>
                                        - See your Profile
                                    </Typography>
                                    <Typography gutterBottom>
                                        
                                    if  {
                                        userprofile.first_name 
                                        ? 
                                            userprofile.last_name 
                                            ? ' ' + userprofile.first_name  + ' ' + userprofile.last_name
                                            : ' ' + userprofile.first_name 
                                        : ' ' + userprofile.username
                                    } is following you, blocking will also make the person unfollow you.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                <Box 
                                    display="flex" 
                                    width={600} height={40} 
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Button onClick={()=> {
                                                    setBOpen(false)
                                                }} color="primary">
                                        Cancel
                                    </Button>
                                    <Button 
                                        onClick= {() => {
                                            setBOpen(false);
                                            handleBlockToggle(userprofile)
                                        }} 
                                        color="primary" autoFocus>
                                        Block
                                    </Button>
                                    </Box>
                                </DialogActions>
                            </Dialog>

                            {/* Start unblock */}
                            <Dialog
                                open={ubOpen}
                                onClose={()=> {
                                    setUbOpen(false)
                                }}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                            <DialogTitle id="alert-dialog-title">
                                Unblock
                                    {
                                        userprofile.first_name 
                                        ? 
                                            userprofile.last_name 
                                            ? ' ' + userprofile.first_name  + ' ' + userprofile.last_name
                                            : ' ' + userprofile.first_name 
                                        : ' ' + userprofile.username
                                    } ?
                            </DialogTitle>
                                <DialogContent dividers>
                                    <Typography paragraph>
                                        If you unblock 
                                        { userprofile.first_name 
                                        ? ' ' + userprofile.first_name + ', '
                                        : ' ' + userprofile.username + ', '
                                        } 
                                        the person will be able to start following you, and view your posts.
                                    </Typography>
                                    <Typography gutterBottom>
                                        You will have to wait 48 hours if you want to block
                                    { userprofile.first_name 
                                        ? ' ' + userprofile.first_name 
                                        : ' ' + userprofile.username + ', '
                                        } again.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                <Box 
                                    display="flex" 
                                    width={600} height={40} 
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Button onClick={()=> {
                                                    setUbOpen(false)
                                                }} color="primary">
                                        Cancel
                                    </Button>
                                    <Button 
                                        onClick= {() => {
                                            setUbOpen(false);
                                            handleBlockToggle(userprofile)
                                        }} 
                                        color="primary" autoFocus>
                                        Unblock
                                    </Button>
                                    </Box>
                                </DialogActions>
                            </Dialog>
                            {/* end unblock */}


                            {/* START */}
                            <Dialog
                                open={aOpen}
                                onClose={()=> {
                                    setAOpen(false)
                                }}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Box 
                                        display="flex" 
                                        width='auto' height={40} 
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <SecurityIcon style={{fontSize:"2.5rem"}} color="primary"/>
                                    </Box>
                                </DialogTitle>
                                    <DialogContent dividers>
                                        <Typography style={{fontSize:"1.5rem"}} paragraph align="center" variant="h6"> 
                                            Please Try Again Later
                                        </Typography>
                                        <Typography style={{fontSize:"1.2rem"}} gutterBottom align="center">
                                            Because you recently unblocked this person, you'll have to wait a couple of days before you can block them again.
                                        </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                    <Box 
                                        display="flex" 
                                        width={600} height={40} 
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Button style={{fontSize:"1.5rem"}} 
                                                onClick={()=> {
                                                        setAOpen(false)
                                                    }} color="primary">
                                            OK
                                        </Button>
                                        </Box>
                                    </DialogActions>
                            </Dialog>

                        {/* END */}
                        </div>
                        }
                    </div>

                </div>
                <div style={{paddingTop:'20px'}}>
                    <Gallery key={userprofile.profilepic} options={options}>
                        <Item
                            original={userprofile.profilepic}
                            thumbnail={userprofile.profilepic}
                            width="1600"
                            height = "1600"
                            >
                            {({ ref, open }) => (
                                <img 
                                    className={classes.propic}
                                    ref={ref} 
                                    onClick={open}
                                    src={userprofile.profilepic}
                                    alt='profileimg' 
                                    // width='200px'
                                />
                            )}
                        </Item>
                    </Gallery>
                </div>
            </div>
            : null
            }
        </div>
    )
}

export function ProfileFollow(props) {
    const prefixurl = useContext(UrlContext)
    const [didEdit, setdidEdit]         = useState(false)
    const [userprofile, setUserprofile] = useState(null)
    const [doneLoading, setDoneLoading] = useState(false)
    const [followLoading, setFollowLoading ] = useState(false)
    
    useEffect(() => {
    if (didEdit === false){
        setdidEdit(true)
        apiUserProfileMain(prefixurl, props.username, handleUserLikesLookup)
    }
    }, [prefixurl, props, didEdit, setdidEdit])
    
    const handleUserLikesLookup = (response, status) => {
        if (status === 200){
            setUserprofile(response)
            setDoneLoading(true)
        }
    }
    
    const handleFollowToggle = (event) => {
        if (!followLoading) {
            setFollowLoading(true)
            apiUserFollowToggle(prefixurl, event.username, event.followed ? "unfollow" : "follow", (response, status) => {
                if (status === 200) {
                    setFollowLoading(false)
                    setdidEdit(!didEdit)
                }
            }  )
        }
    }

    return ( 
        <div>
            {(doneLoading === true) 
            ? 
            <div>
                <div style={{width:'100%', textAlign:'center', padding:'10px 0 10px 0'}}>
                    { userprofile.username !== userprofile.sessionUser 
                    ?
                        !userprofile.private 
                            ?   
                                !userprofile.blockedby
                                    ?
                                        <button className="btn btn-sm btn-info" onClick={() => handleFollowToggle(userprofile)}>
                                            { userprofile.followed ? "UnFollow" : "Follow" }
                                        </button>
                                    : null
                            : null
                    : null
                    }
                </div>
            </div>
             : 
                null
            }
        </div>
    )
}

export function ProfileFollowedBy(props) {
    const prefixurl = useContext(UrlContext)
    const [didEdit, setdidEdit] = useState(false)
    const [userFollowing, setUserFollowing] = useState(null)
    const [userProfilePrivacyFlag, setUserProfilePrivacyFlag] = useState(false)
    const [userProfileBlockedFlag, setUserProfileBlockedFlag] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false)
    const [userProfile, setUserProfile] = useState(null)
    const [state, setState] = useState({isPaneOpen: false})


    useEffect(() => {
        if (didEdit === false){
            setdidEdit(true)
            apiUserProfileFollowedBy(prefixurl, props.username, handleUserLikesLookup)
        }
    }, [prefixurl, props, didEdit, setdidEdit])
    
    const handleRefreshc = () => {
        apiUserProfileFollowedBy(prefixurl, props.username, handleUserLikesLookup)
        return new Promise((resolve) => {
            setTimeout(resolve, 1000)
        });
    };

    const handleUserLikesLookup = (response, status) => {
        if (status === 200){
            setUserFollowing(response)
            setDoneLoading(true)
        }
    }

    const handleAvatar = (username, privacyFlag, blockedFlag)=>{
        setUserProfile(username)
        if (username === userFollowing.sessionUser) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(privacyFlag)
            setUserProfileBlockedFlag(blockedFlag)
        }
        setState({ isPaneOpen: true })
    }

    return (
        <div >
            {(doneLoading === true) 
            ? 
            <div>
            <PullToRefresh
                onRefresh={handleRefreshc}
                pullingContent = {false}
            >
                <div className={classes.bndhdr}>
                    <Typography style={{ fontSize: '1em', color: '#ffffff' }}>
                        {userFollowing.followed_by.length > 1 ? userFollowing.followed_by.length + ' followers' : 
                            userFollowing.followed_by.length === 1 ? userFollowing.followed_by.length + ' follower' : 
                            userFollowing.followed_by.length === 0 ? 'No followers' : null
                        } 
                    </Typography>
                </div>
                {userFollowing.followed_by && userFollowing.followed_by.map((following, i) => (
                    <div key={i} className={classes.echdiv1}>
                        <div className={classes.echdiv2}>
                            <div className={classes.echdiv3}>
                                <div className={classes.avatar34} onClick={() => handleAvatar(following.username, following.private, following.blockedby)}>
                                    <img className={classes.avtrimg} src={following.avatar} alt=''></img>
                                </div>
                            </div>

                            <div className={classes.echdiv4}>
                                <div className={classes.echdiv5} onClick={() => handleAvatar(following.username, following.private, following.blockedby)} >
                                    <div>
                                        {following.private ?
                                            <div className={classes.propiclock} >
                                                <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                                            </div> 
                                        : null}
                                        <span style={{fontSize:'.9375rem', textAlign:'left', fontWeight:'500'}}>
                                        <   Typography style={{ fontSize: '1.25em', color: '#ffffff' }} gutterBottom>
                                                { following.first_name 
                                                ? following.first_name + ' ' + following.last_name
                                                : following.username
                                                }
                                            </Typography>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                }
                    <SlidingPane
                        className={classes.postCreate}
                        overlayClassName='formCreateOverlay'
                        isOpen={state.isPaneOpen}
                        width='100%'
                        title=""
                        // from='bottom'
                        onRequestClose={() => setState({ isPaneOpen: false })}
                    >
                        <ProfileTabs username={userProfile} sessionusr={userFollowing.sessionUser} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
                    </SlidingPane>
            </PullToRefresh>
            </div> 
             : 
                null 
            }
        </div>
    )
}

