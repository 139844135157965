import React, {useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

// third party 'material-ui'
import SlidingPane from "react-sliding-pane";
import LazyLoad from 'react-lazy-load';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeckIcon from '@mui/icons-material/Deck';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from '@mui/icons-material/Close';
import ListItemIcon from '@mui/material/ListItemIcon';
import PeopleIcon from '@mui/icons-material/People';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
// import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import { Gallery, Item } from 'react-photoswipe-gallery'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from "@mui/material/Divider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';

import $ from "jquery";
// local
import posteruploadicon from '../assets/images/posteruploadicon.png';
import {VisualViewport, useLockBodyScroll}  from '../utilities';
import UrlContext from '../../lookup/prefixurl'
import classes from '../assets/style.module.css'
import Placeholder from '../assets/images/group-placeholder.png';
import {GroupForm} from './groupform';
import {GroupBlogForm} from './groupblogform';
import {GroupBlogInline} from './groupbloginline';
import {GroupMemberTabs} from './groupmembermain';
import {
    apiGroupMain,
    apiUserReportGroup, 
    apiReportsTypeList, 
    apiGroupJoin,
    apiGroupLeave, 
    apiGroupRequest,
    apiGroupRequestCancel,
    apiGroupBlogFeed 
    } from '../../lookup/lookup';
import {
    verifyImage, 
    verifyVideo, 
    ImageLoading, 
    PosterImageLoading, 
    getPosterImagePolicyForS3, 
    constructFormData, 
    ProcessPosterFileUpload
    } from '../utilities';

    

const textStyleTitle = {
    color: "#060b10",
    fontWeight: 900,
    fontSize: "1.1em"
};

const textStyle = {
    color: "#060b10",
    fontSize: "1.1em"
};




export function GroupFeed(props) {
    const {groupId}                           = props;
    const {groupSlug}                         = props;
    const {didDelete}                         = props;
    const {didLeave}                          = props;
    const {didGroupEdit}                      = props;
    const prefixurl                           = useContext(UrlContext)
    const [grp, setGrp]                       = useState(null);
    const [didEdit, setdidEdit]               = useState(false)
    const [loaded, setLoaded]                 = useState(false);
    const [doneLoading, setDoneLoading]       = useState(false);
    const [sessionUser, setSessionUser]       = useState(null);
    const [groupMenuName, setGroupMenuName]   = useState(null);
    const [groupConfirmpg, setGroupConfirmpg] = useState(null);
    const [reportSubMenu, setReportSubMenu]   = useState({});
    const [ReportItems, setReportItems]       = useState(false)
    const [dOpen, setDOpen]                   = useState(false);
    const [lOpen, setLOpen]                   = useState(false);
    const [refreshGroup, setRefreshGroup]     = useState(false);
    const [openForImage, setOpenForImage]     = useState(false);
    const [jqXHR, setjqXHR]                   = useState(null);
    const [openForJoin, setOpenForJoin]       = useState(false);
    const [openForRequest, setOpenForRequest] = useState(false);
    const [scrollTo, setScrollTo]             = useState(0);
    const [sessionUsr, setSessionUsr]         = useState(null)
    const [groupBlogs, setGroupBlogs]         = useState([]) 
    const [nextUrl, setNextUrl]               = useState(null)
    const [imgLoadingUrl, setImgLoadingUrl]   = useState(null);
    const [blogsListClass]                    = useState('card')
    const [stateMenu, setStateMenu]           = useState({ bottom: false })

    const [groupBlogsDidSet, setGroupBlogsDidSet]         = useState(false)
    const [posterImgLoadingUrl, setPosterImgLoadingUrl]   = useState(null);
    const [reportSubmittedFlag, setReportSubmittedFlag]   = useState(false);
    const [stateGroupReportMenu, setStateGroupReportMenu] = useState({ bottom: false });
    const [groupMenuInBetween, setGroupMenuInBetween]     = useState(null);
    const [reportMainMenuList, setReportMainMenuList]     = useState([])
    const [sessionUserIsMember, setSessionUserIsMember]   = useState(null);
    const [openForRequestCancel, setOpenForRequestCancel] = useState(false);

    const [state, setState]             = useState({isPaneGroupEditOpen: false})
    const [memberState, setMemberState] = useState({isPaneGroupMembersOpen: false})
    const [stateForm, setStateForm]     = useState({isPaneBlogCreate: false})

    const handleImageLoaded = () => { setLoaded(true)}
    const handleGroupEdit   = () => { setState({ isPaneGroupEditOpen: true }) }
    const handleViewMembers = () => { setMemberState({ isPaneGroupMembersOpen: true }) }
    const handleDialogOpen  = () => { setDOpen(true)};
    const handleLeaveDialogOpen  = () => { setLOpen(true)};
    const handleClose       = () => { setDOpen(false)};
    const handleLeaveClose  = () => { setLOpen(false)};
    const handleClickImage  = () => { setOpenForImage(true)};

    const bull = <span  sx = {{
                                display: 'inline-block',
                                margin: '0 12px',
                                transform: 'scale(0.8)'
                            }}>•</span>;
    const imageStyle     = !loaded ? { display: "none" } : {};

    const urlToS3                        = prefixurl + '/groups/posterUploadToS3/'
    const urlToUpdateGroupAfterS3ImgLoad = prefixurl + '/groups/afterposterUploaded/'
    
    let murl       = null;
    let purl       = null;
    let surl       = null;
    let lMediaType = null;
    let newIndex   = null;
    let mediapathFromS3 = null;

    const options = {
        arrowPrev: false,
        arrowNext: false,
        zoom: false,
        close: true,
        counter: false,
        bgOpacity: 1,
    }
    
    useLockBodyScroll();

    // Cleanup : abort file uploading process if exiting before posting
    useEffect(() => {
        return () => {
            if (jqXHR) {
                jqXHR.abort()
            }
        }
    },[jqXHR])    
    
    useEffect(()=> {
        if (refreshGroup === false) {
            setRefreshGroup(true)
        }
    },[refreshGroup, setRefreshGroup])

    useEffect(() => {
        if (didEdit === false){
            apiGroupMain(prefixurl, groupSlug, handleGroupMainLookup)
            PosterImageLoading(prefixurl, handlePosterImgLoadingUrl)
            setdidEdit(true)
        }
    }, [prefixurl, groupSlug, didEdit, setdidEdit])


    useEffect(() => {
        if (ReportItems === false){
            setReportItems(!ReportItems)
            const handleRptTypesEvent = (response, status) => {
                if (status === 200) {
                    const obj = {}
                    response.forEach(el => obj[el.content] = el.reportSubtypes.map(e => e.content));
                    setReportSubMenu(obj)
                    setReportMainMenuList(Object.keys(obj))
                }
            }
            apiReportsTypeList(prefixurl, handleRptTypesEvent)
        }
    }, [ReportItems, setReportItems,reportMainMenuList, setReportMainMenuList, reportSubMenu, prefixurl])

    useEffect(() => {
        if (groupBlogsDidSet === false) { 
            const handleGroupBlogListLookup = (response, status) => {
                if (status === 200){
                    const newGroupBlogs = response.results
                    setSessionUsr(response.sessionUser)
                    setGroupBlogs(newGroupBlogs)
                    setNextUrl(response.next)
                    setGroupBlogsDidSet(true)
                }
            }
            apiGroupBlogFeed(prefixurl, groupId, handleGroupBlogListLookup)
            ImageLoading(prefixurl, handleImgLoadingUrl)
        }
      }, [prefixurl, groupId,  groupBlogsDidSet, setGroupBlogsDidSet])


    const loadMoreGroupBlogs = () =>{
        if (nextUrl !== null){
            const handleLoadNextResponse = (response, status) =>{
                if (status === 200){
                    setNextUrl(response.next)
                    const newGroupBlogs = [...groupBlogs].concat(response.results)
                    setGroupBlogs(newGroupBlogs)
                } else {
                  console.log("There was an error")
                }
            }
            apiGroupBlogFeed(prefixurl, groupId, handleLoadNextResponse, nextUrl)
        }
    }

    const scrollToTop = () => window.scrollTo({ top: 0 })
    const handlePosFixed = (event) => { window.scrollTo(0, scrollTo); }

    const handleScroll = (e) =>{
        let target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            loadMoreGroupBlogs()
        }
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenForImage(false);
        setOpenForJoin(false);
        setOpenForRequest(false);
        setOpenForRequestCancel(false);
    };

    const actionSnack = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    );
    
    const groupFormSubmit = async (updatedGroup) => {
        setState({ isPaneGroupEditOpen: false })
        const editedGroup = grp
        editedGroup.title = updatedGroup.title
        editedGroup.description = updatedGroup.description
        setGrp(editedGroup)
        setRefreshGroup(!refreshGroup)
        didGroupEdit(updatedGroup) // => to send the updated post to the list page
    }

    const handleGroupDelete = (event) => {
        event.preventDefault()
        setDOpen(false)
        didDelete(true)
    }

    const handleGroupMainLookup = (response, status) => {
        if (status === 200){
            setSessionUser(response.sessionUser)
            setSessionUserIsMember(response.sessionUserIsMember)
            setGrp(response)
            setDoneLoading(true)
        } 
    }
   
    const handlePosterImgLoadingUrl = (url) => {
        setPosterImgLoadingUrl(url)
    }
    
    const handleJoin = () => {
        const handleGroupJoined = (response) => {
            if (response.joined) {
                const editedGroup = grp
                editedGroup.sessionUserIsMember = true
                setGrp(editedGroup)
                setSessionUserIsMember(true)
                setRefreshGroup(!refreshGroup)
                setOpenForJoin(true)
            }
        }
        apiGroupJoin(prefixurl, grp.slug, handleGroupJoined)
    }

    const handleGroupLeave = () => {
        const handleGroupLeft = (response) => {
            setLOpen(false)
            didLeave(true)
        }
        apiGroupLeave(prefixurl, grp.slug, handleGroupLeft)
    }

    const handleRequest = () => {
        const handleGroupRequested = (response) => {
            if (response.requested) {
                const editedGroup = grp
                editedGroup.sessionUserRequestPending = true
                setGrp(editedGroup)
                setRefreshGroup(!refreshGroup)
                setOpenForRequest(true)
            }
        }
        apiGroupRequest(prefixurl, grp.slug, handleGroupRequested)
    }

    const handleRequestCancel = () => {
        const handleGroupRequestCancelled = (response, status) => {
            if (status === 204) {
                const editedGroup = grp
                editedGroup.sessionUserRequestPending = false
                setGrp(editedGroup)
                setRefreshGroup(!refreshGroup)
                setOpenForRequestCancel(true)
            }
        }
        apiGroupRequestCancel(prefixurl, grp.slug, handleGroupRequestCancelled)
    }

    const handleGroupBlogDelete = (itemDeleted) => {
        const editedBlogs = Object.assign([], groupBlogs);
        newIndex = editedBlogs.findIndex(blog => blog.id === itemDeleted)
        editedBlogs.splice(newIndex, 1)
        setGroupBlogs(editedBlogs)
    }
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStateMenu({ ...stateMenu, [anchor]: open });
    };
    

    const handlePosterUpload = async (obj) => {
        murl       = obj.get_image_download_url
        purl       = obj.get_poster_download_url
        const editedGroup = grp
        editedGroup.mediapath = obj.mediapath
        const handleDelayedImageloadPushed = (loaded) => {
            if (loaded) {
                editedGroup.get_image_download_url = murl
                editedGroup.get_poster_download_url = purl
            } 
        }
        await verifyImage(purl, handleDelayedImageloadPushed)
        setGrp(editedGroup)
        setRefreshGroup(!refreshGroup)
        //clear variables 
        murl        = null;
        purl        = null;
    }

    const handlePosterFileChange = async (event) => {
        event.persist()
        let file = event.target.files[0];
        if (file) {
            if (file.type.match('image')) {
                if (file.size <= 11000000) {
                    const editedGroup = grp
                    editedGroup.get_image_download_url = posterImgLoadingUrl
                    editedGroup.get_poster_download_url = posterImgLoadingUrl
                    setGrp(editedGroup)
                    setRefreshGroup(!refreshGroup)
                    getPosterImagePolicyForS3(file, groupSlug, urlToS3, UsePolicyAndUpload)
                } else {
                    handleClickImage()
                }
            }
        }
        event.target.value = ''
    }
    
    const UsePolicyAndUpload = async (file, policyData) => {
        if  ("mediapath" in policyData) {
            mediapathFromS3 = policyData["mediapath"]
            delete policyData["mediapath"]
        }

        const fd = constructFormData(policyData)
        fd.append('file', file)
        const awsEndpoint = policyData.url
        setjqXHR($.ajax({
            type : "POST",
            url : awsEndpoint,
            data : fd,
            xhr: function(){
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload){}
                return myXhr;
            },
            success : function(){
                                let data ={
                                    slug : groupSlug,
                                    mediapathFromS3 : mediapathFromS3
                                }
                                ProcessPosterFileUpload(data, urlToUpdateGroupAfterS3ImgLoad, handlePosterUpload)
            },
            error : function(_jqXHR, textStatus){
                console.log("S3 Upload Error:", textStatus)
            },
            cache : false,
            contentType : false,
            processData : false
        }))
    }

    // POST ReportType submitted by user.
    const handleReportSubmission = (reportTypeText) => {
        const handleReportSubmitted = (response, status) => {
            if (status === 201) {
                setReportSubmittedFlag(!reportSubmittedFlag)
            }
        }
        apiUserReportGroup(prefixurl, grp.slug, reportTypeText, handleReportSubmitted)
    }

    // Drawer for User reporting of Posts / Comments / Replies
    const toggleGroupReportDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        } 
        setStateGroupReportMenu({ ...stateGroupReportMenu, [anchor]: open }); 
        setGroupMenuName(null)
        setGroupConfirmpg(null)
        setReportSubmittedFlag(false)
    };

    // start report type
    const groupReportType = anchor => {
        const confirmpage = ["Submit"]
        let arr = groupMenuName ? reportSubMenu[groupMenuName] : groupConfirmpg ? confirmpage : reportMainMenuList ;

        const clickListener = text => {
          if (!groupMenuName) {
              if (!groupConfirmpg) {
                setGroupMenuInBetween(text)  
                return setGroupMenuName(text);
              } else {
                return setGroupConfirmpg(text);
              }
          } else {
            setGroupMenuName(null) 
            return setGroupConfirmpg(text)
          }
        };

        return (
          <div
            className={classes.list}
            role="presentation"
            onKeyDown={toggleGroupReportDrawer(anchor, false)}
          >
            <List>
                <ListItem className="text-center">
                {!groupMenuName && !groupConfirmpg && <ListItemText primaryTypographyProps={{ style: textStyleTitle }} primary="Choose a reason to report"  /> } 
                </ListItem>
            </List>
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
              {arr.map((text, index) => (
                  <div key={text} >
                      {groupConfirmpg && 
                            <Card sx={{ minWidth: 275, minHeight: 300 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                    You are reporting on
                                    </Typography>
                                    <br/>
                                    <Divider />
                                    <br/>
                                    <Typography style={{ fontSize: '1.3rem' }}  gutterBottom>
                                    {groupMenuInBetween}
                                    </Typography>
                                    <Typography style={{ fontSize: '1.3rem' }} gutterBottom>
                                    {bull}{groupConfirmpg}
                                    </Typography>
                                    <br/>
                                    <Divider />
                                    <br/>
                                    <Typography style={{ fontSize: '1.3rem' }}  gutterBottom>
                                        OOima will review your submission and take appropriate action.
                                    </Typography>
                                </CardContent>

                                <div className="text-center">
                                    <br/>
                                    {!reportSubmittedFlag 
                                        ?<Button 
                                            sx = {theme => ({
                                                '&..MuiButton-root':{
                                                    color: theme.palette.getContrastText(blue[600]),
                                                    backgroundColor: blue[600],
                                                    '&:hover': {
                                                    backgroundColor: blue[800],
                                                    }
                                                }
                                            })}
                                            variant="contained"  onClick={() => handleReportSubmission(groupConfirmpg)}>Submit</Button>
                                        :<Button variant="contained" disabled>Submitted !</Button>                                        
                                    }
                                <br/>
                                </div>
                                <br/>
                            </Card>
                            }  
                        {!groupConfirmpg &&            
                            <ListItem button key={text} 
                                    onClick={() => clickListener(text)}>
                                <ListItemText primaryTypographyProps={{ style: textStyle }} primary={text} />
                                <ChevronRightIcon style={{fontSize:'2rem'}}/>
                            </ListItem>
                        }
                        <Divider />
                </div>
              ))}
            </List>
          </div>
        );
      };
    // end report type

    const list = (anchor) => (
        <div
            className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
                {grp.sessionUserIsAdmin ?
                    <ListItem button key={'edit'} onClick={handleGroupEdit}>
                        <ListItemIcon>{<EditIcon fontSize='large' /> }</ListItemIcon>
                        <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Edit group name or description'} />
                    </ListItem>
                : null}
                <ListItem button key={'view'} onClick={handleViewMembers}>
                    <ListItemIcon>{<PeopleIcon fontSize='large' /> }</ListItemIcon>
                    <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'View Members'} />
                </ListItem>
                {sessionUser !== grp.creator.username ?
                    <ListItem button key={grp.slug} onClick = {toggleGroupReportDrawer('bottom', true)} >
                        <ListItemIcon>{<FlagIcon fontSize='large' /> }</ListItemIcon>
                        <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Report Group'}/>
                    </ListItem>
                : null}
                {grp.sessionUserIsMember 
                ?
                    grp.sessionUserCanLeaveGroup
                    ?
                    <ListItem button key={'leave'} onClick={handleLeaveDialogOpen}>
                        <ListItemIcon>{<EditIcon fontSize='large' /> }</ListItemIcon>
                        <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Exit Group'} />
                    </ListItem>
                    : null
                : null}
                {!grp.hasMembers
                ?
                    grp.sessionUser === grp.creator.username 
                    ?
                        <ListItem button key={'delete'} onClick={handleDialogOpen} >
                            <ListItemIcon>{<DeleteForeverIcon fontSize='large' /> }</ListItemIcon>
                            <ListItemText sx = {{ '& .MuiListItemText-primary': {fontSize: '1.5rem'} }}  primary={'Delete Group'} />
                        </ListItem>
                    : null
                :null
                }
                
            </List>
        </div>
    );

    // start of Group feed
    const handleImgLoadingUrl = (url) => {
        setImgLoadingUrl(url)
    }

    const newBlog = async (response) => { // new post is added
        setStateForm({ isPaneBlogCreate: false })
        const editedBlogs = ([...groupBlogs])
        editedBlogs.unshift(response)  // add the new post to the top of the list

        if (response.get_media_download_url) {
            murl       = response.get_media_download_url
            surl       = response.get_sd_download_url
            lMediaType = response.mediatype
            newIndex   = 0 // first in the array since this is a new post
            editedBlogs[newIndex].get_media_download_url = imgLoadingUrl
            editedBlogs[newIndex].get_sd_download_url = imgLoadingUrl
            editedBlogs[newIndex].mediatype = 'image/jpg'
            await setGroupBlogs(editedBlogs)
            const handleDelayedNewMedialoadPushed = async (loaded) => {
                if (loaded) {
                    editedBlogs[newIndex].get_sd_download_url = surl
                    editedBlogs[newIndex].get_media_download_url = murl
                    editedBlogs[newIndex].mediatype = lMediaType
                    await setGroupBlogsDidSet(false)
                } 
            }

            setTimeout(async() => {
                if (lMediaType.slice(0,6) === "video/") {
                    await verifyVideo(murl, handleDelayedNewMedialoadPushed)
                } else {
                    await verifyImage(murl, handleDelayedNewMedialoadPushed)
                }
            }, 1000);
            scrollToTop()
        } else {
            setGroupBlogs(editedBlogs)
            scrollToTop()
        }
    }

    const handleMediaProcess = async (response) => {
        const editedBlogs = ([...groupBlogs])
        const handleDelayedMedialoadPushed = (loaded) => {
            if (loaded) {
                editedBlogs[newIndex].get_sd_download_url = surl
                editedBlogs[newIndex].get_media_download_url = murl
                editedBlogs[newIndex].mediatype = lMediaType
            } 
        }
        if (lMediaType.slice(0,6) === "video/") {
            await verifyVideo(murl, handleDelayedMedialoadPushed)
        } else {
            await verifyImage(murl, handleDelayedMedialoadPushed)
        }
        await setGroupBlogs(editedBlogs)
        //clear variables
        murl        = null;
        surl        = null;
        lMediaType  = null;
        newIndex    = null;
    }

    const handleBlogEdit = async (response) => {
        const editedBlogs = ([...groupBlogs])
        newIndex = editedBlogs.findIndex(blog => blog.slug === response.slug)
        const orig_media = editedBlogs[newIndex].mediapath
        if (response.get_media_download_url) {
            if (orig_media !== response.mediapath) {  // if media file has changed
                murl       = response.get_media_download_url
                surl       = response.get_sd_download_url
                lMediaType = response.mediatype
                editedBlogs[newIndex].content = response.content
                editedBlogs[newIndex].get_media_download_url = imgLoadingUrl
                editedBlogs[newIndex].get_sd_download_url = imgLoadingUrl
                editedBlogs[newIndex].mediatype = 'image/jpg'
                setGroupBlogs(editedBlogs);
                await handleMediaProcess(response)
            } else {  // if media file has not changed
                editedBlogs[newIndex] = response
                setGroupBlogs(editedBlogs)
            }
        } else { // if there is no media file
            editedBlogs[newIndex] = response
            setGroupBlogs(editedBlogs)
        }
    }



    return (
        <VisualViewport>
            <div className='row' id='groupdetail'> 
            {(doneLoading === true) 
            ?
            <div className='col-lg-6 col-lg-offset-3' style={{backgroundColor:'#0d171f', marginBottom:"50px"}} >
                <div className={classes.mnblknoscroll} onScroll={handleScroll}>
                        {/* poster start */}
                        <div style={{width:'100%'}}>
                            {grp.get_poster_download_url 
                            ?
                            <div style={{position:"relative"}} onClick={(e) => { e.stopPropagation()}} >
                                <LazyLoad  offsetBottom={600}>
                                    <div>
                                        {!loaded && <img placeholder={Placeholder} src={Placeholder} alt='post'  width='100%' /> }
                                        <Gallery key={grp.mediapath} options={options}>
                                            <Item
                                            original={grp.get_image_download_url}
                                            thumbnail={grp.get_poster_download_url}
                                            width="800"
                                            height="800"
                                            >
                                            {({ ref, open }) => (
                                                <img 
                                                    placeholder={posterImgLoadingUrl} 
                                                    ref={ref} 
                                                    onClick={open}
                                                    src={grp.get_poster_download_url}
                                                    style={imageStyle}
                                                    onLoad={handleImageLoaded} 
                                                    alt='post' 
                                                    width='100%'
                                                />
                                            )}
                                            </Item>
                                        </Gallery>
                                    </div>
                                </LazyLoad>
                                {grp.sessionUserIsAdmin 
                                ?
                                <div style={{
                                            width:"40px", 
                                            height:"40px", 
                                            position:"absolute", 
                                            bottom:"4px", 
                                            right:"14px",
                                            borderRadius: "6px",
                                            backgroundImage: 'url(' + posteruploadicon + ')',
                                            backgroundSize: "cover",
                                            backgroundRepeat: 'no-repeat'
                                            }}>
                                    <label htmlFor="posterFileUpload" style={{width:"40px", height:"40px"}} /> 
                                    <input
                                        id="posterFileUpload"
                                        style={{display:'none'}}
                                        type={"file"}
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={handlePosterFileChange}
                                    />
                                </div>
                                : null
                                }
                            </div>
                            : null
                            }
                        </div>
                        {/* poster end */}
                        <div className={classes._3sAFzPEm}>
                            <div className={classes.w1HLFFlu}>
                                <div className={classes._37iC3bl} style={{paddingBottom:"4px"}}>
                                    <div className="grpnm">
                                        <Typography gutterBottom={true} style={{fontSize:"1.8rem", fontStyle: "italic", paddingTop:"16px", paddingLeft:"20px"}}>{grp.title} </Typography> 
                                    </div>
                                    <div className={classes.postcmc_grp}>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={toggleDrawer('bottom', true)}
                                        >
                                            <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                                        </IconButton>
                                        <Drawer anchor={'bottom'} open={stateMenu['bottom']} onClose={toggleDrawer('bottom', false)}>
                                            {list('bottom')}
                                        </Drawer>
                                        <Drawer
                                            anchor={'bottom'} 
                                            open={stateGroupReportMenu['bottom']} 
                                            onClose={toggleGroupReportDrawer('bottom', false,"","")}
                                        >   
                                            {groupMenuName && (
                                            <ListItem button onClick={() => setGroupMenuName(null)}>
                                                <ChevronLeftIcon style={{fontSize:'3rem'}}/>
                                                <ListItemText /> {/* added this 'ListItemText' so that the CloseIcon positions to the far right */}
                                                <CloseIcon style={{fontSize:'2.5rem'}} onClick={toggleGroupReportDrawer('bottom', false, "", "")} />
                                            </ListItem>
                                            )}
                                            {groupConfirmpg && (
                                            <ListItem button onClick={() => {
                                                                            setGroupMenuName(groupMenuInBetween)
                                                                            setGroupConfirmpg(null)}
                                                                            }>
                                                <ChevronLeftIcon style={{fontSize:'3rem'}}/>
                                                <ListItemText /> {/* added this 'ListItemText' so that the CloseIcon positions to the far right */}
                                                <CloseIcon style={{fontSize:'2.5rem'}} onClick={toggleGroupReportDrawer('bottom', false,"","")} />
                                            </ListItem>
                                            )}
                                            {groupReportType('bottom')}
                                        </Drawer>
                                    </div>
                                    <Dialog
                                            open={dOpen}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                                                    Do you want to Delete the Group ?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose} color="primary" style={{fontSize:"1.35rem"}}>
                                                    No
                                                </Button>
                                                <Button onClick={handleGroupDelete} color="primary" autoFocus style={{fontSize:"1.35rem"}}>
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                    </Dialog>
                                    <Dialog
                                            open={lOpen}
                                            onClose={handleLeaveClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                                                    Are you sure you want to leave the Group ?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleLeaveClose} color="primary" style={{fontSize:"1.35rem"}}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleGroupLeave} color="primary" autoFocus style={{fontSize:"1.35rem"}}>
                                                    Leave
                                                </Button>
                                            </DialogActions>
                                    </Dialog>
                                </div> 
                                
                                {
                                    (grp.description).trim().length > 0
                                    ?
                                    <div>
                                        <div style={{
                                                position: "relative",
                                                border: "none",
                                                height: "6px",
                                                background: "#182734",
                                                marginBottom: "20px"}}
                                        />
                                        <div>
                                            <Typography gutterBottom={true} 
                                                        style={{fontSize:"1.65rem", fontWeight:"400", color: '#ffffff', paddingLeft:"20px"}}
                                            >
                                                About
                                            </Typography>
                                        </div>
                                        <div className="tryitout" style={{paddingRight:"20px"}}>
                                            <Typography paragraph={true} gutterBottom={true} style={{paddingLeft:"20px", fontSize:"1.35rem"}}>{grp.description} </Typography>
                                        </div>
                                    </div>
                                    : null
                                }                                            
                                  
                                <div>
                                    {grp.sessionUserRequestPending
                                        ?
                                            <div style={{'display':'flex', "backgroundColor": "#172734"}}>
                                                <div style={{width:'100%', padding:'10px 0px 3px 10px' }}>
                                                    <Typography 
                                                        color="textSecondary" align='left' variant='h6' gutterBottom={true}
                                                    >  
                                                        <Button 
                                                            sx = {theme => ({
                                                                '&.MuiButton-root':{
                                                                    color: theme.palette.getContrastText(blue[500]),
                                                                    backgroundColor: blue[500],
                                                                    '&:hover': {
                                                                    backgroundColor: blue[800],
                                                                    }
                                                                }
                                                            })}
                                                            size="small" variant="contained" onClick= {handleRequestCancel} startIcon={<DeckIcon htmlColor='#ffffff'/>}>
                                                            Cancel Request
                                                        </Button>
                                                    </Typography>
                                                </div>
                                            </div>
                                        :
                                        !grp.sessionUserIsMember
                                            ?
                                            <div style={{'display':'flex', "backgroundColor": "#172734"}}>
                                                <div style={{width:'100%', padding:'10px 0px 3px 10px' }}>
                                                    <Typography 
                                                    sx = {{'&.MuiTypography-root' : {color: "#0077b6" }}}
                                                    color="textSecondary" align='left' variant='h6' gutterBottom={true}
                                                    >  
                                                        <Button 
                                                            sx = {theme => ({
                                                                '&..MuiButton-root':{
                                                                    color: theme.palette.getContrastText(blue[600]),
                                                                    backgroundColor: blue[600],
                                                                    '&:hover': {
                                                                    backgroundColor: blue[800],
                                                                    }
                                                                }
                                                            })}
                                                            size="small" variant="contained" onClick= {() => grp.private ? handleRequest() : handleJoin()} startIcon={<DeckIcon htmlColor='#ffffff'/>}>
                                                            {grp.private ? <>Request</> : <>Join</>}
                                                        </Button>
                                                    </Typography>
                                                </div>
                                            </div>
                                            : 
                                            null
                                    }
                                </div>
                                <div>
                                    {grp.sessionUserIsMember
                                    ?
                                    <div>
                                        <div style={{'display':'flex', "backgroundColor": "#172734"}}>
                                            <div style={{width:'100%', padding:'10px 0px 3px 10px' }}>
                                                <Typography 
                                                    sx = {{'&.MuiTypography-root' : {color: "#0077b6" }}}
                                                    color="textSecondary" align='left' variant='h6' gutterBottom={true}>  
                                                    {sessionUserIsMember
                                                    ? 
                                                    <Button 
                                                        sx = {theme => ({
                                                            '&.MuiButton-root':{
                                                                color: theme.palette.getContrastText(blue[600]),
                                                                backgroundColor: 'blue[600]',
                                                                '&:hover': {
                                                                backgroundColor: blue[800],
                                                                }
                                                            }
                                                        })}
                                                        size="small" variant="contained" color="primary" onClick ={() => setStateForm({ isPaneBlogCreate: true })} startIcon={<AddCircleIcon htmlColor='#ffffff'/>}>
                                                        Add Post
                                                    </Button>
                                                    : null
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                        {groupBlogs.length > 0 ? groupBlogs.map((blogItem, index)=>{
                                            return (
                                                <div key={index}>
                                                    <GroupBlogInline 
                                                        blog={blogItem} 
                                                        sessionusr={sessionUsr} 
                                                        onDelete={handleGroupBlogDelete}
                                                        onEdit={handleBlogEdit}
                                                        mainMenuListArray = {reportMainMenuList}
                                                        subMenuObj = {reportSubMenu} 
                                                        elClass={blogsListClass}
                                                        positionFix = {handlePosFixed}
                                                        mBScrollPos = { (ypos) =>  { setScrollTo(ypos)}   }
                                                        key={`${index}-{blogItem.id}`}
                                                    />
                                                </div>
                                            )
                                        }) :""}

                                    </div>
                                    : null
                                    }
                                </div>
                            </div>
                            <Snackbar
                                open={openForImage}
                                autoHideDuration={3000}
                                onClose={handleCloseSnack}
                                message="Cannot upload. Image size exceeds 10 MB."
                                action={actionSnack}
                                sx = {theme => ({
                                    '&.MuiSnackbar-root': {
                                        [theme.breakpoints.down('md')]: {
                                            bottom: 100,
                                        }
                                    }
                                })}
                            />
                            <Snackbar
                                open={openForJoin}
                                autoHideDuration={3000}
                                onClose={handleCloseSnack}
                                message="You have joined the group."
                                action={actionSnack}
                                sx = {theme => ({
                                    '&.MuiSnackbar-root': {
                                        [theme.breakpoints.down('md')]: {
                                            bottom: 100,
                                        }
                                    }
                                })}
                            />
                            <Snackbar
                                open={openForRequest}
                                autoHideDuration={3000}
                                onClose={handleCloseSnack}
                                message="Requested."
                                action={actionSnack}
                                sx = {theme => ({
                                    '&.MuiSnackbar-root': {
                                        [theme.breakpoints.down('md')]: {
                                            bottom: 100,
                                        }
                                    }
                                })}
                            />
                            <Snackbar
                                open={openForRequestCancel}
                                autoHideDuration={3000}
                                onClose={handleCloseSnack}
                                message="Request cancelled."
                                action={actionSnack}
                                sx = {theme => ({
                                    '&.MuiSnackbar-root': {
                                        [theme.breakpoints.down('md')]: {
                                            bottom: 100,
                                        }
                                    }
                                })}
                            />
                        </div>
                    {/* </div> */}
                </div>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='formCreateOverlay'
                    isOpen={state.isPaneGroupEditOpen}
                    width='100%'
                    title=""
                    from='right'
                    onRequestClose={() => setState({ isPaneGroupEditOpen: false })}
                >
                    <GroupForm grp={grp} didGroupSubmit={groupFormSubmit}/>
                </SlidingPane>

                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='groupMemberTabsOverlay'
                    isOpen={memberState.isPaneGroupMembersOpen}
                    width='100%'
                    title=""
                    from='right'
                    onRequestClose={() => setMemberState({ isPaneGroupMembersOpen: false })}
                >
                    <GroupMemberTabs grpslug={grp.slug} sessionUserIsAdmin={grp.sessionUserIsAdmin}/>
                </SlidingPane>

                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='formCreateOverlay'
                    isOpen={stateForm.isPaneBlogCreate}
                    width='100%'
                    title=""
                    onRequestClose={() => setStateForm({ isPaneBlogCreate: false })}
                >
                    <GroupBlogForm didGroupBlogSubmit={newBlog} groupId={groupId}/>
                </SlidingPane>
            </div>
            : 
            null 
        }
        </div> 
    </VisualViewport>
    )
}
