import React, {useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlagIcon from '@mui/icons-material/Flag';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from "@mui/material/ListItemText";
// import BookmarkIcon from '@mui/icons-material/Bookmark';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
// import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from "@mui/material/Divider";
import { blue } from '@mui/material/colors';

// third party plugins
import moment from 'moment';
import { Player, ControlBar, BigPlayButton, VolumeMenuButton } from 'video-react';
import LazyLoad from 'react-lazy-load';
import SlidingPane from "react-sliding-pane";
// import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import { Gallery, Item } from 'react-photoswipe-gallery'
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'

// local
import UrlContext from '../../lookup/prefixurl'
import { apiGroupBlogDelete, 
        apiGroupBlogAction, 
        apiGroupUserReportsType } from '../../lookup/lookup';
import {GroupBlogDetail} from './groupblogdetail'
import {GroupBlogForm} from './groupblogform';
import classes from '../assets/style.module.css'
import {GroupBlogUserLikes} from './groupbloguserlikes';
import ProfileTabs from '../profile/profile';
import Placeholder from '../assets/images/placeholder.png';

const textStyleTitle = {
    color: "#060b10",
    fontWeight: 900,
    fontSize: "1.1em"
};

const textStyle = {
    color: "#060b10",
    fontSize: "1.1em"
};
 

export function GroupBlogInline(props) {
    const prefixurl                      = useContext(UrlContext)
    const {blog}                         = props
    const {onEdit}                       = props
    const {onDelete}                     = props
    // const {bookmarkState}                = props
    // const {notificationState}            = props
    const {mainMenuListArray}            = props
    const {subMenuObj}                   = props
    const {positionFix}                  = props
    const {mBScrollPos}                  = props
    const {sessionusr}                   = props
    const [dOpen, setDOpen]              = useState(false); 
    const [didLike, setDidLike]          = useState(false);

    const [stateOne, setStateOne]        = useState({ isPaneOpen: false });
    const [stateTwo, setStateTwo]        = useState({ isPaneOpenBC: false });
    const [stateThree, setStateThree]    = useState({ isPaneOpenLikes: false });
    const [state, setState]              = useState({ isPaneOpenUsr: false });
    const [stateMenu, setStateMenu]      = useState({ bottom: false });

    const [loaded, setLoaded]            = useState(false);
    const [menuName, setMenuName]        = useState(null);
    const [confirmpg, setconfirmpg]      = useState(null);
    const [menuInBetween, 
            setMenuInBetween]            = useState(null);
    const [userProfile, setUserProfile]  = useState(null);

    const [userProfilePrivacyFlag, 
            setUserProfilePrivacyFlag]   = useState(false);
    const [userProfileBlockedFlag, 
            setUserProfileBlockedFlag]   = useState(false);
    const [stateReportMenu, 
            setStateReportMenu]          = useState({ bottom: false });
    const [reportSubmittedFlag, 
            setReportSubmittedFlag]      = useState(false);

    const options = {
        arrowPrev: false,
        arrowNext: false,
        zoom: false,
        close: true,
        counter: false,
        bgOpacity: 1,
    }
    
    
    const bull = <span sx = {{
                            display: 'inline-block',
                            margin: '0 12px',
                            transform: 'scale(0.8)'
                        }}>•</span>;

    const imageStyle = !loaded ? { display: "none" } : {};

    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    const handleImageLoaded = () => {
        setLoaded(true)
    }
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStateMenu({ ...stateMenu, [anchor]: open });
    };

    const toggleReportDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }       
        setStateReportMenu({ ...stateReportMenu, [anchor]: open }); 
        setMenuName(null)
        setconfirmpg(null)
        setReportSubmittedFlag(false)
    };

    const handleReadFlag =()=>{}
    const handleLikeClick = (event) => {
        event.preventDefault()
        apiGroupBlogAction(prefixurl, blog.slug, 'like', handleActionLikeEvent)
    }

    const handleActionLikeEvent = (response, status) => {
        if (status === 200){
            blog.liked = response.liked
            blog.likes = response.likes
            setDidLike(!didLike)
          }
    }

    const handleActionDeleteEvent = (response, status) => {
        if (status === 204) {
            onDelete(blog.id)
            if (setDOpen) {
                setDOpen(false)
            }
        }
    }

    const handleClickOpen = () => {
        setDOpen(true);
    };

    const handleClose = () => {
        setDOpen(false)
    };

    const handleDelete = async (event) => {
        await apiGroupBlogDelete(prefixurl, blog.slug, handleActionDeleteEvent)
        setStateOne({ isPaneOpen: false })
    }

    useEffect(()=>{
        if (didLike === false) { 
            setDidLike(true)
        }
    },[props, didLike, setDidLike])

    const handleBlogCreate =() => {
        setStateTwo({ isPaneOpenBC: true })
    }

    const formSubmit = (event) => {
        if (event) {
            setStateTwo({ isPaneOpenBC: false })
        }
        onEdit(event)
    }

    const handleAvatar = (username)=>{
        setUserProfile(username)
        if (blog.owner) {
            setUserProfilePrivacyFlag(false)
            setUserProfileBlockedFlag(false)
        } else {
            setUserProfilePrivacyFlag(blog.user.private)
            setUserProfileBlockedFlag(blog.user.blockedby)
        }
        setState({ isPaneOpenUsr: true })
    }
    
    const postEdited = (updatedPost) => {
        onEdit(updatedPost)
    };

    // POST ReportType submitted by user.
    const handleReportSubmission =(reportTypeText) => {
        const handleReportSubmitted = (response, status) => {
            if (status === 201) {
                setReportSubmittedFlag(!reportSubmittedFlag)
            }
        }
        apiGroupUserReportsType(prefixurl, blog.slug, null, reportTypeText, handleReportSubmitted)
    }
    

    const list = (anchor) => (
    <div
        className={clsx({width: 250}, {[{width: 'auto'}]: anchor === 'bottom'})}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    >
        <List sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })} >
            {blog.owner ?
                <ListItem  key={'edit'} onClick={handleBlogCreate}>
                    <ListItemIcon>{<EditIcon fontSize='large' /> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>Edit Post</Typography>
                </ListItem>
            : null}
             {blog.owner ?
                <ListItem  key={'delete'} onClick={handleClickOpen} >
                    <ListItemIcon>{<DeleteForeverIcon fontSize='large' /> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>Delete Post</Typography>
                </ListItem>
            : null}
            {blog.owner ?
              null
            : 
                <ListItem  key={'report'} onClick={toggleReportDrawer('bottom', true)} >
                    <ListItemIcon>{<FlagIcon fontSize='large' /> }</ListItemIcon>
                    <Typography style={{ fontSize: '1em', color: '#000000' }}>Report Post</Typography>
                </ListItem>
            }
        </List>

    </div>
    );
    
    // start report type
    const reporttype = anchor => {
        const confirmpage = ["Submit"]
        let arr = menuName ? subMenuObj[menuName] : confirmpg ? confirmpage : mainMenuListArray ;

        const clickListener = text => {
          if (!menuName) {
              if (!confirmpg) {
                setMenuInBetween(text)  
                return setMenuName(text);
              } else {
                return setconfirmpg(text);
              }
          } else {
            setMenuName(null) 
            return setconfirmpg(text)
          }
        };

        return (
          <div
            className={classes.list}
            role="presentation"
            onKeyDown={toggleReportDrawer(anchor, false)}
          >
            <List>
                <ListItem className="text-center">
                {!menuName && !confirmpg && <ListItemText primaryTypographyProps={{ style: textStyleTitle }} primary="Choose a reason to report"  /> }
                </ListItem>
            </List>
            <List  sx = {theme => ({
                '&.MuiList-padding': {
                    paddingBottom: '66px',
                    [theme.breakpoints.up('md')]: {
                      paddingBottom: '0px',
                    },
                }
            })}>
              {arr.map((text, index) => (
                  <div key={text} >
                      {confirmpg && 
                            <Card sx={{ minWidth: 275, minHeight: 300 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                    You are reporting on
                                    </Typography>
                                    <br/>
                                    <Divider />
                                    <br/>
                                    <Typography style={{ fontSize: '1.3rem' }}  gutterBottom>
                                    {menuInBetween}
                                    </Typography>
                                    <Typography style={{ fontSize: '1.3rem' }} gutterBottom>
                                    {bull}{confirmpg}
                                    </Typography>
                                    <br/>
                                    <Divider />
                                    <br/>
                                    <Typography style={{ fontSize: '1.3rem' }}  gutterBottom>
                                        OOima will review your submission and take appropriate action.
                                    </Typography>
                                </CardContent>

                                <div className="text-center">
                                    <br/>
                                    {!reportSubmittedFlag 
                                        ?<Button 
                                            sx = {theme => ({
                                                '&..MuiButton-root':{
                                                    color: theme.palette.getContrastText(blue[600]),
                                                    backgroundColor: blue[600],
                                                    '&:hover': {
                                                    backgroundColor: blue[800],
                                                    }
                                                }
                                            })}
                                        variant="contained"  onClick={() => handleReportSubmission(confirmpg)}>Submit</Button>
                                        :<Button variant="contained" disabled>Submitted !</Button>                                        
                                    }
                                <br/>
                                </div>
                                <br/>
                            </Card>
                            }  
                        {!confirmpg &&            
                            <ListItem button key={text} 
                                    onClick={() => clickListener(text)}>
                                <ListItemText primaryTypographyProps={{ style: textStyle }} primary={text} />
                                <ChevronRightIcon style={{fontSize:'2rem'}} />
                            </ListItem>
                        }
                        <Divider />
                </div>
              ))}
            </List>
          </div>
        );
      };
    // end report type

    return (
      <div>
          {blog !== undefined 
          ?
            blog.user.blockedby === false
            ?
             <div className={classes.blogM} >
                <div className={classes._3sAFzPEm}>
                    <div className={classes.w1HLFFlu}>
                        <div className={classes._37iC3bl} onClick={() => handleAvatar(blog.user.username)}>
                            <div className={classes.avatar}>
                                <div className={classes.avatar34}>
                                    <img className={classes.avtrimg} src={blog.user.avatar} alt=''></img>
                                </div>
                            </div>
                            <span className={classes._1lxVetIX}>
                                <span className={classes._3BGj4iHx}>
                                    { blog.user.first_name 
                                    ? blog.user.first_name + ' ' + blog.user.last_name
                                    : blog.user.username
                                    }
                                </span>
                                <span className={classes.authorct}>
                                    {moment(blog.timestamp).fromNow()}
                                </span>
                            </span>
                        </div>
                        <div className={classes.postcmc}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={toggleDrawer('bottom', true)}
                            >
                            <MoreHorizIcon fontSize='large' htmlColor='#ffffff' />
                            </IconButton>
                            <Drawer
                                anchor={'bottom'} 
                                open={stateMenu['bottom']} 
                                onClose={toggleDrawer('bottom', false)}
                                onClick={toggleDrawer('bottom', false)}

                            >
                                {list('bottom')}
                            </Drawer>
                            <Drawer
                                anchor={'bottom'} 
                                open={stateReportMenu['bottom']} 
                                onClose={toggleReportDrawer('bottom', false)}
                            >   
                                {menuName && (
                                <ListItem button onClick={() => setMenuName(null)}>
                                    <ChevronLeftIcon style={{fontSize:'3rem'}} />
                                    <ListItemText /> {/* added this 'ListItemText' so that the CloseIcon positions to the far right */}
                                    <CloseIcon style={{fontSize:'2.5rem'}} onClick={toggleReportDrawer('bottom', false)} />
                                </ListItem>
                                )}
                                {confirmpg && (
                                <ListItem button onClick={() => {
                                                                setMenuName(menuInBetween)
                                                                setconfirmpg(null)}
                                                                }>
                                    <ChevronLeftIcon style={{fontSize:'3rem'}} />
                                    <ListItemText /> {/* added this 'ListItemText' so that the CloseIcon positions to the far right */}
                                    <CloseIcon style={{fontSize:'2.5rem'}} onClick={toggleReportDrawer('bottom', false)} />
                                </ListItem>
                                )}
                                {reporttype('bottom')}
                            </Drawer>
                        </div>
                        <Dialog
                            open={dOpen}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                                        Do you want to Delete the Post ?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary" style={{fontSize:"1.35rem"}}>
                                        No
                                    </Button>
                                    <Button onClick={handleDelete} color="primary" autoFocus style={{fontSize:"1.35rem"}}>
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                    </div>
                </div>
                <div className={classes._3sAFzPEm}>
                    <div className='content clearfix' style={{paddingLeft:'20px', marginRight:'10px'}}>
                        <p className={classes.contentbody}>
                            <span className={classes.truncateltc}>
                                <span>
                                {blog.content}
                                </span>
                            </span>
                        </p>
                    </div>
                    {blog.get_media_download_url ?
                    <LazyLoad offsetBottom={600}>
                    <div>
                        {blog.mediatype.slice(0,6) === "video/" 
                            ?
                            <Player
                                playsInline
                                preload='none'
                                poster={ blog.get_sd_download_url }
                                src={ blog.get_media_download_url }>
                                <BigPlayButton position="center" />
                                <ControlBar autoHide={true} className="my-class" >
                                    <VolumeMenuButton enabled />
                                </ControlBar>
                            </Player>
                            :
                            <div>
                                {!loaded && <img placeholder={Placeholder} src={Placeholder} alt='post'  width='100%' /> }
                                <Gallery key={blog.mediapath} options={options} >
                                    <Item
                                    id={blog.mediapath}
                                    original={blog.get_media_download_url}
                                    thumbnail={blog.get_media_download_url}
                                    width="800"
                                    height = "800"
                                    >
                                    {({ ref, open }) => (
                                        <img 
                                            placeholder={blog.get_sd_download_url} 
                                            ref={ref} 
                                            onClick={open} 
                                            src={blog.get_sd_download_url}
                                            style={imageStyle}
                                            onLoad={handleImageLoaded}
                                            alt='post' 
                                            width='100%'
                                        />
                                    )}
                                    </Item>
                                </Gallery>
                            </div>
                        }
                    </div>
                    </LazyLoad>
                    : null}
                </div>
                <div className={classes._3sAFzPEm}>
                    <div className={classes.postac}>
                        <div className={classes._3LHT9vBz}>
                            <button className={classes.clrButton}
                                onClick={handleLikeClick}>
                                   { blog.liked === true
                                   ? 
                                    <ThumbUpAltIcon 
                                        fontSize='default' 
                                        htmlColor='#4AA6DE'/>
                                    : <ThumbUpOutlinedIcon 
                                        fontSize='default' 
                                        htmlColor='#4AA6DE'/>
                                   }
                            </button>
                        </div>
                        <div className={classes.postactioncom}>
                            <span className={classes.commentBubble}>
                                <ChatBubbleOutlineRoundedIcon 
                                    fontSize='default'
                                    htmlColor='#4AA6DE'
                                />
                            </span>
                            <span className={classes.postcct}>
                                <span 
                                    onClick={() => 
                                            {setStateOne({ isPaneOpen: true })
                                            }
                                        }
                                >
                                {blog.comments_total}{' '}{blog.comments_total > 1 ? 'Comments' : 'Comment'}
                                </span>

                            </span>
                        </div>
                        {blog.likes > 0 
                        ?
                        <div className={classes.postacc_t} onClick={() => setStateThree({isPaneOpenLikes: true})}>
                            <span className={classes.post_comm_cnttext}>
                                {blog.likes}
                            </span>
                            <span className={classes.likeThumb}>
                                <ThumbUpAltIcon
                                    fontSize='default' 
                                    htmlColor='#4AA6DE' />
                            </span>
                        </div>
                        : null
                        }
                    </div>
                </div>
                <SlidingPane
                    style={{backgroundColor:'#172734'}}
                    className={classes.postCreate}
                    overlayClassName='GroupBlogDetailOverlay'
                    isOpen={stateOne.isPaneOpen}
                    width='100%'
                    title=""
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onRequestClose={() => 
                        { 
                            setStateOne({ isPaneOpen: false })
                            positionFix(true)
                            if (navbarElement != null) {
                                navbarElement.style.display = 'block';
                            }
                        }
                    }
                >
                    <div>
                        <GroupBlogDetail slug={blog.slug} 
                                    didDelete={handleDelete} 
                                    didPostEdit={postEdited} 
                                    readFlag={handleReadFlag}
                                    mainMenuList={mainMenuListArray} 
                                    submenuList={subMenuObj}
                                    mainBodyScrollPos = {mBScrollPos}
                        />
                    </div>
                </SlidingPane>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='UserLikesOverlay'
                    isOpen={stateThree.isPaneOpenLikes}
                    width='100%'
                    title=""
                    onRequestClose={() => { setStateThree({ isPaneOpenLikes: false }) }}
                >
                    <div><GroupBlogUserLikes slug={blog.slug}  /></div>
                </SlidingPane>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='formCreateOverlay'
                    isOpen={stateTwo.isPaneOpenBC}
                    width='100%'
                    title=""
                    onRequestClose={() => setStateTwo({ isPaneOpenBC: false })}
                >
                    <GroupBlogForm blog={blog} didGroupBlogSubmit={formSubmit}/>
                </SlidingPane>
                <SlidingPane
                    className={classes.postCreate}
                    overlayClassName='ProfileTabsOverlay'
                    isOpen={state.isPaneOpenUsr}
                    width='100%'
                    title=""
                    onRequestClose={() => setState({ isPaneOpenUsr: false })}
                >
                    <ProfileTabs username={userProfile} sessionusr={sessionusr} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
                </SlidingPane>
            </div> 
            : null
          : ""}
      </div>
    )
}

