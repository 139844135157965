import React, {useContext, useState } from 'react';
// import clsx from 'clsx';

// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// local
import UrlContext from '../../lookup/prefixurl';
import ProfileTabs from '../profile/profile';
import {SearchProfileMany} from './searchprofilemany';
import {apiGetProfileByName} from '../../lookup/lookup'
import classes from '../assets/style.module.css'




export function SearchInline(props) {
    const {searchItem}                  = props
    const prefixurl                     = useContext(UrlContext)
    const [state, setState]             = useState({isPaneOpen: false})
    const [stateMany, setStateMany]     = useState({isPaneOpenMany: false})
    const [userProfile, setUserProfile] = useState(null)
    const [manyUserProfiles, 
                   setManyUserProfiles] = useState(null)
    const [userProfilePrivacyFlag, 
             setUserProfilePrivacyFlag] = useState(false)
    const [userProfileBlockedFlag, 
             setUserProfileBlockedFlag] = useState(false);
    const [sessionUser, setSessionUser] = useState('')
    

    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    const handleProfileByName = (response, status) => {
        if (status === 200){
            if (response.length === 1) {
                setUserProfile(response[0].username)
                setSessionUser(response[0].sessionUser)
                setUserProfilePrivacyFlag(response[0].private)
                setUserProfileBlockedFlag(response[0].blockedby)
                setState({ isPaneOpen: true })
            } else {
                if (response.length > 1) {
                    setManyUserProfiles(response)
                    setStateMany({ isPaneOpenMany: true })
                }
            }
        }
    }

    const handleProfile = (searchUser) => {
        apiGetProfileByName(prefixurl, searchUser.first_name, searchUser.last_name, handleProfileByName)
    }



    return (
      <div>
          {searchItem !== undefined ?

                <div className={classes.notifycontainer} style={{border:'none', backgroundColor: '#0C161F', borderBottom:'solid 1px #182734'}} >
                    <div style={{display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center', position:'relative'}}
                    onClick={() => handleProfile(searchItem)}
                    >
                        <div style={{color:'#ffffff', display:'flex', justifyContent:'space-between', flexShrink:'1', flexGrow:'1', flexDirection:'row', alignItems:'center'}}>
                            <div style={{flexBasis:'0px', paddingTop:'4px', paddingBottom:'4px', paddingLeft:'10px', position:'relative', display:'flex', justifyContent:'space-between', 
                                            alignItems:'stretch', flexShrink:'1', flexDirection:'column', flexGrow:'1'}}>
                                <div>
                                    <span style={{wordBreak:'break-word', fontSize:'1.25rem', textAlign:'left', fontWeight:'500'}}>
                                        { searchItem.first_name  + ' ' + searchItem.last_name }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{top:"83px", right:"17px"}}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            style={{padding:"0px"}}
                            onClick={() => handleProfile(searchItem)}
                            >
                            <ArrowForwardIosIcon fontSize='large' htmlColor='#546a7b' />
                        </IconButton>                                    
                    </div>

                    <SlidingPane
                        className={classes.slidesearch}
                        overlayClassName='formCreateOverlay'
                        isOpen={state.isPaneOpen}
                        width='100%'
                        title=""
                        from='right'
                        onRequestClose={() => 
                                            {   setState({ isPaneOpen: false }) 
                                                if (navbarElement != null) {
                                                    navbarElement.style.display = 'block';
                                                }
                                            }
                                        }
                    >
                        <div> 
                            <ProfileTabs username={userProfile} sessionusr={sessionUser} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
                        </div>
                    </SlidingPane>
                    <SlidingPane
                        className={classes.slidesearch}
                        overlayClassName='formCreateOverlay'
                        isOpen={stateMany.isPaneOpenMany}
                        width='100%'
                        title=""
                        from='right'
                        onRequestClose={() => 
                                            {   setStateMany({ isPaneOpenMany: false }) 
                                                if (navbarElement != null) {
                                                    navbarElement.style.display = 'block';
                                                }
                                            }
                                        }
                    >
                        <div> 
                            <SearchProfileMany profiles={manyUserProfiles}  />
                        </div>
                    </SlidingPane>   
                    
                </div> 
          : ""}
      </div>
    )
}
