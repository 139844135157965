import React, { useContext, useState, useEffect } from 'react';

//third party plugins
import ReactGallery from 'reactive-blueimp-gallery'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { blue } from '@mui/material/colors';

// local
import UrlContext from '../../lookup/prefixurl'
import { apiUserProfileMedia } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import {deleteUserMediaFile} from '../utilities';


export function ProfileMedia(props) {
    const prefixurl = useContext(UrlContext)
    const [didEdit, setdidEdit] = useState(false)
    const [userprofileMedia, setUserprofileMedia] = useState(null)
    const [sessionUser, setSessionUser] = useState(null)
    const [doneLoading, setDoneLoading] = useState(false)
    const [doDelete, setDoDelete] = useState(false)
    const [dOpen, setDOpen] = useState(false);
    const div_id = 'li-counter-'
    const div_in_id = 'li-in-counter-'
    const [isDisplay, setIsDisplay] = useState('none')
    const [isTrashVisible, setIsTrashVisible] = useState('hidden')
    const [isSelected, setIsSelected] = useState([])
    const [isDiv, setIsDiv] = useState([])
    const [didSelect, setDidSelect] = useState(false)
    const urlToDeleteFile = prefixurl + '/post/usrimgdelete/'

    const handleClose = () => {
        setDOpen(false)
    };

    const handleDeleteFlag = () => {
        if (doDelete) {
            setDoDelete(false)
            setIsDisplay('none')
            setIsTrashVisible('hidden')
            setIsSelected([])
        } else {
            setDoDelete(true)
            setIsDisplay('block')

        }
    }

    const handleImageDelete = async() => {
        const selected = isSelected;
        let ldiv = isDiv;
        let filteredUserprofileMedia = userprofileMedia
        const handleFileDelete = async (response,status) => {
            if (status === 200) {
                filteredUserprofileMedia = filteredUserprofileMedia.filter(filteredUserprofileMedia => filteredUserprofileMedia.title !== response.key)
                await setUserprofileMedia(filteredUserprofileMedia)
                ldiv = ldiv.filter(innerArray => innerArray[0] !== response.key)
                setIsDiv( ldiv );
                setIsSelected([]);
                setIsTrashVisible('hidden')
            }
        }
        if (selected !== undefined) {
            for(var k = 0; k < selected.length; k++){
                // const i = k
                const path = selected[k][0]
                await deleteUserMediaFile(path, urlToDeleteFile, handleFileDelete)
            }
        }
        setDOpen(false);
    }

    const togglePhotoBoxDisplay = (title)  => {
        const div = isDiv;
        if (div !== undefined) {
            // return div.indexOf(title) !== -1;
            for(var k = 0; k < div.length; k++){
                if(div[k][0] === title){
                    return true;
                }
            }
        }
    }

    const toggleOpacity = (title) => {
        let selected = isSelected;
        if (selected !== undefined) {
            // return selected.indexOf(title) !== -1;
            for(var k = 0; k < selected.length; k++){
                if(selected[k][0] === title){
                    return true;
                }
            }
        }
    }

    const haveSelected = (title) => {
        let selected = isSelected
        let arrayfound = false
        // if (selected.indexOf(title) === -1) {
        //   selected.push([title]);
        for(var k = 0; k < selected.length; k++){
            if(selected[k][0] === title){
                arrayfound = true;
            }
        }
        if (!arrayfound) {
            selected.push([title]);
        }
         else {
          selected = selected.filter(innerArray => innerArray[0] !== title)
        }
        setIsSelected( selected );
        selected.length > 0 ? setIsTrashVisible('visible') : setIsTrashVisible('hidden')
        setDidSelect(!didSelect)
    }

    useEffect(() => {
        const handleUserMedia = (response, status) => {
            let divArray = []
            if (status === 200){
                if (response.media){
                    setUserprofileMedia(response.media)
                    setSessionUser(response.sessionUser)
                    for(var k = 0; k < response.media.length; k++){
                        divArray.push([response.media[k].title]);
                    }
                    setIsDiv(divArray)
                }
                setDoneLoading(true)
            }
            setDidSelect(!didSelect)
        }
        if (didEdit === false){
            setdidEdit(true)
            apiUserProfileMedia(prefixurl, props.username, handleUserMedia)
        }
    }, [prefixurl, props, didEdit, setdidEdit, didSelect])


    useEffect(() => {
        if (didSelect === false){
            setDidSelect(true)
            //
        }
    }, [didSelect, setDidSelect])

    return (
        <div >
            {(doneLoading === true) 
            ? 
            <div className='mediamain'>
                <div className='grid' style={{display:'grid', gridTemplateRows: '50px auto'}}>
                    { sessionUser === props.username 
                    ?
                        <div className='mediamain-a' style={{width:'25%'}}>
                            <div className="photo-select-hdr" style={{position: 'relative', color: 'black', float:'left', marginLeft: '8%', marginTop: '11px'}} >
                                <Button id="photoSelection" 
                                    sx = {theme => ({
                                        '&..MuiButton-root':{
                                            color: theme.palette.getContrastText(blue[500]),
                                            backgroundColor: blue[500],
                                            '&:hover': {
                                            backgroundColor: blue[700],
                                            }
                                        }
                                    })}
                                    
                                    variant="contained" color="primary" className="photo-selection" value="cancel" onClick={handleDeleteFlag} startIcon={<DeleteIcon htmlColor='#ffffff' />} >
                                    <small><span className="photoSelectionText" style={{paddingLeft: '4px', paddingRight: '4px'}}>{doDelete ? 'Cancel' : 'Select'}</span></small>
                                </Button>
                                <div id="photoTrash" 
                                    className="photo-trashbin" 
                                    style={{position:'absolute',
                                            top: '0px', 
                                            marginLeft:'120px',
                                            visibility: isTrashVisible
                                            }}
                                    onClick = {() => setDOpen(true)}>
                                    <span className='pro-photo-del-tr'>
                                        <DeleteIcon htmlColor="#ffffff" fontSize='large' style={{marginTop:'4px', marginLeft:'40px', cursor:'pointer'}}/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    :   <div></div>
                    }
                    <div className='mediaBox'>
                        <ReactGallery
                            withControls={false}
                            overlays={<ReactGallery.Overlays noPlayPause noTitle noIndicator/>}
                            options={{
                                transitionSpeed: 200
                            }}
                            >
                            { userprofileMedia.map((item, i) => {
                                // video data-poster is set in django serializer 'BlogFileItemsSerializer'
                                // check UserProfile.serializers.py; line 224:
                                //****    self.fields.update({
                                //****        'data-poster': serializers.SerializerMethodField(method_name='get_dataPoster')
                                //****    })
                                return <div id={div_id + i}
                                            key={i} 
                                            className='media-box' 
                                            style={{
                                                position:'relative', 
                                                // margin:'1px',
                                                display: togglePhotoBoxDisplay(item.title) ? 'block' : 'none'
                                            }}
                                        >
                                            <div id={div_in_id + i} >
                                                <ReactGallery.Slide {...item} key={ item.href } />
                                            </div>
                                            <div
                                                className={classes.deleteBox}
                                                onClick={() => haveSelected(item.title)}
                                                key= {i}
                                                style={{
                                                    display: isDisplay,
                                                    opacity: toggleOpacity(item.title) ? '0.55' : '0',
                                                    }}>
                                            </div>
                                        </div>
                            })}
                        </ReactGallery>
                    </div> 
                </div>
                <Dialog
                    open={dOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{fontSize:"1.35rem"}}>
                        Delete selected media ?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary" style={{fontSize:"1.35rem"}}>
                        No
                    </Button>
                    <Button onClick={handleImageDelete} color="primary" autoFocus style={{fontSize:"1.35rem"}}>
                        Yes
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
             : 
                null
            }
        </div>
    )
}