import React, {useContext, useState, useEffect, useRef } from 'react';

//// third party 'material-ui'
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

// third party plugins
import $ from "jquery";

// local
import uploadIcon from '../assets/images/uploadIcon.png';
import uploadIconDisabled from '../assets/images/uploadIconDisabled.png';
import UrlContext from '../../lookup/prefixurl'
import {apiGroupBlogCreate, apiGroupBlogUpdate} from '../../lookup/lookup';
import {constructFormData, imagePreviewGenerate, getPolicyForS3, ProcessEditedMedia, deleteWithPolicy} from '../utilities';
import classes from '../assets/style.module.css';
import {useLockBodyScroll} from '../utilities';
import {VisualViewport}  from '../utilities';


export function GroupBlogForm(props) {
    const {blog}                                    = props
    const {groupId}                                 = props
    const {didGroupBlogSubmit}                      = props
    const prefixurl                                 = useContext(UrlContext)
    const [slug, setSlug]                           = useState(null)
    const [jqXHR, setjqXHR]                         = useState(null);
    const [content, setContent]                     = useState( blog !== undefined ? blog.content :'' );
    const [enableSendIcon, setEnableSendIcon]       = useState(false)
    const [opacity, setOpacity]                     = useState(0.5);
    const [progress, setProgress]                   = useState(0);
    const [canDelete, setCanDelete]                 = useState(false)
    const [fileDisplay, setFileDisplay]             = useState(false);
    const [mediaInTemp, setMediaInTemp]             = useState(false)
    const [awsUploadKey, setAwsUploadKey]           = useState(null);
    const [deleteOrigMedia, setDeleteOrigMedia]     = useState(false)
    const [imagePreviewUrl, setImagePreviewUrl]     = useState(null);
    const [medianameFromTemp, setMedianameFromTemp] = useState(null)
    const [mediapathFromTemp, setMediapathFromTemp] = useState(null)
    const [mediatypeFromTemp, setMediatypeFromTemp] = useState(null)
    const [fileUploaded, setFileUploaded]           = useState(false)

    const blogContentRef = useRef();
    const fileUpload = useRef(null);
    const urlToS3Temp = prefixurl + '/grouppost/mediauploadToTemp/'
    const urlToS3Copy = prefixurl + '/grouppost/mediaCopyFromTemp/'

    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    useLockBodyScroll();

    const scrollToTop = () => window.scrollTo({
        top: 0
    })

    const buttonstyles = {
        paperContainer: {
            marginRight: '20px',
            marginLeft: '20px',
            marginTop:'20px',
            backgroundImage: 'url(' + uploadIcon + ')',
            backgroundRepeat: 'no-repeat',
            borderRadius:'0%',
            width:"50%", height:"30px"
        },
        paperContainerDisabled: {
            marginRight: '20px',
            marginLeft: '20px',
            marginTop:'20px',
            backgroundImage: 'url(' + uploadIconDisabled + ')',
            backgroundRepeat: 'no-repeat',
            borderRadius:'0%',
            width:"50%", height:"30px"
        }
    };

    const [openForImage, setOpenForImage] = useState(false);
    const [openForVideo, setOpenForVideo] = useState(false);

    const handleFocus = (event) => {
        event.preventDefault()
        //set cursor to end
        var originalText = event.target.value;
        event.target.value = '';
        event.target.value = originalText;
        // remove navbar
        if (navbarElement != null) {
            navbarElement.style.display = 'none';
        }
    };

    const handleBlur = (event) => {
        // event.preventDefault()
        // display navbar
        if (navbarElement != null) {
            navbarElement.style.display = 'block';
        }
    };

    const handleClickImage = () => {
        setOpenForImage(true);
    };
    const handleClickVideo = () => {
        setOpenForVideo(true);
    };
  
    const handleCloseImage = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenForImage(false);
    };
    const handleCloseVideo = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenForVideo(false);
    };

    const actionImage = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseImage}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      const actionVideo = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseVideo}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );


    const handleImagePreviewSetup = (callback) => {
        setImagePreviewUrl(callback)
        setOpacity(0.5)
    }

    useEffect(()=> {
        if (blog) {
            setSlug(blog.slug)
            if (blog.get_media_download_url) {
                if (blog.mediatype.slice(0,6) === "video/" ){
                    setImagePreviewUrl(blog.get_sd_download_url)
                } else {
                    setImagePreviewUrl(blog.get_media_download_url)
                }
                setFileDisplay(true)
                setOpacity(0)
            } 
            scrollToTop()
        } 
    },[blog])
    
    const UsePolicyAndUpload = async (file, policyData) => {
        if  ("medianame" in policyData) {
            setMedianameFromTemp(policyData["medianame"])
            delete policyData["medianame"]
        }
        if  ("mediapath" in policyData) {
            setMediapathFromTemp(policyData["mediapath"])
            delete policyData["mediapath"]
        }
        if  ("mediatype" in policyData) {
            setMediatypeFromTemp(policyData["mediatype"])
            delete policyData["mediatype"]
        }

        const fd = constructFormData(policyData)
        fd.append('file', file)
        const awsEndpoint = policyData.url
        setAwsUploadKey(policyData.fields.key)
        setjqXHR($.ajax({
            type : "POST",
            url : awsEndpoint,
            data : fd,
            xhr: function(){
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload){
                    myXhr.upload.addEventListener('progress', function(e){
                        if (e.lengthComputable){
                            var percent = e.lengthComputable ? (e.loaded / e.total) * 100 : 0;
                            setProgress(parseFloat(percent.toFixed(2)))
                            if (parseFloat(percent.toFixed(2)) === 100) {
                                setOpacity(0)
                            }
                        }
                    }, false);
                }
                return myXhr;
            },
            success : function(){
                setCanDelete(true)
                setMediaInTemp(true)
                setFileUploaded(true)
            },
            error : function(_jqXHR, textStatus){
                console.log("S3 Upload Error:", textStatus)
                setFileUploaded(false)
            },
            cache : false,
            contentType : false,
            processData : false
        }))
    }

    const handleSendResponse = async (response, status) => {
        if (status === 200) {
            await setMediaInTemp(false)
            setContent("")
            didGroupBlogSubmit(response)
        }
    }

    const handleBackendCreate = async (response, status) => {
        if (status === 200 || status === 201){
            if (mediaInTemp) {
                let copy_from = mediapathFromTemp
                let filename_to_copy = copy_from.split("/").pop()
                let data ={
                    slug : response.slug,
                    content: content,
                    medianameFromTemp : medianameFromTemp,
                    mediatypeFromTemp : mediatypeFromTemp,
                    copy_from : copy_from,
                    filename : filename_to_copy
                }
                await ProcessEditedMedia(data, urlToS3Copy, handleSendResponse)
            } else {
                setContent("")
                didGroupBlogSubmit(response)
            }
        }
    }
    
    const handleBackendUpdate = async (response, status) => {
        if (status === 200) {
            setAwsUploadKey(null)
            didGroupBlogSubmit(response)     
        } else {
            console.log("Error", response)
        }
    }

    const handleBackendPreUpdate = async () => {
        await apiGroupBlogUpdate(prefixurl, content, slug, handleBackendUpdate)
    }

    const handleUploadAbort = async (event) => {
        event.preventDefault()
        if (jqXHR) {
            jqXHR.abort()
        }
        setFileDisplay(false)
        setFileUploaded(false)
        setDeleteOrigMedia(true)
        if (mediaInTemp) {
            if (awsUploadKey) {
                await deleteWithPolicy(awsUploadKey, urlToS3Temp)
                setMediaInTemp(false)
            }
        }
        if (content) {
            setEnableSendIcon(true)
        } else {
            setEnableSendIcon(false)
        }

    }

    const handleInputChange = (event) => {
        event.preventDefault()
        setEnableSendIcon(true)
        let key = event.target.name
        let value = event.target.value
        let checkValue = value.trim()
        if (key === 'content'){
            if (checkValue.length > 1000){
                value = value.substring(0,1000)
            }
            if (checkValue.length === 0){
                setEnableSendIcon(false)
            } 
        }
        setContent(value)
    }
    
    const handleFileChange = async (event) => {
        event.persist()
        setProgress(0)
        if (awsUploadKey) {
            deleteWithPolicy(awsUploadKey, urlToS3Temp)
        }
        setAwsUploadKey(null)
        let file = event.target.files[0];
        if (file) {

            if (file.type.match('image')) {
                if (file.size <= 11000000) {
                    setFileDisplay(true)
                    setFileUploaded(false)
                    imagePreviewGenerate(file, handleImagePreviewSetup)
                    getPolicyForS3(file, urlToS3Temp, UsePolicyAndUpload)
                } else {
                    handleClickImage()
                }
            }

            if (file.type.match('video')) {
                if (file.size <= 51000000) {
                    setFileDisplay(true)
                    setFileUploaded(false)
                    imagePreviewGenerate(file, handleImagePreviewSetup)
                    getPolicyForS3(file, urlToS3Temp, UsePolicyAndUpload)
                } else {
                    handleClickVideo()
                }
            }
        }
        event.target.value = ''
    }


    const handleSubmit = async (event) => {
        event.preventDefault()
        if (blog !== undefined){   // post is in edit mode
            if (mediaInTemp) {
                let copy_from = mediapathFromTemp
                let filename_to_copy = copy_from.split("/").pop()
                let data ={
                    slug : slug,
                    content: content,
                    medianameFromTemp : medianameFromTemp,
                    mediatypeFromTemp : mediatypeFromTemp,
                    copy_from : copy_from,
                    filename : filename_to_copy
                }
                await ProcessEditedMedia(data, urlToS3Copy, handleBackendPreUpdate)
                
            } else if (deleteOrigMedia) {
                    let data ={
                        slug : slug,
                        content: content,   
                        medianameFromTemp : '',
                        mediatypeFromTemp : '',
                        copy_from : '',
                        filename : ''
                    }
                    await ProcessEditedMedia(data, urlToS3Copy, handleBackendPreUpdate)
            } else {
                apiGroupBlogUpdate(prefixurl, content, blog.slug, handleBackendUpdate)
            }
        } else {
            apiGroupBlogCreate(prefixurl, content, groupId, handleBackendCreate)
        }
    }

    let $imagePreview = (<div></div>);
    if (imagePreviewUrl) {
        $imagePreview = (
            <div className={classes.imgPreviewBox}>
                <div className={classes.imageopaque} style={{opacity: opacity}}></div>
                <div className={classes.cancelImgBtn}>
                    <IconButton className='mediaUpload-cancel' onClick={handleUploadAbort}>
                        <CancelSharpIcon htmlColor='#003049' fontSize='small' />
                    </IconButton>
                </div>
                <img src={imagePreviewUrl} alt="icon" className={classes.imgPreview}/> 
                <div>
                    <LinearProgress 
                    sx={{
                        width: '100%',
                        height: '2px',
                        borderRadius: '0 0 4px 4px',
                        backgroundColor: `rgb(${"56,54,61"},0.1)`,
                        "&.MuiLinearProgress-bar": {
                        backgroundColor: '#003049'
                        }
                    }} 
                    variant="determinate" 
                    value={progress} />
                </div>
            </div>
        );
    }

    useEffect(() => {
        return () => {
            if (canDelete) {
                setjqXHR(null)
            }
        }
    },[canDelete])

    // Cleanup : abort file uploading process if exiting before posting
    useEffect(() => {
        return () => {
            if (jqXHR) {
                jqXHR.abort()
            }
        }
    },[jqXHR])
 
    // Cleanup : delete any media in AWS S3 temp folder if any
    useEffect(() => {
        return () => {
            if (awsUploadKey !== null) {
                deleteWithPolicy(awsUploadKey, urlToS3Temp)
            }
        }
    },[awsUploadKey, urlToS3Temp])


    return (
        <VisualViewport> 
        <div className='row' style={{backgroundColor:'#0d171f'}}>
            <div className='col-sm-6 col-sm-offset-3'>
                <form onSubmit={(event) => {handleSubmit(event)}}>
                <div className='form-group'>
                    <div className={classes.postHdr}>
                        <div style={{float:"left"}}>
                            {fileDisplay ?

                                <IconButton className="needsclick" disabled={true} style={buttonstyles.paperContainerDisabled} />
                                :
                                <IconButton className="needsclick" style={buttonstyles.paperContainer} disabled={false} onClick={() => fileUpload.current.click()} />
                             }
                        </div>
                        <div style={{float:"left", marginTop:"18px"}}>
                            <span style={{fontSize:'12px', color:'#ffffff'}}>Add photo or video</span>
                        </div> 
                            
                                               
                        <div style={{float:"right"}}>
                            {fileUploaded ? <IconButton type='submit'><SendIcon fontSize='large' htmlColor='#4AA6DE' /></IconButton>
                                : enableSendIcon ? <IconButton type='submit'><SendIcon fontSize='large' htmlColor='#4AA6DE' /></IconButton>
                                : <IconButton disabled={true}><SendIcon fontSize='large' htmlColor='#8d99ae' /></IconButton>
                            }
                        </div>
                        <div>
                            <input type="file"
                                ref={fileUpload}
                                id="blogMedia"
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, video/*"  onChange={handleFileChange} />
                        </div>
                    </div>
                    
                    <div className={classes.imgContainer}>
                        {fileDisplay ? $imagePreview :'' }
                    </div>

                    <TextField
                        id="content"
                        name='content'
                        sx = {{
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {borderColor: "#d3d3d3"},
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {borderColor: "#d3d3d3"},
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor: "#d3d3d3"},
                            width:'100% !important',
                            backgroundColor: '#ffffff'
                        }}
                        ref = {blogContentRef}
                        placeholder='Content...' 
                        required={false}
                        value = {content !== null ? content :''} 
                        onChange={handleInputChange}
                        onFocus={handleFocus} 
                        onBlur={handleBlur}
                        multiline
                        autoFocus
                        rows={8}
                        variant="outlined"
                        inputProps={{style: {fontSize: '12px', lineHeight: '16px', letterSpacing: '-0.32'}}}
                    />

                    <Snackbar
                        open={openForImage}
                        autoHideDuration={3000}
                        onClose={handleCloseImage}
                        message="Cannot upload. Image size exceeds 10 MB."
                        action={actionImage}
                    />

                    <Snackbar
                        open={openForVideo}
                        autoHideDuration={4500}
                        onClose={handleCloseVideo}
                        message="Cannot upload. Video size exceeds 50 MB."
                        action={actionVideo}
                    />
                </div>
            </form>
            </div>
        </div>
        </VisualViewport>
    )
}

export default GroupBlogForm



