
import React, { useContext, useState, useEffect } from 'react';

//third party plugins
import Typography from "@mui/material/Typography";
import PullToRefresh from "react-simple-pull-to-refresh";


// local
import UrlContext from '../../lookup/prefixurl'
import { apiUserProfileFollowing } from '../../lookup/lookup';
import classes from '../assets/style.module.css'
import '../assets/reactivegallery.css'
import {ProfileFollowingInline} from './profilefollowinginline';


export function ProfileFollowing(props) {
    const prefixurl  = useContext(UrlContext)
    const [userfollowers, setUserFollowers] = useState(null)
    const [doneLoading, setDoneLoading] = useState(false)

    const handleUsersFollowing = (response, status) => {
        if (status === 200){
            setUserFollowers(response)
            setDoneLoading(true)
        }
    }

    useEffect(() => {
        apiUserProfileFollowing(prefixurl, props.username, handleUsersFollowing)
    }, [prefixurl, props])
    
    const handleRefreshc = () => {
        apiUserProfileFollowing(prefixurl, props.username, handleUsersFollowing)
        return new Promise((resolve) => {
            setTimeout(resolve, 1000)
        });
    };

    
    return (
        <div>
            {(doneLoading === true) 
            ? 
            <div>
            <PullToRefresh
                onRefresh={handleRefreshc}
                pullingContent = {false}
            >
                <div>
                    <div className={classes.bndhdr}>
                        <Typography style={{ fontSize: '1em', color: '#ffffff' }}>
                            Following{' '}
                            {userfollowers.following.length > 1 ? userfollowers.following.length + ' people' : 
                                userfollowers.following.length === 1 ? userfollowers.following.length + ' person' : 
                                userfollowers.following.length === 0 ? 'none' : null
                            } 
                        </Typography>
                    </div>
                    {userfollowers.following && userfollowers.following.map((followingItem, i) => {
                        return (
                                <div key={i} className={classes.echdiv1}>
                                    <ProfileFollowingInline following={followingItem} sessionUser={userfollowers.sessionUser} />
                                </div>
                                )
                    })}
                </div>
            </PullToRefresh>
            </div> 
             : 
               null
            }
        </div>
    )
}