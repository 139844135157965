import React, {useContext, useState, useRef } from 'react';

//// third party 'material-ui'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from "@mui/material/Typography";


// local
import UrlContext from '../../lookup/prefixurl'
import {apiGroupUpdate} from '../../lookup/lookup';
import {useLockBodyScroll} from '../utilities';
import {VisualViewport}  from '../utilities';
import classes from '../assets/style.module.css'


export function GroupForm(props) {
    const {grp}                         = props
    const {didGroupSubmit}              = props
    const prefixurl                     = useContext(UrlContext)
    const [title, setTitle]             = useState( grp !== undefined ? grp.title :'' );
    const [description, setDescription] = useState(grp !== undefined ? grp.description :'');
    const [errorName, setErrorName]     = useState(false)


    const groupTitleRef = useRef();
    const groupDescriptionRef = useRef();

    var navbarElement   = document.querySelector('.navbar-fixed-bottom');

    useLockBodyScroll();

    const handleFocus = (event) => {
        event.preventDefault()
        //set cursor to end
        var originalText = event.target.value;
        event.target.value = '';
        event.target.value = originalText;
        // remove navbar
        if (navbarElement != null) {
            navbarElement.style.display = 'none';
        }
    };

    const handleBlur = (event) => {
        // display navbar
        if (navbarElement != null) {
            navbarElement.style.display = 'block';
        }
    };

//    const textareaStyles = makeStyles({
//         root: {
//             "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {borderColor: "#d3d3d3"},
//             "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {borderColor: "#d3d3d3"},
//             "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor: "#d3d3d3"},
//             width:'100% !important',
//             backgroundColor: '#ffffff'
//         }
//     });
//     const txtareaclasses = textareaStyles();

    const handleBackendUpdate = async (response, status) => {
        if (status === 200) {
            didGroupSubmit(response)     
        } else {
            console.log("Error", response)
        }
    }

    const nameEventHandler = (event) => {
        event.preventDefault()
        let key = event.target.name
        let value = event.target.value
        let checkValue = value.trim()
        if (key === 'title'){
            if (checkValue.length > 200){
                value = value.substring(0,200)
            }
        }
        setTitle(value)
        setErrorName(false)
    }
    const descriptionEventHandler = (event) => {
        event.preventDefault()
        let key = event.target.name
        let value = event.target.value
        let checkValue = value.trim()
        if (key === 'description'){
            if (checkValue.length > 1000){
                value = value.substring(0,1000)
            }
        }
        setDescription(value)
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (title.trim().length === 0) {
            setErrorName(true)
        } else {
            apiGroupUpdate(prefixurl, title, description, grp.slug, grp.mediapath, handleBackendUpdate)
        }
    }


    return (
        <VisualViewport> 
            <div className={classes.grouplist}>
                <div className='col-sm-6'>
                <div>
                    <div style={{marginTop:"20px", marginLeft:"16px", marginRight:"16px"}}>
                    <form onSubmit={(event) => {handleSubmit(event)}}>
                        <div className='form-group'>
                            <Typography align='left' variant='h6' gutterBottom={true} style={{ fontSize: '1.5em', color: '#ffffff' }}>Group Name</Typography>
                            <FormControl fullWidth error={errorName} variant="standard" style={{paddingBottom: '20px', color:'#fff'}}
                                        sx={{ 
                                                '& .MuiOutlinedInput-root': {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#ffffff"
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#ffffff"
                                                    }
                                                }
                                            }} 
                                >
                                <OutlinedInput
                                        id="title"
                                        ref={groupTitleRef}
                                        autoComplete="off"
                                        placeholder="Group name..."
                                        style={{fontSize:'1.3rem', color:'#182734', background:'#fff'}}
                                        value={title !== null ? title :''}
                                        onChange={nameEventHandler}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}  
                                        error={errorName}
                                />
                                {errorName && <FormHelperText style={{fontSize:'0.95rem'}}>Add a group name</FormHelperText>}
                            </FormControl>

                            <Typography align='left' variant='h6' gutterBottom={true} style={{ fontSize: '1.5em', color: '#ffffff', paddingTop:'10px' }}>Description</Typography>

                            <FormControl fullWidth variant="standard" style={{paddingBottom: '20px'}}
                                        sx={{ 
                                            '& .MuiOutlinedInput-root': {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#ffffff"
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#ffffff"
                                                }
                                            }
                                        }} 
                            > 
                                <OutlinedInput
                                    id="description"
                                    ref= {groupDescriptionRef}
                                    value={description !== null ? description :''}
                                    autoFocus
                                    autoComplete="off"
                                    placeholder="Description..."
                                    multiline
                                    style={{fontSize:'1.3rem', color:'#182734', background:'#fff'}}
                                    rows={8}
                                    onChange={descriptionEventHandler}  
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}  
                                />
                            </FormControl>
                            
                            <Button 
                             sx = {theme => ({
                                '&..MuiButton-root':{
                                    color: theme.palette.getContrastText(blue[500]),
                                    backgroundColor: blue[500],
                                    '&:hover': {
                                    backgroundColor: blue[700],
                                    }
                                }
                            })}
                            type="submit" size="medium" variant="contained" color="primary" style={{float:"right", marginRight:"20px"}}>Save</Button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </VisualViewport>
    )
}

export default GroupForm



