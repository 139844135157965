import React, { useState, useEffect } from 'react';

// Third party plugins
import SlidingPane from "react-sliding-pane";
import 'react-sliding-pane/dist/react-sliding-pane.css'
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Typography from "@mui/material/Typography";
import PullToRefresh from "react-simple-pull-to-refresh";

// local
import ProfileTabs from './profile/profile';
import {apiLocationFollowersList, apiUserFollowToggle} from '../lookup/lookup';
import classes from '../blogs/assets/style.module.css'
import { UrlProvider } from '../lookup/prefixurl'


export function LocationFollowersList(props) {
    const {prefixurl}                                           = props
    const {locationslug}                                        = props
    const [didEdit, setdidEdit]                                 = useState(false)
    const [doneLoading, setDoneLoading]                         = useState(false)
    const [allFollowers, setAllFollowers]                       = useState([])
    const [followLoading, setFollowLoading]                     = useState(false)
    const [notificationsDidSet, setNotificationsDidSet]         = useState(false)
    const [userProfile, setUserProfile]                         = useState(null)
    const [userProfilePrivacyFlag, setUserProfilePrivacyFlag]   = useState(false)
    const [userProfileBlockedFlag, setUserProfileBlockedFlag]   = useState(false);
    const [totalFollowing, setTotalFollowing]                   = useState(0)
    const [sessionusr, setSessionUsr]                           = useState(null)
    

    const [state, setState] = useState({
        isPaneOpen: false
    })
    
    useEffect(()=>{
     if (didEdit === false){
         setdidEdit(!didEdit)
     }
    },[didEdit, setdidEdit])

    useEffect(() => {
        if (notificationsDidSet === false) { 
            const handleFollowersListLookup = (response, status) => {
                setSessionUsr(response.sessionUser)
                if (status === 200){
                    setAllFollowers(response)
                    // total following = all following minus all blocked
                    setTotalFollowing(response.locationfollowing.filter((following) => (following.blockedby === false)).length)

                    setDoneLoading(true)
                    setNotificationsDidSet(true)
                }
            }
            apiLocationFollowersList(prefixurl, locationslug, handleFollowersListLookup)
        }
    }, [prefixurl, allFollowers, locationslug, notificationsDidSet, setNotificationsDidSet])


    const handleRefreshc = () => {
        const handleFollowersListRetrieve = (response, status) => {
            if (status === 200){
                setAllFollowers(response)
                setTotalFollowing(response.locationfollowing.filter((following) => (following.blockedby === false)).length)
                setDoneLoading(true)
                setNotificationsDidSet(true)
            }
        }
        apiLocationFollowersList(prefixurl, locationslug, handleFollowersListRetrieve)
        return new Promise((resolve) => {
            setTimeout(resolve, 1000)
        });
    };

    const handleFollowToggle = (event) => {
        if (!followLoading) {
            setFollowLoading(true)
            apiUserFollowToggle(prefixurl, event.username, event.followed ? "unfollow" : "follow", (response, status) => {
                if (status === 200) {
                    setFollowLoading(false)
                    const editedAllFollowers = allFollowers
                    const index = editedAllFollowers.locationfollowing.findIndex(locfoll => locfoll.username === event.username)
                    editedAllFollowers.locationfollowing[index].followed = response.followed
                    setAllFollowers(editedAllFollowers)
                    setdidEdit(!didEdit)
                }
            })
        }
    }

    const handleAvatar = (username, privacyFlag, blockedby)=>{
        setUserProfile(username)
        if (username === allFollowers.sessionUser){
            setUserProfilePrivacyFlag(false)
        } else {
            if (privacyFlag === true) {
                setUserProfilePrivacyFlag(true)
            }else {
                setUserProfilePrivacyFlag(false)
            }
            if (blockedby === true) {
                setUserProfileBlockedFlag(true)
            }else {
                setUserProfileBlockedFlag(false)
            }
        }
        setState({ isPaneOpen: true })
    }


    return (
        <div className='row' style={{backgroundColor:'#0d171f'}}>
            {(doneLoading === true) 
            ? 
            <div className='col-lg-6 col-lg-offset-3'>
                <PullToRefresh
                    onRefresh={handleRefreshc}
                    pullingContent = {false}
                >
                <div style={{marginLeft:'-14px', marginRight:'-14px'}}>
                    <div style={{display:'flex', flexDirection:'column', flexGrow:'1'}}>
                        <div className={classes.bndhdr} style={{paddingLeft:'40px', paddingRight:'20px'}} >
                            <Typography style={{ fontSize: '0.935em', color: '#ffffff', paddingTop:'10px' }}>
                                {totalFollowing > 1 ? totalFollowing + ' people are following this location' : 
                                totalFollowing === 1 ? totalFollowing + ' person is following this location' : 
                                totalFollowing === 0 ? 'No followers yet.' : null
                                } 
                            </Typography>
                        </div>

                        { allFollowers.locationfollowing.length === 0 ?
                            <div className={classes.bndhdr} style={{paddingLeft:'40px', paddingRight:'20px', backgroundColor:'#0C161F'}} >
                                <React.Fragment>
                                    <Typography style={{ fontSize: '0.935em', color: '#ffffff', paddingBottom:'10px' }}></Typography>
                                    <Typography style={{ fontSize: '0.935em', color: '#ffffff', paddingBottom:'10px' }}>
                                        Start following this <strong>location</strong> to get notified when others 
                                        <strong> Review</strong> or upload <strong>Photos</strong> or <strong>Videos</strong> in this location.
                                    </Typography>
                                    <Typography style={{ fontSize: '0.935em', color: '#ffffff', paddingBottom:'10px' }}>
                                        To follow this location, go to the <strong>About</strong> tab and tap on the <strong>Follow</strong> button.
                                    </Typography>
                                </React.Fragment>
                            </div>
                        : null
                        }

                        {allFollowers.locationfollowing.map((follower, i) => (
                            follower.blockedby === false  // do not display users that have blocked the requester
                            ?
                            <div key={i} className={classes.echdiv1}>
                                <div className={classes.echdiv2} style={{paddingLeft:'12px'}}>
                                    <div className={classes.echdiv3}>
                                        <div className={classes.avatar34} onClick={() => handleAvatar(follower.username, follower.private, follower.blockedby)}>
                                            <img className={classes.avtrimg} src={follower.avatar} alt=''></img>
                                        </div>
                                    </div>

                                    <div className={classes.echdiv4}>
                                        <div className={classes.echdiv5} onClick={() => handleAvatar(follower.username, follower.private, follower.blockedby)} >
                                            <div>
                                                {follower.private ?
                                                    <div className={classes.propiclock} >
                                                        <VpnLockIcon htmlColor="#e63946" fontSize='small'/>
                                                    </div> 
                                                : null}
                                                <Typography style={{ fontSize: '0.935em', color: '#ffffff' }}>
                                                    { follower.first_name 
                                                    ? follower.first_name + ' ' + follower.last_name
                                                    : follower.username
                                                    }
                                                </Typography>
                                            </div>
                                        </div>

                                        <div style={{paddingLeft:'10%'}}>
                                            { follower.username !== allFollowers.sessionUser 
                                            ?
                                                !follower.private ?
                                                    <button className="btn btn-sm btn-info" style={{width:'80px'}} onClick={() => handleFollowToggle(follower)}>
                                                        { follower.followed ? "UnFollow" : "Follow" }
                                                    </button>
                                                : null
                                            : null
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            : null
                        ))}
                    </div>
                </div>
                </PullToRefresh>
                <SlidingPane
                    className={classes.postCreate} 
                    overlayClassName='editedPostOverlay'
                    isOpen={state.isPaneOpen}
                    width='100%'
                    title=""
                    // from='bottom'
                    onRequestClose={() => setState({ isPaneOpen: false })}
                >
                    <UrlProvider value={prefixurl}>
                        <ProfileTabs username={userProfile} sessionusr={sessionusr} privacyflag={userProfilePrivacyFlag} blockedby={userProfileBlockedFlag}/>
                    </UrlProvider>

                </SlidingPane>
            </div> 
             : 
                null
            }
        </div>
     )
}


export default LocationFollowersList